import BaseView from '../../../js/base-view';

export default class ResponsiveVideo extends BaseView {
	init() {
		this.refs = {
			sources: Array.from(this.el.querySelectorAll('source')),
			currentSources: [],
		};

		this.setCorrectVideo();

		if (this.el.hasAttribute('autoplay')) {
			this.el.play();
		}

		// Maybe add resize
		// this.bindEvents();
	}

	/**
	 * Bind component's events
	 */
	// bindEvents() {
	// 	this.on('resize', this.onResize.bind(this));
	// }

	// onResize() {
	// 	this.setCorrectVideo();
	// }

	setCorrectVideo() {
		this.refs.currentSources.forEach((child) => {
			this.el.removeChild(child);
		});
		this.refs.currentSources = [];

		this.refs.sources.forEach((source) => {
			if (window.matchMedia(source.getAttribute('media')).matches) {
				const newSrc = document.createElement('source');

				this.el.append(newSrc);

				newSrc.setAttribute('type', source.getAttribute('type'));
				newSrc.setAttribute('src', source.getAttribute('data-src'));

				this.refs.currentSources.push(newSrc);
			}
		});
	}
}
