import { throttle } from 'lodash-es';

const THRESHOLD = 40; // px
const TRHOTTLE_DELAY = 100; // ms

const SMALL_CLASS = 'nav--small';

let navigationView = null;

function toggleSmall() {
	if (window.pageYOffset > THRESHOLD) {
		navigationView.classList.add(SMALL_CLASS);
		const event = new CustomEvent('minimize', { detail: true });
		navigationView.dispatchEvent(event);
	}
	else {
		navigationView.classList.remove(SMALL_CLASS);
		const event = new CustomEvent('minimize', { detail: false });
		navigationView.dispatchEvent(event);
	}
}
const onScrollPage = throttle(toggleSmall, TRHOTTLE_DELAY);

function bind() {
	window.addEventListener('scroll', onScrollPage);
}

function destroy() {
	window.removeEventListener('scroll', onScrollPage);
	navigationView = null;
}

export default function init(view = null) {
	if (view === null) {
		return false;
	}

	navigationView = view;

	toggleSmall();
	bind();

	return {
		toggleSmall,
		destroy,
	};
}
