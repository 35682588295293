import BaseInput from '../_0-input/input';

const VALID_CLASS = 'is-valid';
const FILE_MAX_FILENAME_LENGTH = 32;
const SELECTOR_LABEL = 'input-file__label';
const SELECTOR_FIELD = '.input-file__field';
const SELECTOR_SELECTION = '.input-file__selection';
const SELECTOR_CLEAR = '.input-file__clear';

/**
 * @class InputFile
 */
export default class InputFile extends BaseInput {
	init() {
		super.init(SELECTOR_FIELD);

		this.props.attrs = this.refs.field.attributes;

		this.state.hasFile = false;

		this.refs.label = this.el.querySelector(SELECTOR_LABEL);
		this.refs.selection = this.el.querySelector(SELECTOR_SELECTION);

		this.bindEvents();
	}

	bindEvents() {
		this.on('click', SELECTOR_LABEL, this.onLabelClick.bind(this));
		this.on('change', SELECTOR_FIELD, this.onFileChange.bind(this));
		this.on('focus', SELECTOR_FIELD, this.onFileFocus.bind(this));
		this.on('blur', SELECTOR_FIELD, this.onFileBlur.bind(this));
		this.on('click', SELECTOR_CLEAR, this.deleteFile.bind(this));
	}

	onLabelClick(event) {
		if (! this.state.hasFile) {
			return;
		}

		event.preventDefault();
		if (this.state.isErroneous) {
			this.resetError();
		}

		this.deleteFile();
	}

	onFileChange() {
		if (this.refs.field.files.length === 0) {
			return;
		}
		this.resetError();
		this.populateFileSelection(this.refs.field.files[ 0 ]);
	}

	onFileFocus() {
		this.el.classList.add('is-focus');
	}

	onFileBlur() {
		this.el.classList.remove('is-focus');
	}

	populateFileSelection(file) {
		// eslint-disable-next-line no-unused-vars
		const [filename, basename, fileExt] = /(.*)\.(.+)$/.exec(file.name);

		this.refs.selection.innerText = (basename.length > FILE_MAX_FILENAME_LENGTH ? basename.slice(0, FILE_MAX_FILENAME_LENGTH).concat('…') : basename);

		this.state.hasFile = true;
		this.el.classList.add(VALID_CLASS);
		this.trigger('selected', null, false);
	}

	deleteFile() {
		// Destroy field
		this.el.removeChild(this.refs.field);

		// Create new field with all attrs & insert in DOM
		this.refs.field = document.createElement('input');
		for (let i = 0; i < this.props.attrs.length; i++) {
			this.refs.field.setAttribute(this.props.attrs[ i ].name, this.props.attrs[ i ].value);
		}
		this.el.insertAdjacentElement('afterbegin', this.refs.field);

		this.resetFileSelection();

		// trigger event for possible listeners
		this.trigger('deleted', null, false);
	}

	resetFileSelection() {
		this.refs.selection.innerText = '';
		this.el.classList.remove(VALID_CLASS);
		this.state.hasFile = false;
	}

	resetError() {
		this.el.classList.remove('has-error');
		this.el.removeAttribute('data-error');
		this.state.isErroneous = false;
	}

	reset() {
		super.reset();
		this.deleteFile();
	}
}
