import BaseView from "../../../js/base-view";
import pad from "../../../js/utils/padNumber";

const SELECTOR_COUNTDOWN = ".timer__countdown";

export default class Timer extends BaseView {
	/**
	 * Entrypoint
	 */
	init() {
		// refs
		// to keep reference of scoped dom elements
		this.refs = {};
		this.refs.countdown = this.el.querySelector(SELECTOR_COUNTDOWN);

		// props
		// the component's static configuration

		// state
		// the component's dynamic data representing its "state"
		this.state = {};
		this.state.countdownDate = null;
		this.state.countdownId = null;

		// attach events
		this.setupCountdown();
	}

	// // --------------------------------
	// // #region Event Handlers
	// // --------------------------------

	/**
	 * Setup Countdown
	 */
	setupCountdown() {
		if (this.refs.countdown) {
			this.state.countdownDate = new Date(this.refs.countdown.dataset.countdown).getTime();

			this.getTimeLeft();
			this.countdown();
		}
	}

	/**
	 * Countdown every 1 second
	 */
	countdown() {
		this.state.countdownId = setInterval(() => {
			this.getTimeLeft();
		}, 1000);
	}

	/**
	 * Estimate time left to a specific date
	 * UTC Time
	 */
	getTimeLeft() {
		// Get today's date and time
		const now = new Date().getTime();

		// Find the distance between now and the count down date
		const distance = this.state.countdownDate - now;

		// Time calculations for days, hours, minutes and seconds
		const days = Math.floor(distance / (1000 * 60 * 60 * 24));
		const hours = Math.floor(
			(distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
		);
		const min = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
		const sec = Math.floor((distance % (1000 * 60)) / 1000);

		const strings =
			!window.supt || !window.supt.countdown || !window.supt.countdown.strings
				? {
						days: "days",
						hours: "hours",
						minutes: "minutes",
						seconds: "seconds",
				  }
				: window.supt.countdown.strings;

		// Display the result in the element with id="demo"
		this.refs.countdown.innerHTML = `<li><p>${pad(days)}</p><p>${
			strings.days
		}</p></li><li><p>${pad(hours)}</p><p>${strings.hours}</p></li><li><p>${pad(
			min
		)}</p><p>${strings.minutes}</p></li><li><p>${pad(sec)}</p><p>${
			strings.seconds
		}</p></li>`;

		// If the count down is finished, write some text
		if (distance < 0) {
			clearInterval(this.state.countdownId);
			this.refs.countdown.innerHTML = `<li><p>00</p><p>${strings.days}</p></li><li><p>00</p><p>${strings.hours}</p></li><li><p>00</p><p>${strings.minutes}</p></li><li><p>00</p><p>${strings.seconds}</p></li>`;

			this.el.classList.add("timer--finished");
			this.trigger("finished", null, false);
		}
	}

	// --------------------------------
	// #endregion
	// --------------------------------

	/**
	 * Before the component gets destroyed
	 * - unbind any event not bound with this.on()
	 * - reset UI if needed (any classes/attributes added?)
	 */
	beforeDestroyChild() {
		clearInterval(this.state.countdownId);
	}
}
