/* eslint-disable brace-style */
import BaseView from '../../../js/base-view';

const SELECTOR = 'data-accordion';
const CLASS_HAS_FOCUS = 'has-focus';
const CLASS_IS_OPEN = 'is-open';

export default class Accordion extends BaseView {
	init() {
		// config
		this.config = this.getConfig();

		// prepare dom
		this.prepareDom();

		// bind events
		this.bindEvents();
	}

	// ========================= //
	// #region PREPARE/RESET DOM
	// ========================= //

	prepareDom() {
		// prepare the dom of the container (root element)
		this.prepareRootNode(this.el);

		// prepare the dom of each header/panel pair
		this.getHeaderNodes().forEach((headerNode, index) => {
			// vars
			const headerId = `accordion_${this.vid}_header_${index}`;
			const panelId = `accordion_${this.vid}_panel_${index}`;
			const panelNode = headerNode.closest(`[${SELECTOR}-item]`).querySelector(`[${SELECTOR}-panel]`);
			const panelIsOpened = headerNode.hasAttribute(`${SELECTOR}-opened`);

			// header
			this.prepareHeader(headerNode, headerId, panelId, panelIsOpened);

			// panel
			this.preparePanel(panelNode, headerId, panelId, panelIsOpened);
		});
	}

	resetDom() {
		// reset the dom of the container (root element)
		this.resetRootNode(this.el);

		// reset the dom of each header/panel pair
		this.getHeaderNodes().forEach((headerNode) => {
			// header
			this.resetHeader(headerNode);

			// panel
			const panelNode = headerNode.closest(`[${SELECTOR}-item]`).querySelector(`[${SELECTOR}-panel]`);
			this.resetPanel(panelNode);
		});
	}

	/**
	 * Prepare the accordion root dom element
	 * @param {Node} node accordion root element
	 */
	prepareRootNode(node) {
		// add role tablist to node
		node.setAttribute('role', 'tablist');

		// add a class saying the accordion js is activated
		// …this way we can add some "js-specific" styling
		node.classList.add(this.config.jsClass);
	}

	/**
	 * Reset the accordion root dom element to its initial state
	 * To use when we destroy the accordion for example
	 * @param {Node} node accordion root element
	 */
	resetRootNode(node) {
		// remove added role
		node.removeAttribute('role');

		// remove added class
		node.classList.remove(this.config.jsClass);
	}

	/**
	 * Prepare the dom header element that will serve as toggler
	 * @param {Node} node header element
	 * @param {String} headerId unique id of the header element
	 * @param {String} panelId unique id of the panel element
	 * @param {Boolean} panelIsOpened should the panel be opened by default?
	 */
	prepareHeader(node, headerId, panelId, panelIsOpened) {
		// create button with aria attributes
		const button = document.createElement('BUTTON');
		button.innerHTML = node.innerHTML;

		const attrs = {
			'data-accordion-id': this.vid,
			type: 'button',
			role: 'tab',
			id: headerId,
			'aria-controls': panelId,
			'aria-selected': 'false',
			'aria-expanded': panelIsOpened ? 'true' : 'false',
		};

		Object.keys(attrs).forEach((key) => {
			button.setAttribute(key, attrs[key]);
		});

		// replace header content by the button
		// eslint-disable-next-line no-param-reassign
		node.innerHTML = '';
		node.appendChild(button);
	}

	/**
	 * Reset the dom header element to its initial state
	 * To use when we destroy the accordion for example
	 * @param {Node} node header element
	 */
	resetHeader(node) {
		// vars
		const button = node.querySelector('[role="tab"]');

		// bail early if there's no button
		if (button === null) return; // this shouldn't happen but hey, life can be funny sometimes

		// remove the button and put the content back in place
		const nodeHtml = button.innerHTML;
		// eslint-disable-next-line no-param-reassign
		node.innerHTML = nodeHtml;
	}

	/**
	 * Prepare the dom panel element that will serve as toggled content
	 * @param {Node} node panel element
	 * @param {String} headerId unique id of the header element
	 * @param {String} panelId unique id of the panel element
	 * @param {Boolean} panelIsOpened should the panel be opened by default?
	 */
	preparePanel(node, headerId, panelId, panelIsOpened) {
		const attrs = {
			'data-accordion-id': this.vid,
			role: 'tabpanel',
			'aria-labelledby': headerId,
			id: panelId,
			'aria-hidden': panelIsOpened ? 'false' : 'true',
		};

		Object.keys(attrs).forEach((key) => {
			node.setAttribute(key, attrs[key]);
		});
	}

	/**
	 * Reset the dom panel element to its initial state
	 * To use when we destroy the accordion for example
	 * @param {Node} node panel element
	 */
	resetPanel(node) {
		node.removeAttribute('data-accordion-id');
		node.removeAttribute('role');
		node.removeAttribute('aria-labelledby');
		node.removeAttribute('id');
		node.removeAttribute('aria-hidden');
	}

	// ============================ //
	// #endregion PREPARE/RESET DOM
	// ============================ //


	// ================= //
	// #region EVENTS
	// ================= //

	/**
	 * Attach all events
	 */
	bindEvents() {
		// note: we avoid affecting nested accordions by filtering with data-accordion-id
		this.on('click', `[role="tab"][data-accordion-id="${this.vid}"]`, e => this.handleButtonClick(e));
		this.on('keydown', `[role="tab"][data-accordion-id="${this.vid}"]`, e => this.handleButtonKeydown(e));
		this.on('focus', `[role="tab"][data-accordion-id="${this.vid}"]`, e => this.handleButtonFocus(e));
		this.on('blur', `[role="tab"][data-accordion-id="${this.vid}"]`, e => this.handleButtonBlur(e));
	}

	/**
	 * Detach all events
	 * To use when we destroy the accordion for example
	 */
	// note: not needed anzmore, this is handled by the baseview in destroy()
	// unbindEvents() {
	// 	this.off('click', `[role="tab"][data-accordion-id="${this.vid}"]`);
	// 	this.off('keydown', `[role="tab"][data-accordion-id="${this.vid}"]`);
	// 	this.off('focus', `[role="tab"][data-accordion-id="${this.vid}"]`);
	// }

	handleButtonClick(e) {
		// vars
		const buttonNode = e.target;
		const panelNode = this.getPanelFromButton(buttonNode);

		// bail early if panel doesn't exist
		if (panelNode === null) return;

		// prevent default event
		e.preventDefault();

		const wasExpanded = buttonNode.getAttribute('aria-expanded');

		// toggle current header/panel state
		if (wasExpanded === 'true') {
			buttonNode.setAttribute('aria-expanded', 'false');
			panelNode.setAttribute('aria-hidden', 'true');
			this.getItemFromButton(buttonNode).classList.remove(CLASS_IS_OPEN);
		} else {
			buttonNode.setAttribute('aria-expanded', 'true');
			panelNode.setAttribute('aria-hidden', 'false');
			this.getItemFromButton(buttonNode).classList.add(CLASS_IS_OPEN);
		}

		// close other panels
		if (
			!this.config.multiselectable // = the accordion is not multiselectable
			&& wasExpanded === 'false' // = we are opening
		) {
			this.getButtonNodes().forEach((currentButtonNode) => {
				// avoid the clicked button
				if (currentButtonNode === buttonNode) return;

				// vars
				const currentPanelNode = this.getPanelFromButton(currentButtonNode);

				// close the panel
				currentButtonNode.setAttribute('aria-selected', 'false');
				currentButtonNode.setAttribute('aria-expanded', 'false');
				currentPanelNode.setAttribute('aria-hidden', 'true');
				this.getItemFromButton(currentButtonNode).classList.remove(CLASS_IS_OPEN);
			});
		}

		// focus the clicked button
		setTimeout(buttonNode.focus(), 0);
	}

	handleButtonFocus(e) {
		// vars
		const buttonNode = e.target;

		// update 'aria-selected' attribute on all buttons
		this.getButtonNodes().forEach((currentButtonNode) => {
			const isFocused = currentButtonNode === buttonNode;
			currentButtonNode.setAttribute('aria-selected', isFocused ? 'true' : 'false');
			if (isFocused) {
				// this.getPanelFromButton(currentButtonNode).classList.add(CLASS_HAS_FOCUS);
				this.getItemFromButton(currentButtonNode).classList.add(CLASS_HAS_FOCUS);
			} else {
				// this.getPanelFromButton(currentButtonNode).classList.remove(CLASS_HAS_FOCUS);
				this.getItemFromButton(currentButtonNode).classList.remove(CLASS_HAS_FOCUS);
			}
		});
	}

	handleButtonBlur(e) {
		// vars
		const buttonNode = e.target;
		this.getItemFromButton(buttonNode).classList.remove(CLASS_HAS_FOCUS);
	}

	handleButtonKeydown(e) {
		// vars
		let buttons;
		let shouldSelectIndex;

		// pressed `home` => 1st tab
		if (e.keyCode === 36) {
			shouldSelectIndex = 0;
		}

		// pressed `end` => last tab
		else if (e.keyCode === 35) {
			buttons = this.getButtonNodes();
			shouldSelectIndex = buttons.length - 1;
		}

		// pressed `up` or `left` => previous tab
		else if ((e.keyCode === 37 || e.keyCode === 38) && !e.ctrlKey) {
			buttons = this.getButtonNodes();
			const selectedIndex = this.getSelectedButtonIndex(buttons);

			// define tab
			if (selectedIndex < 1) { // reached the beginning => last tab
				shouldSelectIndex = buttons.length - 1;
			} else { // previous tab
				shouldSelectIndex = selectedIndex - 1;
			}
		}

		// pressed `down` or `right` => previous tab
		else if ((e.keyCode === 40 || e.keyCode === 39) && !e.ctrlKey) {
			buttons = this.getButtonNodes();
			const selectedIndex = this.getSelectedButtonIndex(buttons);

			// define tab
			if (selectedIndex === buttons.length - 1) { // last tab selected => first tab
				shouldSelectIndex = 0;
			} else { // next tab
				shouldSelectIndex = selectedIndex + 1;
			}
		}

		// bail early if user is not navigating
		if (typeof shouldSelectIndex === 'undefined') return;

		// prevent default event
		e.preventDefault();

		// fetch buttons if not yet done
		if (typeof buttons === 'undefined') {
			buttons = this.getButtonNodes();
		}

		// select target button and unselect all others
		buttons.forEach((buttonNode, index) => {
			if (index === shouldSelectIndex) {
				buttonNode.setAttribute('aria-selected', 'true');
				setTimeout(buttonNode.focus(), 0);
			} else {
				buttonNode.setAttribute('aria-selected', 'false');
			}
		});
	}

	// ================= //
	// #endregion EVENTS
	// ================= //


	// =============== //
	// #region HELPERS
	// =============== //

	/**
	 * Get the config
	 * @returns {Object}
	 */
	getConfig() {
		// read config from data-attrs
		return {
			multiselectable: this.el.hasAttribute(`${SELECTOR}-multiselectable`),
			jsClass: this.el.hasAttribute(`${SELECTOR}-js-class`) ? this.el.getAttribute(`${SELECTOR}-js-class`) : 'js',
		};
	}

	/**
	 * Get all headers
	 * @returns {Array} an array of Nodes
	 */
	getHeaderNodes() {
		return [].slice.call(this.el.querySelectorAll(`[${SELECTOR}-header]`));
	}

	/**
	 * Get all buttons
	 * @returns {Array} an array of Nodes
	 */
	getButtonNodes() {
		// note: we avoid affecting nested accordions by filtering with data-accordion-id
		return [].slice.call(this.el.querySelectorAll(`[role="tab"][data-accordion-id="${this.vid}"]`));
	}

	getSelectedButtonIndex(buttons) {
		return buttons.findIndex(currentButtonNode => currentButtonNode.getAttribute('aria-selected') === 'true');
	}

	/**
	 * Get the panel controlled by a given button
	 * @param {Node} node the button Node
	 */
	getItemFromButton(node) {
		return node.closest(`[${SELECTOR}-item]`);
	}

	/**
	 * Get the panel controlled by a given button
	 * @param {Node} node the button Node
	 */
	getPanelFromButton(node) {
		return this.el.querySelector(`#${node.getAttribute('aria-controls')}`);
	}

	// ================== //
	// #endregion HELPERS
	// ================== //

	destroy() {
		this.resetDom();
	}
}
