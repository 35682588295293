import {
	TimelineLite, TweenLite, Power0, ExpoScaleEase,
} from 'gsap/all';
import { CustomEase } from '../../../js/utils/gsap/CustomEase';
import BaseView from '../../../js/base-view';

const SELECTOR_WRAPPER = '.media-header__wrapper';
const SELECTOR_CLIP = '.media-header__clip';
const SELECTOR_MASK = '.media-header__mask';
const SELECTOR_MEDIA_WRAPPER = '.media-header__media-wrapper';
const SELECTOR_WRAPPER_TITLE = '.media-header__title-wrapper';
const SELECTOR_DOM_TITLE = '.media-header__title--dom';
const SELECTOR_ITEM = '.media-header__item';
const CLASS_LOADED = 'media-header--loaded';
const MEDIA_COMPENSATION = 40;

export default class MediaHeader extends BaseView {
	/**
	 * Entrypoint
	 */
	init() {
		// refs
		// to keep reference of scoped dom elements
		this.refs = {
			wrapper: this.el.querySelector(SELECTOR_WRAPPER),
			clip: this.el.querySelector(SELECTOR_CLIP),
			mask: this.el.querySelector(SELECTOR_MASK),
			mediaWrapper: this.el.querySelector(SELECTOR_MEDIA_WRAPPER),
			titleClipWrapper: this.el.querySelector(SELECTOR_WRAPPER_TITLE),
			titleDom: this.el.querySelector(SELECTOR_DOM_TITLE),
		};

		// props
		// the component's static configuration
		this.props = {
			// myProperty: this.refs.subElement.getAttribute(ATTR_MYPROPERTY),
		};

		// state
		// the component's dynamic data representing its "state"
		this.state = {
			scaleClip: 1,
			contraScaleClip: 1,
			tTitle: {},
		};

		this.cache = {
			tlLoad: new TimelineLite(),
		};

		// attach events
		this.bindEvents();
	}

	/**
	 * Bind component's events
	 */
	bindEvents() {
		this.updateHandler = this.updateSection.bind(this);
		this.setupHandler = this.setupSection.bind(this);
		window.addEventListener('mediaLoaded', this.setupHandler);
		window.addEventListener('archiveTransition', this.setupHandler(false));
		this.on('resizeFast', this.updateSection.bind(this));
	}

	// --------------------------------
	// #region Event Handlers
	// --------------------------------

	/**
	 * Setup section
	 *
	 * @param animate
	 */
	setupSection(animate = true) {
		const speed = animate ? 1 : 0;
		const speedify = (seconds) => speed * seconds;

		this.resizeSection();
		this.updateClip();

		// setup everything to start animation
		this.el.classList.add(CLASS_LOADED);
		const txTitleScale = this.state.tTitle.x + MEDIA_COMPENSATION;
		const tyTitleScale = this.state.tTitle.y + (this.refs.clip.offsetHeight * this.state.scaleClip - this.refs.clip.offsetHeight) / 2;

		// circle animation common values (circle + masked title + image)
		const circleAnimDuration = speedify(0.65);
		const circleAnimDelay = speedify(0.07);
		const easeFunction = CustomEase.create('mjfCircleEase', '0.1, 0, 0.07, 1');

		// load animation
		this.cache.tlLoad.kill().clear()
			.set(SELECTOR_ITEM, {
				css: {
					y: '40px',
				},
			}, 0)
			// all contents (title, copyright, partner logo,…)
			.from(SELECTOR_ITEM, speedify(0.18), {
				ease: Power0.easeNone,
				css: {
					opacity: 0,
				},
			}, circleAnimDelay)
			.to(SELECTOR_ITEM, circleAnimDuration, {
				ease: CustomEase.create('custom', '0.1, 0, 0.07, 1'),
				css: {
					y: '0px',
				},
			}, circleAnimDelay)
			// circle "mask" (container of mask, media, masked title)
			.from(this.refs.clip, speedify(0.12), {
				ease: Power0.easeNone,
				css: {
					opacity: 0,
				},
			}, circleAnimDelay)
			// circle "clip" (contains media + masked title)
			.from(this.refs.clip, circleAnimDuration, {
				ease: ExpoScaleEase.config(this.state.scaleClip, 1, easeFunction),
				css: {
					scale: this.state.scaleClip,
				},
			}, circleAnimDelay)
			// circle > white title (inside circle -> compensate the circle scale)
			.from(this.refs.titleClipWrapper, circleAnimDuration, {
				ease: ExpoScaleEase.config(this.state.contraScaleClip, 1, easeFunction),
				css: {
					transform: `scale(${ this.state.contraScaleClip }, ${ this.state.contraScaleClip }) translate3d(${ txTitleScale }px, ${ tyTitleScale }px, 0)`,
				},
			}, circleAnimDelay)
			.from(this.refs.mediaWrapper, circleAnimDuration, {
				ease: ExpoScaleEase.config(this.state.contraScaleClip, 1, easeFunction),
				css: {
					scale: this.state.contraScaleClip,
				},
			}, circleAnimDelay)
			.play();
	}

	/**
	 * Update section
	 */
	updateSection() {
		this.resizeSection();
		this.updateClip();
	}

	// --------------------------------
	// #endregion
	// --------------------------------

	// --------------------------------
	// #region Actions
	// --------------------------------

	/**
	 * Resize section
	 * To avoid the bottom of the image being equal to the bottom of the section
	 */
	resizeSection() {
		// difference between image and inner section
		// const diffImgRect = this.refs.mask.offsetHeight - this.refs.content.offsetHeight;
		if (viewport_service.getViewportWidth() >= 900) {
			// estimate section height
			this.refs.wrapper.style.minHeight = `${ this.refs.mask.offsetHeight }px`;
		}
		else {
			this.refs.wrapper.style.minHeight = null;
		}
	}

	/**
	 * Update Clip
	 */
	updateClip() {
		// update clip
		this.state.scaleClip = (this.refs.mask.offsetWidth + MEDIA_COMPENSATION * 2) / this.refs.mask.offsetWidth;
		this.state.contraScaleClip = 1 / this.state.scaleClip;

		// update text size/position
		this.state.tTitle.x = this.refs.titleDom.offsetLeft - this.refs.mask.offsetLeft;
		this.state.tTitle.y = this.refs.titleDom.offsetTop - this.refs.mask.offsetTop;
		this.refs.titleClipWrapper.style.width = `${ this.refs.titleDom.offsetWidth }px`;
		TweenLite.set(this.refs.titleClipWrapper, { x: this.state.tTitle.x });
		TweenLite.set(this.refs.titleClipWrapper, { y: this.state.tTitle.y });
	}

	// --------------------------------
	// #endregion
	// --------------------------------

	/**
	 * Before the component gets destroyed
	 * - unbind any event not bound with this.on()
	 * - reset UI if needed (any classes/attributes added?)
	 */
	beforeDestroy() {
		// this.resetUI();
		window.removeEventListener('mediaLoaded', this.setupHandler);
		window.removeEventListener('archiveTransition', this.setupHandler(false));
	}
}
