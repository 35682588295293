import BaseInput from '../_0-input/input';

const ATTR_ERROR = 'data-error';

const SELECTOR_FIELD = '.input-date__field';

export default class InputDate extends BaseInput {
	init() {
		this.refs = {
			fields: Array.from(this.el.querySelectorAll(SELECTOR_FIELD)),
		};

		this.props = {
			names: this.refs.fields.map((f) => f.name),
			basename: this.refs.fields[ 0 ].name.replace(/\[.*\]/, ''),
			maxlengths: this.refs.fields.map((f) => parseInt(f.getAttribute('maxlength'))),
		};

		this.state = {
			isConditinal: false,
			shouldFocusField: 0,
			isErroneous: false,
		};

		this.bindEvents();
		this.checkAutofill()
	}

	bindEvents() {
		this.on('keyup', SELECTOR_FIELD, this.onKeyup.bind(this));
		this.on('keypress', SELECTOR_FIELD, this.onKeypress.bind(this));
		this.on('focusout', SELECTOR_FIELD, this.onFocusout.bind(this));
	}

	// #region EVENT HANDLERS

	onKeyup(event) {
		const idx = this.getIdx(event.target);
		this.isGoodLength(this.refs.fields[ idx ], idx);
		// this.autoTab(this.refs.fields[idx], idx);
	}

	onKeypress(event) {
		const idx = this.getIdx(event.target);
		setTimeout(this.autoTab.bind(this, this.refs.fields[ idx ], idx), 0);
	}

	onFocusout(event) {
		const idx = this.getIdx(event.target);
		this.prefixValue(this.refs.fields[ idx ], idx);

		if (idx === this.refs.fields.length - 1) {
			this.checkValidity();
		}
	}

	// #endregion

	// #region HELPERS

	getIdx(field) {
		return this.refs.fields.indexOf(field);
	}

	getName() {
		return this.props.basename;
	}

	// #endregion

	// #region ACTIONS

	setValue(value) {
		const [year, month, day] = value.split(/\.|\/|-/);
		this.refs.fields[0].value = day
		this.refs.fields[1].value = month
		this.refs.fields[2].value = year

	}

	isGoodLength(field, idx) {
		// input not full? bail!
		if (field.value.length < this.props.maxlengths[ idx ]) {
			return;
		}

		// input full: prevent adding new characters
		// eslint-disable-next-line no-param-reassign
		field.value = field.value.slice(0, this.props.maxlengths[ idx ]);
	}

	prefixValue(field, idx) {
		// input has two digits
		if (field.value.length >= this.props.maxlengths[ idx ]) {
			return;
		}

		// input has only 1 digit --> let's add '0' before the number
		// eslint-disable-next-line no-param-reassign
		field.value = `0${ field.value }`;
	}

	autoTab(field, idx) {
		// if input not full? bail!
		if (field.value.length < this.props.maxlengths[ idx ]) {
			return;
		}

		// focus next input
		if (idx < this.refs.fields.length - 1) {
			this.refs.fields[ idx + 1 ].focus();
		}
		else {
			this.checkValidity();
		}
	}

	/**
	 * TODO Improve validation
	 *      - when not required min attr will fail validation
	 *      - year should be checked
	 *      - 31st of february is wrong
	 */
	checkValidity() {
		const invalidFields = this.refs.fields.filter((f) => ! f.checkValidity());

		if (invalidFields.length) {
			const invalidIdx = this.refs.fields.indexOf(invalidFields[ 0 ]);
			this.shouldFocusField = invalidIdx;
			this.setError(this.refs.fields[ invalidIdx ].validationMessage);
			return false;
		}

		this.resetError();
		return true;
	}

	setError(errorMessage = '') {
		this.state.isErroneous = true;
		this.el.setAttribute(ATTR_ERROR, errorMessage);
	}

	resetError() {
		this.state.isErroneous = false;
		this.state.shouldFocusField = 0;
		this.el.removeAttribute(ATTR_ERROR);
	}

	disable() {
		this.refs.fields.forEach((field) => field.setAttribute('disabled', true));
	}

	enable() {
		// Do not enable field if field is condition & should still be hidden
		if (this.props.isConditional && this.state.isHidden) {
			return;
		}
		this.refs.fields.forEach((field) => field.removeAttribute('disabled'));
	}

	focus() {
		this.refs.fields[ this.state.shouldFocusField ].focus();
	}

	// #endregion
}
