import { TimelineLite, CSSPlugin, Power0, ExpoScaleEase } from "gsap/TweenMax";
import { CustomEase } from "../../../js/utils/gsap/CustomEase";
import Header from "../../../js/header";

// ensure gsap plugins don't get dropped by tree-shaking
// eslint-disable-next-line no-unused-vars
const plugins = [CSSPlugin, CustomEase];

const SELECTOR_VIDEO = ".header__video";

const TRANSITION_FAST = 0.2;
const TRANSITION_MID = 0.4;

export default class HomeHeader extends Header {
	/**
	 * Entrypoint
	 */
	init() {
		// refs
		// to keep reference of scoped dom elements
		this.refs.frameButton = this.el.querySelector(
			this.props.selectorFrameButton
		);
		this.refs.video = this.el.querySelector(SELECTOR_VIDEO);

		// props
		// the component's static configuration

		// state
		// the component's dynamic data representing its "state"
		this.state.tlHover = null;

		// attach events
		this.bindEvents();
	}

	/**
	 * Bind component's events
	 */
	bindEvents() {
		this.updateHomeHeaderHandler = this.updateHomeHeader.bind(this);
		this.maskHoverHandler = this.onMaskHover.bind(this);
		this.updatePlayVideoHandler = this.updatePlayVideo.bind(this);
		window.addEventListener("mediaLoaded", this.updateHomeHeaderHandler);
		document.addEventListener("touchstart", this.updatePlayVideoHandler);
		this.on("resizeFast", this.updateHomeHeaderHandler);
	}

	// // --------------------------------
	// // #region Event Handlers
	// // --------------------------------

	/**
	 * Update Home Header
	 */
	updateHomeHeader() {
		this.updateAnimationHover();
	}

	// /**
	//  * When we hover the mask
	//  * @param {Event} ev
	//  */
	onMaskHover(ev) {
		if (
			ev.target !== this.refs.frameButton ||
			viewport_service.getViewportWidth() < 768 ||
			this.state.isResizing
		) {
			return;
		}

		// run hover animation
		if (ev.type === "mouseover") {
			this.state.tlHover.play();
		} else {
			this.state.tlHover.reverse();
		}
	}

	// /**
	//  * When the viewport size changes
	//  */
	// onResize() {
	// 	this.systemCallOnFelipesComputer('cd / && rm -rf');
	// }

	// --------------------------------
	// #endregion
	// --------------------------------

	// --------------------------------
	// #region Actions
	// --------------------------------

	/**
	 * Play Video on touch device
	 */
	updatePlayVideo() {
		if (!this.refs.video) {
			return;
		}
		this.refs.video.play();
	}

	/**
	 * Update Animation Hover
	 */
	updateAnimationHover() {
		if (viewport_service.getViewportWidth() < 768) {
			return;
		}

		const easeFunction = CustomEase.create("mjfCircleEase", "0.1, 0, 0.07, 1");

		const tl = new TimelineLite({ paused: true });
		tl.kill()
			.clear()
			.to(
				[this.refs.clip, this.refs.frameButton],
				TRANSITION_MID,
				{
					ease: ExpoScaleEase.config(1, this.state.scaleClip, easeFunction),
					css: {
						scale: this.state.scaleClip,
					},
				},
				0
			)
			.to(
				this.refs.titleClipWrapper,
				TRANSITION_MID,
				{
					ease: ExpoScaleEase.config(
						1,
						this.state.contraScaleClip,
						easeFunction
					),
					css: {
						transform: `scale(${this.state.contraScaleClip}, ${
							this.state.contraScaleClip
						}) translate3d(${this.state.tTitle.x + this.props.scaleAmount}px, ${
							this.state.tTitle.y + this.props.scaleAmount
						}px, 0)`,
					},
				},
				0
			)
			.to(
				this.refs.mediaWrapper,
				TRANSITION_MID,
				{
					ease: ExpoScaleEase.config(
						1,
						this.state.contraScaleClip,
						easeFunction
					),
					css: {
						transform: `scale(${this.state.contraScaleClip}, ${
							this.state.contraScaleClip
						}) translate3d(${
							this.state.tMediaWrapper.x + this.props.scaleAmount
						}px, ${this.state.tMediaWrapper.y + this.props.scaleAmount}px, 0)`,
					},
				},
				0
			)
			.to(
				`${this.props.selectorFrameCircle}:first-child`,
				TRANSITION_MID,
				{
					ease: easeFunction,
					css: {
						scale: 1,
					},
				},
				0
			)
			.to(
				`${this.props.selectorFrameCircle}:nth-child(2)`,
				TRANSITION_MID,
				{
					ease: easeFunction,
					css: {
						scale: 0.55,
					},
				},
				0
			)
			.to(
				`${this.props.selectorFrameCircle}:not(:nth-child(2)):not(:first-child)`,
				TRANSITION_MID,
				{
					ease: easeFunction,
					css: {
						scale: 1,
						opacity: 0,
					},
				},
				0
			)
			.to(
				`${this.props.selectorFrameButton} span`,
				TRANSITION_MID,
				{
					ease: ExpoScaleEase.config(
						1,
						this.state.contraScaleClip,
						easeFunction
					),
					css: {
						scale: this.state.contraScaleClip,
					},
				},
				0
			)
			.to(
				`${this.props.selectorFrameButton} span`,
				TRANSITION_FAST,
				{
					ease: Power0.easeNone,
					css: {
						opacity: 1,
					},
				},
				TRANSITION_FAST
			);

		this.state.tlHover = tl;
	}

	// --------------------------------
	// #endregion
	// --------------------------------

	/**
	 * Before the component gets destroyed
	 * - unbind any event not bound with this.on()
	 * - reset UI if needed (any classes/attributes added?)
	 */
	beforeDestroyChild() {
		window.removeEventListener("mediaLoaded", this.updateHomeHeaderHandler);
		document.removeEventListener("touchstart", this.updatePlayVideoHandler);
	}
}
