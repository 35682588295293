/* eslint-disable curly */
import { TweenLite, Power2, ScrollToPlugin } from 'gsap/all';
import BaseView from '../../../js/base-view';
import AccountData from '../../../js/services/account-data';

// ensure gsap plugins don't get dropped by tree-shaking
// eslint-disable-next-line no-unused-vars
const plugins = [ScrollToPlugin];

const SELECTOR_MOBILE_NAV = '.ud-nav__mobile';
const SELECTOR_MOBILE_NAV_ITEM = '.ud-nav__mobile__item';

export default class UdNav extends BaseView {
	init() {
		this.accountData = AccountData();

		this.refs = {
			name : this.el.querySelector('.ud-nav__profile-name'),
			email: this.el.querySelector('.ud-nav__profile-email'),
			badge: this.el.querySelector('.ud-nav__profile-plan'),
			mobileNav: this.el.querySelector(SELECTOR_MOBILE_NAV),
			mobileItems: [...this.el.querySelectorAll(SELECTOR_MOBILE_NAV_ITEM)],
			activeMenuItem: null,
		}
		this.state = {
			currentPage: 'mjf',
		}
		this.setNavData();
		this.bindEvents();

		this.updateCurrentPage(false);
	}

	setNavData() {
		if (this.refs.name) this.refs.name.textContent = this.accountData.get('display_name');
		if (this.refs.email) this.refs.email.textContent = this.accountData.get('contact.email');
		if (this.refs.badge) this.refs.badge.innerHTML = this.accountData.get('badge');
	}

	bindEvents() {
		this.onAccountDataChangeHandler = this.onAccountDataChange.bind(this);
		this.menuMobileClickHandler = this.onMenuMobileClick.bind(this);
		this.accountData.on('change', this.onAccountDataChangeHandler);
		this.on('click', SELECTOR_MOBILE_NAV_ITEM, this.menuMobileClickHandler);
	}

	onAccountDataChange() {
		this.setNavData();
	}

	updateCurrentPage(animate, page = '') {
		this.state.currentPage = page || [...document.body.classList].find(name => name.startsWith('page--account-')).split('-').pop();
		this.updateMenuActiveItem();
		this.scrollCenter(animate);
	}

	updateMenuActiveItem() {

		this.refs.mobileItems.forEach(item => {
			if(item.dataset.key === this.state.currentPage) {
				item.classList.add('is-active');
				this.refs.activeMenuItem = item;
			}
			else {
				item.classList.remove('is-active');
			}
		});
	}

	onMenuMobileClick(ev) {
		this.updateCurrentPage(true, ev.target.dataset.key);
	}

	scrollCenter(animate) {
		const navWidth = this.refs.mobileNav.offsetWidth;
		const navScrollWidth = this.refs.mobileNav.scrollWidth;

		if(navScrollWidth > navWidth) {
			const elPos = this.refs.activeMenuItem.offsetWidth/2 + this.refs.activeMenuItem.offsetLeft

			const scrollPos = elPos - navWidth/2;
			if(animate) {
				TweenLite.to(this.refs.mobileNav, 0.5, { scrollTo:{ x:scrollPos }, ease:Power2.easeOut });
			}
			else {
				this.refs.mobileNav.scrollLeft = scrollPos;
			}
		}

	}

	beforeDestroy() {
		this.accountData.off('change', this.onAccountDataChangeHandler);
	}
}
