/* eslint-disable curly */
import MySection from '../my-section/my-section';


export default class SectionPackage extends MySection {
	init() {
		this.refs = {
			plan: this.el.querySelector('.subscription__item-plan .subscription__item__text'),
			price: this.el.querySelector('.subscription__item-price .subscription__item__text'),
			date: this.el.querySelector('.subscription__item-date .subscription__item__text'),
		};

		this.setData();
	}

	setData() {
		if (this.refs.plan) this.refs.plan.textContent = this.accountData.get('subscription.plan');
		if (this.refs.price) this.refs.price.textContent = this.accountData.get('subscription.price');
		if (this.refs.date) this.refs.date.textContent = this.accountData.get('subscription.date');

		if (this.accountData.get('subscription.btn') === false) {
			this.el.querySelector('.my-section__content').removeChild(this.el.querySelector('form'));
			this.el.querySelector('.subscription').removeChild(this.el.querySelector('.subscription__item-price'));
		}
	}
}
