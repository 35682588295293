/* eslint-disable curly */
import BaseView from '../../../js/base-view';
import Loadmore from '../../2-javascript/loadmore/loadmore';

const SELECTOR_INNER = '.articles-list__inner';
const SELECTOR_CARD = '.card-article';

const ATTR_LOAD_MORE = 'data-loadmore-article-list';
const SELECTOR_LOAD_MORE = `[${ ATTR_LOAD_MORE }]`;

const CLASS_NB_ITEM_PREFIX = 'has-';
const CLASS_SMALL_LAYOUT = 'articles-list--small';
const CLASS_CARD_MODIFIER_SMALL = 'card-article--small';

const CLASS_IS_LOADING = 'is-loading';

export default class ArticlesList extends BaseView {
	/**
	 * Entrypoint
	 */
	init() {
		this.refs = {
			inner: this.el.querySelector(SELECTOR_INNER),
			cards: Array.from(this.el.querySelectorAll(SELECTOR_CARD)),

			loadmore: null,
		};

		this.props = {
			isSmallLayout: this.el.classList.contains(CLASS_SMALL_LAYOUT),
		};

		this.state = new Proxy(
			{
				nbCards: this.refs.cards.length,
			},
			{ set: this.stateChange.bind(this) }
		);

		const loadmoreEl = this.el.querySelector(SELECTOR_LOAD_MORE);
		if (loadmoreEl) {
			this.refs.loadmore = new Loadmore(loadmoreEl).init(ATTR_LOAD_MORE);
		}

		// Allows to chain
		return this;
	}

	/**
	 * Trap handler for the state object
	 *
	 * @param {Object} state Current state object
	 * @param {string} property The name of the property to set in the state
	 * @param {any} value The new value of the property to set
	 *
	 * @return {boolean} Indicate wether or not the assignment succeeded
	 */
	stateChange(state, property, value) {
		// Bail early if value did not change
		if (value === state[ property ]) {
			return true;
		}

		const prevValue = state[ property ];

		// eslint-disable-next-line no-param-reassign
		state[ property ] = value; // Update the state

		// eslint-disable-next-line default-case
		switch (property) {
			case 'nbCards':
				this.updateNbCardsClass(prevValue, value);
				break;
			case 'isLoading':
				this.updateIsLoading();
				break;
		}

		return true;
	}

	// --------------------------------
	// #region DOM Manipulation
	// --------------------------------

	updateNbCardsClass(prevVal, newVal) {
		this.el.classList.remove(`${ CLASS_NB_ITEM_PREFIX }${ prevVal }`);
		this.el.classList.add(`${ CLASS_NB_ITEM_PREFIX }${ newVal }`);
	}

	updateIsLoading() {
		this.el.classList[ this.state.isLoading ? 'add' : 'remove' ](CLASS_IS_LOADING);
	}

	// --------------------------------
	// #endregion
	// --------------------------------

	// --------------------------------
	// #region API (meant to be used by parent component)
	// --------------------------------

	/**
	 * Getter for the number of cards in the list
	 */
	getNbCards() {
		return this.state.nbCards;
	}

	/**
	 * Setter for the loading state of the Article list
	 *
	 * @param {boolean} isLoading The bool value of the state.
	 *                            Optional. Default: true
	 */
	setIsLoading(isLoading = true) {
		this.state.isLoading = isLoading;
	}

	/**
	 * Insert the given cards in the list at the right position
	 *
	 * @param {Array} cards     Array of Element to insert in the list
	 * @param {string} position Where to insert the cards `beforeend` | `afterbegin`.
	 *                          Optional. Default: beforeend.
	 */
	insertCards(cards = [], position = 'beforeend') {
		// eslint-disable-next-line no-param-reassign,curly
		if (position === 'afterbegin') cards = cards.reverse();

		cards.forEach((c) => {
			c.classList[ this.props.isSmallLayout ? 'add' : 'remove' ](CLASS_CARD_MODIFIER_SMALL);
			this.refs.inner.insertAdjacentElement(position, c);
		});

		this.refs.cards = Array.from(this.el.querySelectorAll(SELECTOR_CARD));
		this.state.nbCards = this.refs.cards.length;
	}

	/**
	 *
	 * @param {number} nb         Number of cards to extract.
	 *                            Optional. Default: 1.
	 * @param {boolean} fromStart Extract the cards from the begining or at the end.
	 *                            Optional. Default: true.
	 * @return {Array} Array of extracted Element cards
	 */
	extractCards(nb = 1, fromStart = true) {
		const start = fromStart ? 0 : this.state.nbCards - nb;

		const extractedCards = this.refs.cards.splice(start, nb);

		extractedCards.forEach((c) => this.refs.inner.removeChild(c));
		this.state.nbCards = this.state.nbCards - extractedCards.length;

		return extractedCards;
	}

	// --------------------------------
	// #endregion
	// --------------------------------

	beforeDestroy() {
		if (this.refs.loadmore) this.refs.loadmore.destroy();
	}
}
