/* eslint-disable curly */
import BaseView from '../../../js/base-view';
import AccountData from '../../../js/services/account-data';

export default class MySection extends BaseView {
	constructor(...args) {
		super(...args);
		this.accountData = AccountData();
	}
}
