/* eslint-disable curly */

import MySection from '../my-section/my-section';


export default class SectionPreferences extends MySection {
	init() {
		this.refs = {
			form: this.el.querySelector('form'),
			nonce: this.el.querySelector('[name="user_preferences_nonce"]'),
			notification: [...this.el.querySelectorAll('[name*="user_preferences[notification]"]')],
			communication: [...this.el.querySelectorAll('[name="user_preferences[communication]"]')],
		};

		this.setData();
		this.bindEvents();
	}

	setData() {
		const {
			notification = {},
			communication = [],
			...prefsData
		} = this.accountData.get('preferences') ?? {};

		if (this.refs.nonce) this.refs.nonce.value = prefsData.nonce;
		if (this.refs.notification) this.refs.notification.forEach(el => {
			// eslint-disable-next-line no-param-reassign
			el.checked = notification[el.value] ?? false
		})
		if (this.refs.communication) this.refs.communication.forEach(el => {
			// eslint-disable-next-line no-param-reassign
			el.checked = communication.includes(el.value)
		})
	}

	bindEvents() {
		this.on('form:success', 'form', this.onFormSuccess.bind(this))
		this.on('form:reset', 'form', this.onFormReset.bind(this))
	}

	onFormSuccess({ detail: data }) {
		this.accountData.setData(data);
		this.setData();
	}

	onFormReset() {
		this.setData();
	}
}
