var thumbnailDefaults = {
    thumbnail: true,

    animateThumb: true,
    currentPagerPosition: 'middle',

    thumbWidth: 100,
    thumbContHeight: 100,
    thumbMargin: 5,

    exThumbImage: false,
    showThumbByDefault: true,
    toggleThumb: true,
    pullCaptionUp: true,

    enableThumbDrag: true,
    enableThumbSwipe: true,
    swipeThreshold: 50,

    loadYoutubeThumbnail: true,
    youtubeThumbSize: 1,

    loadVimeoThumbnail: true,
    vimeoThumbSize: 'thumbnail_small',

    loadDailymotionThumbnail: true
};

var Thumbnail = function(element) {

    this.el = element;

    this.core = window.lgData[this.el.getAttribute('lg-uid')];
    this.core.s = Object.assign({}, thumbnailDefaults, this.core.s);

    this.thumbOuter = null;
    this.thumbOuterWidth = 0;
    this.thumbTotalWidth = (this.core.items.length * (this.core.s.thumbWidth + this.core.s.thumbMargin));
    this.thumbIndex = this.core.index;

    // Thumbnail animation value
    this.left = 0;

    this.init();

    return this;
};

Thumbnail.prototype.init = function() {
    var _this = this;
    if (this.core.s.thumbnail && this.core.items.length > 1) {
        if (this.core.s.showThumbByDefault) {
            setTimeout(function() {
                utils.addClass(_this.core.outer, 'lg-thumb-open');
            }, 700);
        }

        if (this.core.s.pullCaptionUp) {
            utils.addClass(this.core.outer, 'lg-pull-caption-up');
        }

        this.build();
        if (this.core.s.animateThumb) {
            if (this.core.s.enableThumbDrag && !this.core.isTouch && this.core.doCss()) {
                this.enableThumbDrag();
            }

            if (this.core.s.enableThumbSwipe && this.core.isTouch && this.core.doCss()) {
                this.enableThumbSwipe();
            }

            this.thumbClickable = false;
        } else {
            this.thumbClickable = true;
        }

        this.toggle();
        this.thumbkeyPress();
    }
};

Thumbnail.prototype.build = function() {
    var _this = this;
    var thumbList = '';
    var vimeoErrorThumbSize = '';
    var $thumb;
    var html = '<div class="lg-thumb-outer">' +
        '<div class="lg-thumb group">' +
        '</div>' +
        '</div>';

    switch (this.core.s.vimeoThumbSize) {
        case 'thumbnail_large':
            vimeoErrorThumbSize = '640';
            break;
        case 'thumbnail_medium':
            vimeoErrorThumbSize = '200x150';
            break;
        case 'thumbnail_small':
            vimeoErrorThumbSize = '100x75';
    }

    utils.addClass(_this.core.outer, 'lg-has-thumb');

    _this.core.outer.querySelector('.lg').insertAdjacentHTML('beforeend', html);

    _this.thumbOuter = _this.core.outer.querySelector('.lg-thumb-outer');
    _this.thumbOuterWidth = _this.thumbOuter.offsetWidth;

    if (_this.core.s.animateThumb) {
        _this.core.outer.querySelector('.lg-thumb').style.width = _this.thumbTotalWidth + 'px';
        _this.core.outer.querySelector('.lg-thumb').style.position = 'relative';
    }

    if (this.core.s.animateThumb) {
        _this.thumbOuter.style.height = _this.core.s.thumbContHeight + 'px';
    }

    function getThumb(src, thumb, index) {
        var isVideo = _this.core.isVideo(src, index) || {};
        var thumbImg;
        var vimeoId = '';

        if (isVideo.youtube || isVideo.vimeo || isVideo.dailymotion) {
            if (isVideo.youtube) {
                if (_this.core.s.loadYoutubeThumbnail) {
                    thumbImg = '//img.youtube.com/vi/' + isVideo.youtube[1] + '/' + _this.core.s.youtubeThumbSize + '.jpg';
                } else {
                    thumbImg = thumb;
                }
            } else if (isVideo.vimeo) {
                if (_this.core.s.loadVimeoThumbnail) {
                    thumbImg = '//i.vimeocdn.com/video/error_' + vimeoErrorThumbSize + '.jpg';
                    vimeoId = isVideo.vimeo[1];
                } else {
                    thumbImg = thumb;
                }
            } else if (isVideo.dailymotion) {
                if (_this.core.s.loadDailymotionThumbnail) {
                    thumbImg = '//www.dailymotion.com/thumbnail/video/' + isVideo.dailymotion[1];
                } else {
                    thumbImg = thumb;
                }
            }
        } else {
            thumbImg = thumb;
        }

        thumbList += '<div data-vimeo-id="' + vimeoId + '" class="lg-thumb-item" style="width:' + _this.core.s.thumbWidth + 'px; margin-right: ' + _this.core.s.thumbMargin + 'px"><img src="' + thumbImg + '" /></div>';
        vimeoId = '';
    }

    if (_this.core.s.dynamic) {
        for (var j = 0; j < _this.core.s.dynamicEl.length; j++) {
            getThumb(_this.core.s.dynamicEl[j].src, _this.core.s.dynamicEl[j].thumb, j);
        }
    } else {
        for (var i = 0; i < _this.core.items.length; i++) {
            if (!_this.core.s.exThumbImage) {
                getThumb(_this.core.items[i].getAttribute('href') || _this.core.items[i].getAttribute('data-src'), _this.core.items[i].querySelector('img').getAttribute('src'), i);
            } else {
                getThumb(_this.core.items[i].getAttribute('href') || _this.core.items[i].getAttribute('data-src'), _this.core.items[i].getAttribute(_this.core.s.exThumbImage), i);
            }
        }
    }

    _this.core.outer.querySelector('.lg-thumb').innerHTML = thumbList;

    $thumb = _this.core.outer.querySelectorAll('.lg-thumb-item');

    for (var n = 0; n < $thumb.length; n++) {

        /*jshint loopfunc: true */
        (function(index) {
            var $this = $thumb[index];
            var vimeoVideoId = $this.getAttribute('data-vimeo-id');
            if (vimeoVideoId) {

                window['lgJsonP' + _this.el.getAttribute('lg-uid') + '' + n] = function(content) {
                    $this.querySelector('img').setAttribute('src', content[0][_this.core.s.vimeoThumbSize]);
                };

                var script = document.createElement('script');
                script.className = 'lg-script';
                script.src = '//www.vimeo.com/api/v2/video/' + vimeoVideoId + '.json?callback=lgJsonP' + _this.el.getAttribute('lg-uid') + '' + n;
                document.body.appendChild(script);
            }
        })(n);
    }

    // manage active class for thumbnail
    utils.addClass($thumb[_this.core.index], 'active');
    utils.on(_this.core.el, 'onBeforeSlide.lgtm', function() {

        for (var j = 0; j < $thumb.length; j++) {
            utils.removeClass($thumb[j], 'active');
        }

        utils.addClass($thumb[_this.core.index], 'active');

    });

    for (var k = 0; k < $thumb.length; k++) {

        /*jshint loopfunc: true */
        (function(index) {

            utils.on($thumb[index], 'click.lg touchend.lg', function() {

                setTimeout(function() {

                    // In IE9 and bellow touch does not support
                    // Go to slide if browser does not support css transitions
                    if ((_this.thumbClickable && !_this.core.lgBusy) || !_this.core.doCss()) {
                        _this.core.index = index;
                        _this.core.slide(_this.core.index, false, true);
                    }
                }, 50);
            });

        })(k);
    }

    utils.on(_this.core.el, 'onBeforeSlide.lgtm', function() {
        _this.animateThumb(_this.core.index);
    });

    utils.on(window, 'resize.lgthumb orientationchange.lgthumb', function() {
        setTimeout(function() {
            _this.animateThumb(_this.core.index);
            _this.thumbOuterWidth = _this.thumbOuter.offsetWidth;
        }, 200);
    });

};

Thumbnail.prototype.setTranslate = function(value) {
    utils.setVendor(this.core.outer.querySelector('.lg-thumb'), 'Transform', 'translate3d(-' + (value) + 'px, 0px, 0px)');
};

Thumbnail.prototype.animateThumb = function(index) {
    var $thumb = this.core.outer.querySelector('.lg-thumb');
    if (this.core.s.animateThumb) {
        var position;
        switch (this.core.s.currentPagerPosition) {
            case 'left':
                position = 0;
                break;
            case 'middle':
                position = (this.thumbOuterWidth / 2) - (this.core.s.thumbWidth / 2);
                break;
            case 'right':
                position = this.thumbOuterWidth - this.core.s.thumbWidth;
        }
        this.left = ((this.core.s.thumbWidth + this.core.s.thumbMargin) * index - 1) - position;
        if (this.left > (this.thumbTotalWidth - this.thumbOuterWidth)) {
            this.left = this.thumbTotalWidth - this.thumbOuterWidth;
        }

        if (this.left < 0) {
            this.left = 0;
        }

        if (this.core.lGalleryOn) {
            if (!utils.hasClass($thumb, 'on')) {
                utils.setVendor(this.core.outer.querySelector('.lg-thumb'), 'TransitionDuration', this.core.s.speed + 'ms');
            }

            if (!this.core.doCss()) {
                $thumb.style.left = -this.left + 'px';
            }
        } else {
            if (!this.core.doCss()) {
                $thumb.style.left = -this.left + 'px';
            }
        }

        this.setTranslate(this.left);

    }
};

// Enable thumbnail dragging and swiping
Thumbnail.prototype.enableThumbDrag = function() {

    var _this = this;
    var startCoords = 0;
    var endCoords = 0;
    var isDraging = false;
    var isMoved = false;
    var tempLeft = 0;

    utils.addClass(_this.thumbOuter, 'lg-grab');

    utils.on(_this.core.outer.querySelector('.lg-thumb'), 'mousedown.lgthumb', function(e) {
        if (_this.thumbTotalWidth > _this.thumbOuterWidth) {
            // execute only on .lg-object
            e.preventDefault();
            startCoords = e.pageX;
            isDraging = true;

            // ** Fix for webkit cursor issue https://code.google.com/p/chromium/issues/detail?id=26723
            _this.core.outer.scrollLeft += 1;
            _this.core.outer.scrollLeft -= 1;

            // *
            _this.thumbClickable = false;
            utils.removeClass(_this.thumbOuter, 'lg-grab');
            utils.addClass(_this.thumbOuter, 'lg-grabbing');
        }
    });

    utils.on(window, 'mousemove.lgthumb', function(e) {
        if (isDraging) {
            tempLeft = _this.left;
            isMoved = true;
            endCoords = e.pageX;

            utils.addClass(_this.thumbOuter, 'lg-dragging');

            tempLeft = tempLeft - (endCoords - startCoords);

            if (tempLeft > (_this.thumbTotalWidth - _this.thumbOuterWidth)) {
                tempLeft = _this.thumbTotalWidth - _this.thumbOuterWidth;
            }

            if (tempLeft < 0) {
                tempLeft = 0;
            }

            // move current slide
            _this.setTranslate(tempLeft);

        }
    });

    utils.on(window, 'mouseup.lgthumb', function() {
        if (isMoved) {
            isMoved = false;
            utils.removeClass(_this.thumbOuter, 'lg-dragging');

            _this.left = tempLeft;

            if (Math.abs(endCoords - startCoords) < _this.core.s.swipeThreshold) {
                _this.thumbClickable = true;
            }

        } else {
            _this.thumbClickable = true;
        }

        if (isDraging) {
            isDraging = false;
            utils.removeClass(_this.thumbOuter, 'lg-grabbing');
            utils.addClass(_this.thumbOuter, 'lg-grab');
        }
    });

};

Thumbnail.prototype.enableThumbSwipe = function() {
    var _this = this;
    var startCoords = 0;
    var endCoords = 0;
    var isMoved = false;
    var tempLeft = 0;

    utils.on(_this.core.outer.querySelector('.lg-thumb'), 'touchstart.lg', function(e) {
        if (_this.thumbTotalWidth > _this.thumbOuterWidth) {
            e.preventDefault();
            startCoords = e.targetTouches[0].pageX;
            _this.thumbClickable = false;
        }
    });

    utils.on(_this.core.outer.querySelector('.lg-thumb'), 'touchmove.lg', function(e) {
        if (_this.thumbTotalWidth > _this.thumbOuterWidth) {
            e.preventDefault();
            endCoords = e.targetTouches[0].pageX;
            isMoved = true;

            utils.addClass(_this.thumbOuter, 'lg-dragging');

            tempLeft = _this.left;

            tempLeft = tempLeft - (endCoords - startCoords);

            if (tempLeft > (_this.thumbTotalWidth - _this.thumbOuterWidth)) {
                tempLeft = _this.thumbTotalWidth - _this.thumbOuterWidth;
            }

            if (tempLeft < 0) {
                tempLeft = 0;
            }

            // move current slide
            _this.setTranslate(tempLeft);

        }
    });

    utils.on(_this.core.outer.querySelector('.lg-thumb'), 'touchend.lg', function() {
        if (_this.thumbTotalWidth > _this.thumbOuterWidth) {

            if (isMoved) {
                isMoved = false;
                utils.removeClass(_this.thumbOuter, 'lg-dragging');
                if (Math.abs(endCoords - startCoords) < _this.core.s.swipeThreshold) {
                    _this.thumbClickable = true;
                }

                _this.left = tempLeft;
            } else {
                _this.thumbClickable = true;
            }
        } else {
            _this.thumbClickable = true;
        }
    });

};

Thumbnail.prototype.toggle = function() {
    var _this = this;
    if (_this.core.s.toggleThumb) {
        utils.addClass(_this.core.outer, 'lg-can-toggle');
        _this.thumbOuter.insertAdjacentHTML('beforeend', '<span class="lg-toggle-thumb lg-icon"></span>');
        utils.on(_this.core.outer.querySelector('.lg-toggle-thumb'), 'click.lg', function() {
            if (utils.hasClass(_this.core.outer, 'lg-thumb-open')) {
                utils.removeClass(_this.core.outer, 'lg-thumb-open');
            } else {
                utils.addClass(_this.core.outer, 'lg-thumb-open');
            }
        });
    }
};

Thumbnail.prototype.thumbkeyPress = function() {
    var _this = this;
    utils.on(window, 'keydown.lgthumb', function(e) {
        if (e.keyCode === 38) {
            e.preventDefault();
            utils.addClass(_this.core.outer, 'lg-thumb-open');
        } else if (e.keyCode === 40) {
            e.preventDefault();
            utils.removeClass(_this.core.outer, 'lg-thumb-open');
        }
    });
};

Thumbnail.prototype.destroy = function(d) {
    if (this.core.s.thumbnail && this.core.items.length > 1) {
        utils.off(window, '.lgthumb');
        if(!d) {
            this.thumbOuter.parentNode.removeChild(this.thumbOuter);
        }
        utils.removeClass(this.core.outer, 'lg-has-thumb');

        var lgScript = document.getElementsByClassName('lg-script');
        while (lgScript[0]) {
            lgScript[0].parentNode.removeChild(lgScript[0]);
        }
    }
};

window.lgModules.thumbnail = Thumbnail;
