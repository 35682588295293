import BaseView from '../../../js/base-view';
import objectToQueryString from '../../../js/utils/objectToQueryString';
import LineClamp from '../../../js/utils/line-clamp';

// config
const DATA_ATTRIBUTE_UID = 'data-loadmore';
const CLASS_IS_LOADING = 'is-loading';
const CLASS_CARD_ARTICLE_SMALL = 'card-article--small';
const CLASS_CARD_ARTICLE_SPONSORED = 'card-article--sponsored';
const SELECTOR_ALL_SMALL_ARTICLE_CARDS = '.articles-list--small > .articles-list__inner > .card-article:not(.card-article--small)';
const SELECTOR_CARD_ARTICLE_TITLE = '.card-article__title';
const LINE_CLAMP_MAX_LINES = '4';

export default class Loadmore extends BaseView {
	init(customDataAttrUID = null) {
		// fetch config from the global scope
		// NOTE: we do this because we need to pass json data from views (for the "query" parameter),
		//       and it's quite tricky and error-prone to pass it through html data-attributes
		//       (mostly because escaping is not consistent between js/php)
		const configId = this.el.getAttribute((customDataAttrUID || DATA_ATTRIBUTE_UID));
		if (! window.supt || ! window.supt.loadmore || ! window.supt.loadmore[ configId ]) {
			console.error('Could not load "loadmore" config');
			return false;
		}
		const config = window.supt.loadmore[ configId ];

		// props
		this.props = {
			target: config.target,
			url: config.url,
			query: config.query,
			maxPage: parseInt(config.maxPage),
			texts: config.texts,
			isFake: config.isFake,
		};

		// state
		this.state = {
			currentPage: parseInt(config.currentPage),
		};

		// dom elements
		this.refs = {
			targetContainer: document.querySelector(this.props.target),
		};

		// bind events
		this.on('click', this.handleClick.bind(this));

		// Allows to chain
		return this;
	}

	// #region Event Handlers

	/**
	 * When user clicks on the loadmore button
	 *
	 * @param {Event} ev original event
	 */
	handleClick(ev) {
		ev.preventDefault();
		this.loadMore();
	}

	// #endregion

	// #region Actions

	/**
	 * Load the next page and inject it
	 */
	loadMore() {
		// toggle UI state to "loading…"
		this.toggleLoadingState(true);

		// fetch (real or fake)
		this.fetchContent()
			.then((result) => this.handleContentLoaded(result))
			.catch((error) => console.error(error));
	}

	/**
	 * When we receive new content
	 *
	 * @param {string} htmlString the html to inject
	 */
	handleContentLoaded(htmlString) {
		// inject html
		this.refs.targetContainer.insertAdjacentHTML('beforeend', htmlString);

		/**
		 * This is required because apparently the fetching does not render an html string with
		 * the proper class and data-attributes for added articles.
		 */
		this.fixSmallArticleCardsStyle();

		// toggle UI state to "finished loading"
		this.toggleLoadingState(false);

		// update current page index
		this.state.currentPage += 1;

		// loaded everything?
		if (this.state.currentPage === this.props.maxPage) {
			this.handleLastPageReached();
		}
	}

	/**
	 * Adds .card-article--small class and
	 * makes sure data-line-clamp works
	 * so that small article cards are properly rendered
	 */
	fixSmallArticleCardsStyle() {
		const smallArticleCards = document.querySelectorAll(SELECTOR_ALL_SMALL_ARTICLE_CARDS);
		smallArticleCards.forEach((card) => {
			card.classList.add(CLASS_CARD_ARTICLE_SMALL);
			if (! card.classList.contains(CLASS_CARD_ARTICLE_SPONSORED)) {
				card.querySelector(SELECTOR_CARD_ARTICLE_TITLE).setAttribute('data-line-clamp', LINE_CLAMP_MAX_LINES);
			}
		});
		LineClamp();
	}

	/**
	 * When we reach the last page
	 */
	handleLastPageReached() {
		// disable the button and unbind everything
		this.el.textContent = this.props.texts.maxReached;
		this.el.setAttribute('disabled', 'disabled');
		// hide button
		this.el.parentElement.style.display = 'none';
		this.destroy();
	}

	/**
	 * Set/unset loading state on the UI
	 *
	 * @param {bool} isLoading is the content loading?
	 */
	toggleLoadingState(isLoading) {
		if (isLoading) {
			this.el.classList.add(CLASS_IS_LOADING);
			this.el.innerText = this.props.texts.loading;
			this.refs.targetContainer.classList.add(CLASS_IS_LOADING);
		}
		else {
			this.el.classList.remove(CLASS_IS_LOADING);
			this.el.innerText = this.props.texts.initial;
			this.refs.targetContainer.classList.remove(CLASS_IS_LOADING);
		}
	}

	// #endregion

	// #region Fetching

	/**
	 * Fetch the next content
	 */
	fetchContent() {
		return (
			this.props.isFake ?
				this.fakeFetch() :
				this.realFetch()
		);
	}

	/**
	 * Fetch the content (fo' realz)
	 *
	 * @return {Promise} a promise resolving with html as a string or rejecting with an error object
	 */
	realFetch() {
		const data = {
			action: 'loadmore',
			query: this.props.query,
			page: this.state.currentPage, // retrieve next page
		};

		return window.fetch(this.props.url, {
			method: 'POST',
			credentials: 'same-origin',
			cache: 'no-cache',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded;',
			},
			body: objectToQueryString(data),
		})
			// check success
			.then((response) => {
				if (response.status >= 200 && response.status < 300) {
					return response;
				}
				const error = new Error(response.statusText);
				error.response = response;
				throw error;
			})
			.then((response) => response.text());
	}

	/**
	 * Fetch the content (fo' fakezzz)
	 *
	 * @return {Promise} a promise resolving with html as a string or rejecting with an error object
	 */
	fakeFetch() {
		const fakeHtml = `<p>content of page #${ this.state.currentPage + 1 }</p>`;
		return new Promise((resolve) => {
			setTimeout(() => resolve(fakeHtml), 2000);
		});
	}

	// #endregion

	/**
	 * Removes all events handlers and resets the component state
	 */
	destroy() {
		// console.log(performance.now(), 'destroying load more view…');
		super.destroy();
	}
}
