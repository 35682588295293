
import BaseInput from '../_0-input/input';

const SELECTOR_FIELD = '.input-checkbox__field';

/**
 * @class InputRadio
 */
export default class InputRadio extends BaseInput {
	init() {
		super.init(SELECTOR_FIELD);
	}
}
