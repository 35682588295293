import * as Sentry from '@sentry/browser';
// import { Integrations } from '@sentry/tracing';

/**
 * Load assets
 */
import './icons';
import './img';

/**
 * Polyfills
 */
// import 'utils/nodeList-forEach-polyfill';
// import 'custom-event-polyfill';
// import 'poor-mans-proxy';
// import 'es6-promise/auto';
// import 'whatwg-fetch';
// import objectFitImages from 'object-fit-images';
// import 'utils/ie-poly';
// import 'utils/closest';
// import 'utils/touch-test';
// import 'utils/keyboard-test';

/**
 * Services etc
 */
import './js/services/viewport';
import './js/services/account-data';

/**
 * Components loader
 */
import './components/_loader';
import './js/app';

/**
 * Styles
 */
import './main.scss';

// object-fit polyfill for IE
// objectFitImages();

(() => {
	Sentry.init({
		dsn: 'https://ba5d00dd091e46bdabf14f9dafe35870@o233938.ingest.sentry.io/5711564',
		// integrations: [new Integrations.BrowserTracing()],

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		// tracesSampleRate: 1.0,
	});
})();
