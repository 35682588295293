import BaseView from '../../../js/base-view';

export default class Button extends BaseView {
	bind() {
		console.info(`JS for 'Button' component successfully loaded.`);
	}

	// destroy() {
	// }
}
