// TODO: update from new BaseView

import BaseView from '../../../../js/base-view';
import AccountData from '../../../../js/services/account-data';

const ATTR_ERROR = 'data-error';
const ATTR_COND_NAME = 'data-cond-name';
const ATTR_COND_VALUE = 'data-cond-value';
const ATTR_AUTOFILL = 'data-autofill';

const CLASS_DISABLED = 'is-disabled';

export default class BaseInput extends BaseView {
	/**
	 *
	 * @param {string} selectorField input dom element selector
	 * @param {string|boolean} handleMultilineLabelResize selector for the label dom element (optional, pass false to avoid resizing input based on label height)
	 */
	init(selectorField = 'input', handleMultilineLabelResize = false) {
		this.refs = {
			field: this.el.querySelector(selectorField),
			label: this.el.querySelector('label'),
		};

		this.props = {
			isConditinal: false,
			name: this.refs.field.name,
		};

		this.state = {
			isErroneous: false,
		};

		this.setIsConditional();
		this.checkAutofill()
		this.bindEvents();

		if (handleMultilineLabelResize && this.refs.label) {
			this.on('resize', this.updateMultilineLabelResize.bind(this));
			this.updateMultilineLabelResize();
		}
	}

	setIsConditional() {
		this.props.isConditinal = (
			this.el.hasAttribute(ATTR_COND_NAME) &&
				this.el.hasAttribute(ATTR_COND_VALUE)
		);

		if (this.props.isConditinal) {
			this.props.condName = this.el.getAttribute(ATTR_COND_NAME);
			this.props.condValues = this.el.getAttribute(ATTR_COND_VALUE).split(',');
			this.toggleDisabled(true);
		}
	}

	checkAutofill() {
		if (this.el.hasAttribute(ATTR_AUTOFILL)) {
			const accountData = new AccountData()
			const value = accountData.get(this.el.getAttribute(ATTR_AUTOFILL))
			if (value) this.setValue(value);
		}
	}

	/**
	 * Set the field value with the given value.
	 * The purpose of this function si to be overrided
	 * by classes that extend this one,
	 * for specific case when the way to set the value differ.
	 *
	 * @param {*} value
	 */
	setValue(value) {
		this.refs.field.value = value
	}

	bindEvents() {}

	updateMultilineLabelResize() {
		if (! this.refs.label) {
			return;
		}

		// vars
		const labelHeight = this.refs.label.getBoundingClientRect().height;
		const labelStyles = window.getComputedStyle(this.refs.label);
		const labelLineHeight = parseInt(labelStyles.getPropertyValue('line-height'));
		const labelPaddingTop = parseInt(labelStyles.getPropertyValue('padding-top'));
		const labelPaddingBottom = parseInt(labelStyles.getPropertyValue('padding-bottom'));
		const labelPaddings = labelPaddingTop + labelPaddingBottom;

		// label has multiple lines
		// …we grow the padding of the input to fit it inside
		if (labelHeight > (labelLineHeight + labelPaddings)) {
			this.refs.field.style.paddingTop = `${ labelHeight }px`;
		}
		// label is on one line, style as usual
		else {
			this.refs.field.style.paddingTop = null;
		}
	}

	getName() {
		return this.props.name;
	}

	checkCondition(name, value) {
		// Bail early
		if (! this.props.isConditinal) {
			return false;
		}
		if (name === this.getName()) {
			return false;
		}
		if (this.props.condName !== name) {
			return false;
		}

		// Check the condition
		let newIsDisabled = true;
		if (Array.isArray(value)) {
			newIsDisabled = ! this.props.condValues.filter((v) => value.indexOf(v) > -1).length;
		}
		else {
			newIsDisabled = ! (this.props.condValues.indexOf(value) > -1);
		}

		// Bail if condition didn't change
		if (this.state.isDisabled === newIsDisabled) {
			return false;
		}

		// Condition changed, update DOM + state
		this.toggleDisabled(newIsDisabled);
		return true;
	}

	setError(errorMessage = '') {
		this.state.isErroneous = true;
		this.el.setAttribute(ATTR_ERROR, errorMessage);
	}

	resetError() {
		this.state.isErroneous = false;
		this.el.removeAttribute(ATTR_ERROR);
	}

	toggleDisabled(isDisabled) {
		this.state.isDisabled = isDisabled;
		this.el.classList[(isDisabled ? 'add' : 'remove')](CLASS_DISABLED);

		if (isDisabled) {
			this.disable();
		}
		else {
			this.enable();
		}
	}

	checkValidity() {
		if (this.refs.field.checkValidity()) {
			this.resetError();
			return true;
		}

		this.setError(this.refs.field.validationMessage);
		return false;
	}

	focus() {
		this.refs.field.focus();
	}

	disable() {
		this.refs.field.setAttribute('disabled', true);
	}

	enable() {
		// Do not enable field if field is condition & should still be hidden
		if (this.props.isConditinal && this.state.isDisabled) {
			return;
		}
		this.refs.field.removeAttribute('disabled');
	}

	reset() {
		this.resetError();

		if (this.props.isConditinal) {
			this.toggleDisabled(true);
		}
	}
}
