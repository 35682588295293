/**
 * Add 0 if number minor than 10
 *
 * @param {Number} num
 * @return {String}
 */

function pad(n) {
	return (n < 10) ? (`0${n}`) : n;
}

export default pad;
