// --------------------------------
// #region Imports
// --------------------------------
import {
	disableBodyScroll,
	enableBodyScroll,
	clearAllBodyScrollLocks,
} from 'body-scroll-lock';
import Swup from 'swup';
// import DebugPlugin from '@swup/debug-plugin';
import JsPlugin from '@swup/js-plugin';
import HeadPlugin from '@swup/head-plugin';
// import ScrollPlugin from '@swup/scroll-plugin';
import ScriptsPlugin from '@swup/scripts-plugin';
import BodyClassPlugin from '@swup/body-class-plugin';
import smoothscroll from 'smoothscroll-polyfill';
import PreloadPlugin from '@swup/preload-plugin';
import SwupGtmPlugin from '@swup/gtm-plugin';
import {
	TimelineLite,
	TweenLite,
	CSSPlugin,
	Power0,
	Power2,
	ExpoScaleEase,
} from 'gsap/all';
import { CustomEase } from './utils/gsap/CustomEase';
import { componentsLoader, componentsDestroy } from '../components/_loader';
// --------------------------------
// #endregion
// --------------------------------

// --------------------------------
// #region Variables
// --------------------------------
// ensure gsap plugins don't get dropped by tree-shaking
// eslint-disable-next-line no-unused-vars
const plugins = [CSSPlugin, CustomEase];

// navigation
const SELECTOR_BACKGROUND = '.nav__background';
const SELECTOR_NB_TOP = '.navbar__top';
const SELECTOR_NB_PROGRAM = '.navbar__program';
const SELECTOR_NB_MENU = '.navbar__menu';
const SELECTOR_NB_MENU_ITEM = '.navbar__menu__item';
const SELECTOR_SUBMENU_ACTIVE = '.submenu--active';
const SELECTOR_DROPDOWN = '.dropdown';
const SELECTOR_DROPDOWN_OVERLAY = '.dropdown__menu__overlay';
const SELECTOR_DROPDOWN_CARD = '.dropdown__cards .card-cta';
const SELECTOR_NB_TOGGLE = '.navbar__toggle';
const SELECTOR_NB_TOGGLE_MENU = '.navbar__icon--menu';
const SELECTOR_NB_TOGGLE_BACK = '.navbar__icon--back';
const SELECTOR_NB_TOGGLE_CLOSE = '.navbar__icon--close';
// page header
const SELECTOR_CONTENT = '.header__content';
const SELECTOR_MASK = '.header__mask';
const SELECTOR_CLIP = '.header__clip';
const SELECTOR_FRAME_CIRCLE = '.header__frame__circle';
const SELECTOR_FRAME_BUTTON = '.header__frame__button';
const SELECTOR_MEDIA_WRAPPER = '.header__media-wrapper';
const SELECTOR_MEDIA_INNER = '.header__media-inner';
const SELECTOR_WRAPPER_TITLE = '.header__title-wrapper';
const SELECTOR_VIDEO = '.header__video';
const SELECTOR_IMAGE = '.header__image';
const SELECTOR_HHM_CLONED = '.header__media-wrapper--cloned';
// page
const SELECTOR_SWUP = '.swup';
const SELECTOR_PAGE_MAIN = '.page__main';
const SELECTOR_PAGE_FOOTER = '.page__footer';
const SELECTOR_COOKIE_LAW = '.cookie-law';
const SELECTOR_POST_MEDIA = '.page-post__media';
const SELECTOR_POST_HEADER = '.page-post__header';
const SELECTOR_POST_TITLE = '.page-post__title';
const SELECTOR_BREADCRUMBS = '.breadcrumbs';
const SELECTOR_LOAD_MEDIA = '.js-load-media';
const SELECTOR_PROGRESS_BAR = '.navbar__main__progress-bar';
const SELECTOR_USER_DASHBOARD_SIDEBAR = '.my-account-nav';
const SELECTOR_USER_DASHBOARD_SIDEBAR_PROFILE = '.ud-nav__profile';

const CLASS_HHM_CLONED = 'header__media-wrapper--cloned';
const CLASS_POST_TRANSITION = 'to-post-transition';

const tlOut = new TimelineLite();
const tlIn = new TimelineLite();
const tlInitial = new TimelineLite();
const loadedComponents = componentsLoader();
let navView;
let headerView;
let mediaCloned = null;
let isPopState = false;
let locationHref = '';
const scrollPositions = [];
const defaultAniDuration = 0.65;
const initialAniDelay = 0.07;
const emptyElement = document.createElement('p');
// --------------------------------
// #endregion
// --------------------------------

// --------------------------------
// #region Setup
// --------------------------------
// promise media loaded
const mediaLoaded = (media) =>
	new Promise((resolve) => {
		if (media.complete) {
			resolve(media);
		}
		else {
			media.onload = () => {
				resolve(media);
			};
			// to avoid blocking the page
			setTimeout(() => {
				if (!media.complete) {
					resolve(media);
				}
			}, 3000);
		}
	});

// save nav view
// to be improved
Object.keys(loadedComponents).forEach((key) => {
	if (loadedComponents[key].name === 'Navigation') {
		navView = loadedComponents[key];
	}
});

// check if media loaded on initial load
const loadMedia = [].slice.call(document.querySelectorAll(SELECTOR_LOAD_MEDIA));
Promise.all(loadMedia.map(mediaLoaded)).then(() => {
	const event = new CustomEvent('mediaLoaded');
	window.dispatchEvent(event);
	tlInitial
		.clear()
		.to(
			[
				SELECTOR_PAGE_MAIN,
				SELECTOR_PAGE_FOOTER,
				SELECTOR_USER_DASHBOARD_SIDEBAR,
			],
			defaultAniDuration,
			{
				ease: Power0.easeNone,
				css: {
					opacity: '1',
				},
			},
			initialAniDelay
		)
		.to(
			SELECTOR_COOKIE_LAW,
			0.2,
			{
				ease: Power0.easeNone,
				css: {
					opacity: '1',
				},
			},
			defaultAniDuration / 2
		)
		.set(
			[
				`${SELECTOR_PAGE_MAIN}> article > *:not(${SELECTOR_POST_HEADER})`,
				`${SELECTOR_PAGE_MAIN}> article ${SELECTOR_POST_TITLE}`,
			],
			{
				css: {
					y: 40,
				},
			},
			0
		)
		.to(
			`${SELECTOR_PAGE_MAIN}> article ${SELECTOR_POST_MEDIA}`,
			defaultAniDuration / 2,
			{
				ease: Power2.easeOut,
				css: {
					opacity: 1,
				},
			},
			initialAniDelay
		)
		.to(
			[
				`${SELECTOR_PAGE_MAIN}> article > *:not(${SELECTOR_POST_HEADER})`,
				`${SELECTOR_PAGE_MAIN}> article ${SELECTOR_POST_TITLE}`,
			],
			0.6,
			{
				ease: Power2.easeOut,
				css: {
					opacity: 1,
					y: 0,
					clearProps: 'y',
				},
			},
			defaultAniDuration / 2
		);
});

// estimate scroll width
const documentWidth = document.documentElement.clientWidth;
const windowWidth = window.innerWidth;
const scrollBarWidth = windowWidth - documentWidth;

function disableScroll() {
	disableBodyScroll(emptyElement, {
		reserveScrollBarGap: true,
	});
	if (mediaCloned) {
		mediaCloned.style.marginRight = `${scrollBarWidth}px`;
		mediaCloned.style.width = `calc(100% - ${scrollBarWidth}px)`;
	}
}

function enableScroll() {
	enableBodyScroll(emptyElement);
	if (mediaCloned) {
		mediaCloned.style.marginRight = '';
		mediaCloned.style.width = '100%';
	}
}

// TODO: move this elsewhere + document
// when the url has a hash (i.e. '/yo#my-section')
// -> we want to scroll to the section '#my-section'
function handleScrollIn() {
	if (isPopState) {
		return;
	}
	let topY = 0;
	if (window.location.hash) {
		const targetElement = document.querySelector(window.location.hash);
		if (targetElement) {
			topY = targetElement.offsetTop;
		}
	}
	window.scroll({ top: topY, left: 0, behavior: 'auto' }); // TODO: find a way to have this instant??
}

// --------------------------------
// #endregion
// --------------------------------

// --------------------------------
// #region Animations
// --------------------------------

/**
 * Default Out Animation
 *
 * @param next
 */
function defaultOutAnm(next) {
	tlOut
		.clear()
		// content
		.to(
			[
				SELECTOR_SWUP,
				SELECTOR_PAGE_MAIN,
				SELECTOR_PAGE_FOOTER,
				SELECTOR_HHM_CLONED,
				SELECTOR_USER_DASHBOARD_SIDEBAR,
			],
			defaultAniDuration / 2,
			{
				ease: Power0.easeNone,
				css: {
					opacity: '0',
				},
			},
			0
		)
		.eventCallback('onComplete', next);
}

/**
 * Default In Animation
 *
 * @param next
 */
function defaultInAnm(next) {
	tlIn
		.clear()
		// content
		.set(
			SELECTOR_SWUP,
			{
				css: {
					opacity: '1',
				},
			},
			0
		)
		.set(
			[
				SELECTOR_PAGE_MAIN,
				SELECTOR_PAGE_FOOTER,
				SELECTOR_USER_DASHBOARD_SIDEBAR,
			],
			{
				css: {
					opacity: '0',
				},
			},
			0
		)
		.to(
			[
				SELECTOR_PAGE_MAIN,
				SELECTOR_PAGE_FOOTER,
				SELECTOR_USER_DASHBOARD_SIDEBAR,
			],
			defaultAniDuration,
			{
				ease: Power0.easeNone,
				css: {
					opacity: '1',
				},
			},
			initialAniDelay
		)
		// article
		.set(
			[
				`${SELECTOR_PAGE_MAIN}> article > *:not(${SELECTOR_POST_HEADER})`,
				`${SELECTOR_PAGE_MAIN}> article ${SELECTOR_POST_TITLE}`,
			],
			{
				css: {
					y: 40,
				},
			},
			0
		)
		.to(
			`${SELECTOR_PAGE_MAIN}> article ${SELECTOR_POST_MEDIA}`,
			defaultAniDuration / 2,
			{
				ease: Power2.easeOut,
				css: {
					opacity: 1,
				},
			},
			initialAniDelay
		)
		.to(
			[
				`${SELECTOR_PAGE_MAIN}> article > *:not(${SELECTOR_POST_HEADER})`,
				`${SELECTOR_PAGE_MAIN}> article ${SELECTOR_POST_TITLE}`,
			],
			0.6,
			{
				ease: Power2.easeOut,
				css: {
					opacity: 1,
					y: 0,
					clearProps: 'y',
				},
			},
			defaultAniDuration / 2
		)
		.eventCallback('onComplete', next);
}

/**
 * Leaving page from menu Animation
 *
 * @param next
 */
function menuOutAnm(next) {
	const ddDuration = 0.65;
	const cardDuration = 0.2;
	const toggleDuration = 0.2;

	// mobile
	if (navView.state.isMobileMode) {
		const elementDuration = 0.35;
		const waveDuration = 0.25;

		tlOut
			.clear()
			// toggle
			.set(
				SELECTOR_NB_TOGGLE_MENU,
				{
					css: {
						opacity: 1,
					},
				},
				toggleDuration
			)
			.fromTo(
				`${SELECTOR_NB_TOGGLE_MENU} path`,
				toggleDuration,
				{
					drawSVG: '100% 100%',
				},
				{
					ease: Power2.easeOut,
					drawSVG: '100%',
				},
				toggleDuration
			)
			// menu list
			.to(
				SELECTOR_NB_MENU_ITEM,
				elementDuration,
				{
					ease: Power2.easeOut,
					css: {
						opacity: 0,
					},
				},
				0
			)
			.set(SELECTOR_NB_MENU, {
				css: {
					display: 'none',
				},
			})
			.eventCallback('onComplete', next);

		if (navView.state.isDropdownOpened) {
			tlOut
				// toggle
				.fromTo(
					`${SELECTOR_NB_TOGGLE_BACK} path`,
					toggleDuration,
					{
						drawSVG: '100%',
					},
					{
						ease: Power2.easeOut,
						drawSVG: '0%',
					},
					0
				)
				.set(
					SELECTOR_NB_TOGGLE_BACK,
					{
						css: {
							opacity: 0,
						},
					},
					toggleDuration
				)
				// dropdown
				.to(
					SELECTOR_DROPDOWN_OVERLAY,
					ddDuration,
					{
						ease: CustomEase.create('custom', '0.88, 0, 0.88, 1'),
						css: {
							scaleY: 1,
							transformOrigin: 'center bottom',
						},
					},
					0
				)
				.set(
					SELECTOR_DROPDOWN,
					{
						css: {
							display: 'none',
						},
					},
					ddDuration
				)
				// wave
				.to(
					`${SELECTOR_NB_MENU_ITEM}[aria-expanded="true"] .item__icon`,
					waveDuration,
					{
						ease: Power0.easeNone,
						css: {
							transformOrigin: 'center bottom',
							scaleY: 0,
						},
					},
					0
				)
				// cards
				.staggerTo(
					SELECTOR_DROPDOWN_CARD,
					cardDuration,
					{
						ease: Power0.easeNone,
						css: {
							opacity: 0,
						},
					},
					-0.05,
					0
				)
				// clear search
				.call(() => {
					const event = new CustomEvent('clearArtistSearch');
					window.dispatchEvent(event);
				});
		}
		else {
			tlOut
				// toggle
				.fromTo(
					`${SELECTOR_NB_TOGGLE_CLOSE} path`,
					toggleDuration,
					{
						drawSVG: '100%',
					},
					{
						ease: Power2.easeOut,
						drawSVG: '0%',
					},
					0
				)
				.set(
					SELECTOR_NB_TOGGLE_CLOSE,
					{
						css: {
							opacity: 0,
						},
					},
					toggleDuration
				);
		}

		if (navView.state.isSmallMode) {
			tlOut
				.to(
					SELECTOR_NB_TOP,
					elementDuration,
					{
						ease: Power0.easeNone,
						css: {
							opacity: 0,
						},
					},
					0
				)
				.set(SELECTOR_NB_TOP, {
					css: {
						display: 'none',
						opacity: 1,
					},
				});

			if (navView.state.isProgramAvailable) {
				tlOut
					.to(
						SELECTOR_NB_PROGRAM,
						elementDuration,
						{
							ease: Power0.easeNone,
							css: {
								opacity: 0,
							},
						},
						0
					)
					.set(SELECTOR_NB_PROGRAM, {
						css: {
							display: 'none',
							opacity: 1,
						},
					});
			}
		}
	}
	// desktop
	else {
		const cardDelay = 0.1;
		const waveDuration = 0.3;
		const waveDelay = 0.2;

		tlOut
			.clear()
			// toggle
			.fromTo(
				`${SELECTOR_NB_TOGGLE_CLOSE} path`,
				toggleDuration,
				{
					drawSVG: '100%',
				},
				{
					ease: Power2.easeOut,
					drawSVG: '0%',
				},
				0
			)
			.set(
				SELECTOR_NB_TOGGLE_CLOSE,
				{
					css: {
						opacity: 0,
					},
				},
				toggleDuration
			)
			.set(SELECTOR_NB_TOGGLE, {
				css: {
					display: 'none',
				},
			})
			// dropdown
			.to(
				SELECTOR_DROPDOWN_OVERLAY,
				ddDuration,
				{
					ease: CustomEase.create('custom', '0.88, 0, 0.88, 1'),
					css: {
						scaleY: 1,
						transformOrigin: 'center bottom',
					},
				},
				0
			)
			.set(
				SELECTOR_DROPDOWN,
				{
					css: {
						display: 'none',
					},
				},
				ddDuration
			)
			// cards
			.staggerTo(
				SELECTOR_DROPDOWN_CARD,
				cardDuration,
				{
					ease: Power0.easeNone,
					css: {
						opacity: 0,
					},
				},
				-cardDuration,
				cardDelay
			)
			// wave
			.to(
				`${SELECTOR_NB_MENU_ITEM}[aria-expanded="true"] .item__icon`,
				waveDuration,
				{
					ease: Power0.easeNone,
					css: {
						transformOrigin: 'center bottom',
						scaleY: 0,
					},
				},
				waveDelay
			)
			// submenu
			.set(SELECTOR_SUBMENU_ACTIVE, {
				css: {
					display: 'none',
				},
			})
			// clear search
			.call(() => {
				const event = new CustomEvent('clearArtistSearch');
				window.dispatchEvent(event);
			})
			.eventCallback('onComplete', next);
	}
}

/**
 * Entering page from menu Animation
 *
 * @param next
 */
function menuInAnm(next) {
	const bgDuration = 0.2;
	tlIn
		.clear()
		// content
		.set(
			[
				SELECTOR_PAGE_MAIN,
				SELECTOR_PAGE_FOOTER,
				SELECTOR_USER_DASHBOARD_SIDEBAR,
			],
			{
				css: {
					opacity: '0',
				},
			},
			0
		)
		.to(
			[
				SELECTOR_PAGE_MAIN,
				SELECTOR_PAGE_FOOTER,
				SELECTOR_USER_DASHBOARD_SIDEBAR,
			],
			defaultAniDuration,
			{
				ease: Power0.easeNone,
				css: {
					opacity: '1',
				},
			},
			initialAniDelay
		)
		// article
		.set(
			[
				`${SELECTOR_PAGE_MAIN}> article > *:not(${SELECTOR_POST_HEADER})`,
				`${SELECTOR_PAGE_MAIN}> article ${SELECTOR_POST_TITLE}`,
			],
			{
				css: {
					y: 40,
				},
			},
			0
		)
		.to(
			`${SELECTOR_PAGE_MAIN}> article ${SELECTOR_POST_MEDIA}`,
			defaultAniDuration / 2,
			{
				ease: Power2.easeOut,
				css: {
					opacity: 1,
				},
			},
			initialAniDelay
		)
		.to(
			[
				`${SELECTOR_PAGE_MAIN}> article > *:not(${SELECTOR_POST_HEADER})`,
				`${SELECTOR_PAGE_MAIN}> article ${SELECTOR_POST_TITLE}`,
			],
			0.6,
			{
				ease: Power2.easeOut,
				css: {
					opacity: 1,
					y: 0,
					clearProps: 'y',
				},
			},
			defaultAniDuration / 2
		)
		// background
		.to(
			SELECTOR_BACKGROUND,
			bgDuration,
			{
				ease: Power0.easeNone,
				css: {
					backgroundColor: 'rgba(255,255,255,0)',
				},
			},
			0
		)
		// grow
		.call(
			() => {
				navView.growAnimation(true, true);
			},
			null,
			null,
			0
		)
		// reset values
		.set(navView.el, {
			css: {
				height: 'auto',
				overflowY: 'visible',
			},
		})
		.set(SELECTOR_SUBMENU_ACTIVE, {
			css: {
				display: 'none',
			},
		})
		.set(SELECTOR_BACKGROUND, {
			css: {
				height: '0%',
			},
		})
		.set(SELECTOR_NB_MENU_ITEM, {
			css: {
				opacity: 1,
				y: 0,
			},
		})
		// close menu
		.call(() => {
			if (navView.state.isDropdownOpened) {
				navView.cleanActiveSubmenu();
			}
			navView.toggleMenu(false);
			clearAllBodyScrollLocks();
		})
		.eventCallback('onComplete', next);
}

/**
 * Leaving page from home header
 *
 * @param next
 */
function postOutAnm(next) {
	// save header
	Object.keys(loadedComponents).forEach((key) => {
		if (loadedComponents[key].name === 'HomeHeader') {
			headerView = loadedComponents[key];
		}
	});

	// Media Clone
	// clone media only
	mediaCloned = headerView.refs.mediaWrapper.cloneNode(true);
	mediaCloned.classList.add(CLASS_HHM_CLONED);
	const videoCloned = mediaCloned.querySelector(SELECTOR_VIDEO);
	const imageCloned = mediaCloned.querySelector(SELECTOR_IMAGE);
	const mediaClonedInner = mediaCloned.querySelector(SELECTOR_MEDIA_INNER);

	// estimate values to cloned version
	const headerViewRatio =
		(headerView.state.sMediaInner.height /
			headerView.state.sMediaWrapper.width) *
		100;
	let headerViewWidth =
		(headerView.state.sMediaInner.width * 100) /
		headerView.state.sMediaWrapper.width;
	if (
		headerView.state.sMediaWrapper.width > headerView.state.sMediaInner.width
	) {
		headerViewWidth = '100';
	}

	mediaCloned.style.transform = '';
	mediaCloned.style.width = `100%`;
	mediaCloned.style.height = '';
	mediaCloned.style.top = '0';
	mediaCloned.style.paddingTop = `${headerViewRatio}%`;
	mediaClonedInner.style.transform = '';
	mediaClonedInner.style.width = `${headerViewWidth}%`;
	mediaClonedInner.style.height = '100%';
	mediaClonedInner.style.top = '0';
	mediaClonedInner.style.left = '0';
	mediaClonedInner.style.position = 'absolute';
	if (videoCloned) {
		imageCloned.style.opacity = '0';
	}

	// destroy mouseover/mouseleave event listeners of header
	headerView.off('mouseover');
	headerView.off('mouseleave');

	const maskElement = document.querySelector(SELECTOR_MASK);

	const circleAnimDuration = 0.8;
	const hideAnimDuration = 0.2;
	const easeFunction = CustomEase.create('mjfCircleEase', '0.1, 0, 0.07, 1'); // x1, y1, x2, y2

	// mask scale
	let scaleAmount =
		window.viewport_service.getViewportWidth() - maskElement.offsetWidth / 2;
	let scaleClip =
		(maskElement.offsetWidth + scaleAmount * 2) / maskElement.offsetWidth;
	let contraScaleClip = 1 / scaleClip;

	if (
		window.viewport_service.getViewportWidth() <
		window.viewport_service.getViewportHeight()
	) {
		scaleAmount =
			window.viewport_service.getViewportHeight() - maskElement.offsetWidth / 2;
		scaleClip =
			(maskElement.offsetWidth + scaleAmount * 2) / maskElement.offsetWidth;
		contraScaleClip = 1 / scaleClip;
	}

	// media inner scale
	let scaleMediaAmount = 0;
	let scaleMedia = 1;
	if (
		headerView.state.sMediaWrapper.width > headerView.state.sMediaInner.width
	) {
		scaleMediaAmount =
			(headerView.state.sMediaWrapper.width -
				headerView.state.sMediaInner.width) /
			2;
		scaleMedia =
			(headerView.state.sMediaInner.width + scaleMediaAmount * 2) /
			headerView.state.sMediaInner.width;
	}

	tlOut
		.clear()
		// grow/click/scroll
		.call(
			() => {
				navView.growAnimation(true, true, false);
				// remove cursor pointer from link
				document.querySelector(SELECTOR_FRAME_BUTTON).style.pointerEvents =
					'none';
				disableScroll();
			},
			null,
			null,
			0
		)
		// clip
		.to(
			SELECTOR_WRAPPER_TITLE,
			hideAnimDuration,
			{
				ease: Power0.easeNone,
				css: {
					opacity: 0,
				},
			},
			0
		)
		// hide header content
		.to(
			[
				`${SELECTOR_CONTENT} > *:not(${SELECTOR_MASK})`,
				`${SELECTOR_IMAGE}:not(:only-child)`,
			],
			hideAnimDuration,
			{
				ease: Power0.easeNone,
				css: {
					opacity: 0,
				},
			},
			0
		)
		// frame
		.to(
			`${SELECTOR_FRAME_CIRCLE}:first-child`,
			hideAnimDuration,
			{
				ease: easeFunction,
				css: {
					scale: 1.2,
					opacity: 0,
				},
			},
			0
		)
		.to(
			`${SELECTOR_FRAME_CIRCLE}:nth-child(2)`,
			0.5,
			{
				ease: easeFunction,
				css: {
					scale: 1,
					opacity: 0,
				},
			},
			0
		)
		// hide content
		.to(
			[
				SELECTOR_BREADCRUMBS,
				SELECTOR_PAGE_MAIN,
				SELECTOR_FRAME_BUTTON,
				`${SELECTOR_FRAME_CIRCLE}:not(:nth-child(2)):not(:first-child)`,
			],
			hideAnimDuration,
			{
				ease: Power0.easeNone,
				css: {
					opacity: 0,
				},
			},
			0
		)
		.call(
			() => {
				document.body.appendChild(mediaCloned);
				if (videoCloned) {
					videoCloned.currentTime = headerView.refs.video.currentTime;
				}
			},
			null,
			null,
			circleAnimDuration
		)
		// end
		.eventCallback('onComplete', next);

	if (navView.state.isSmallMode) {
		tlOut
			// clip
			.to(
				SELECTOR_CLIP,
				circleAnimDuration,
				{
					ease: ExpoScaleEase.config(1, scaleClip, easeFunction),
					css: {
						scale: scaleClip,
					},
				},
				0
			)
			.to(
				SELECTOR_MEDIA_INNER,
				0.2,
				{
					ease: Power2.easeOut,
					css: {
						transform: `scale(${scaleMedia}, ${scaleMedia}) translate3d(0, 0, 0)`,
					},
				},
				0
			)
			.to(
				SELECTOR_MEDIA_WRAPPER,
				circleAnimDuration,
				{
					ease: ExpoScaleEase.config(1, contraScaleClip, easeFunction),
					css: {
						transform: `scale(${contraScaleClip}, ${contraScaleClip}) translate3d(${
							headerView.state.tMediaWrapper.x + scaleAmount
						}px, ${headerView.state.tMediaWrapper.y + scaleAmount}px, 0)`,
					},
				},
				0
			)
			.to(
				SELECTOR_WRAPPER_TITLE,
				circleAnimDuration,
				{
					ease: ExpoScaleEase.config(1, contraScaleClip, easeFunction),
					css: {
						transform: `scale(${contraScaleClip}, ${contraScaleClip}) translate3d(${
							headerView.state.tTitle.x + scaleAmount
						}px, ${headerView.state.tTitle.y + scaleAmount}px, 0)`,
					},
				},
				0
			);
	}
	else {
		tlOut
			// clip
			.to(
				SELECTOR_CLIP,
				circleAnimDuration,
				{
					ease: ExpoScaleEase.config(
						headerView.state.scaleClip,
						scaleClip,
						easeFunction
					),
					css: {
						scale: scaleClip,
					},
				},
				0
			)
			.to(
				SELECTOR_MEDIA_INNER,
				hideAnimDuration,
				{
					ease: Power2.easeOut,
					css: {
						transform: `scale(${scaleMedia}, ${scaleMedia}) translate3d(0, 0, 0)`,
					},
				},
				0
			)
			.to(
				SELECTOR_MEDIA_WRAPPER,
				circleAnimDuration,
				{
					ease: ExpoScaleEase.config(
						headerView.state.contraScaleClip,
						contraScaleClip,
						easeFunction
					),
					css: {
						transform: `scale(${contraScaleClip}, ${contraScaleClip}) translate3d(${
							headerView.state.tMediaWrapper.x + scaleAmount
						}px, ${headerView.state.tMediaWrapper.y + scaleAmount}px, 0)`,
					},
				},
				0
			)
			.to(
				SELECTOR_WRAPPER_TITLE,
				circleAnimDuration,
				{
					ease: ExpoScaleEase.config(
						headerView.state.contraScaleClip,
						contraScaleClip,
						easeFunction
					),
					css: {
						transform: `scale(${contraScaleClip}, ${contraScaleClip}) translate3d(${
							headerView.state.tTitle.x + scaleAmount
						}px, ${headerView.state.tTitle.y + scaleAmount}px, 0)`,
					},
				},
				0
			);
	}
}

/**
 * Entering article
 *
 * @param next
 */
function postInAnm(next) {
	const showAnimDuration = 0.6;

	tlIn
		.clear()
		// setup
		.set(
			[SELECTOR_PAGE_MAIN, `${SELECTOR_PAGE_MAIN}> article  *`],
			{
				css: {
					opacity: '1',
				},
			},
			0
		)
		.set(
			SELECTOR_HHM_CLONED,
			{
				css: {
					zIndex: '-1',
				},
			},
			0
		)
		.set(
			[
				`${SELECTOR_PAGE_MAIN}> article > *:not(${SELECTOR_POST_HEADER})`,
				`${SELECTOR_PAGE_MAIN}> article ${SELECTOR_POST_TITLE}`,
			],
			{
				css: {
					opacity: 0,
					y: 40,
				},
			},
			0
		)
		.to(
			[
				`${SELECTOR_PAGE_MAIN}> article > *:not(${SELECTOR_POST_HEADER})`,
				`${SELECTOR_PAGE_MAIN}> article ${SELECTOR_POST_TITLE}`,
			],
			showAnimDuration,
			{
				ease: Power2.easeOut,
				css: {
					opacity: 1,
					y: 0,
					clearProps: 'y',
				},
			},
			0
		)
		.call(
			() => {
				enableScroll();
			},
			null,
			null
		)
		.eventCallback('onComplete', next);
}

/**
 * Insider Mobile Out Animation
 *
 * @param next
 */
function insiderMobileOutAnm(next) {
	tlOut
		.clear()
		// content
		.to(
			[
				SELECTOR_PAGE_MAIN,
				SELECTOR_PAGE_FOOTER,
				SELECTOR_USER_DASHBOARD_SIDEBAR_PROFILE,
			],
			defaultAniDuration / 2,
			{
				ease: Power0.easeNone,
				css: {
					opacity: '0',
				},
			},
			0
		)
		.eventCallback('onComplete', next);
}

/**
 * Insider In Animation
 *
 * @param next
 */
function insiderMobileInAnm(next) {
	tlIn
		.clear()
		// content
		.set(
			SELECTOR_SWUP,
			{
				css: {
					opacity: '1',
				},
			},
			0
		)
		.set(
			[
				SELECTOR_PAGE_MAIN,
				SELECTOR_PAGE_FOOTER,
				SELECTOR_USER_DASHBOARD_SIDEBAR_PROFILE,
			],
			{
				css: {
					opacity: '0',
				},
			},
			0
		)
		.to(
			[
				SELECTOR_PAGE_MAIN,
				SELECTOR_PAGE_FOOTER,
				SELECTOR_USER_DASHBOARD_SIDEBAR_PROFILE,
			],
			defaultAniDuration,
			{
				ease: Power0.easeNone,
				css: {
					opacity: '1',
				},
			},
			initialAniDelay
		)
		.eventCallback('onComplete', next);
}

// --------------------------------
// #endregion
// --------------------------------

// --------------------------------
// #region Plugin Options
// --------------------------------

const optionsJsPlugin = [
	{
		from: '(.*)',
		to: '(.*)',
		out: (next) => {
			// with fade out
			defaultOutAnm(next);
			// without fade out
			// next();
		},
		in: (next) => {
			handleScrollIn();
			const loadMedia = [].slice.call(
				document.querySelectorAll(SELECTOR_LOAD_MEDIA)
			);
			Promise.all(loadMedia.map(mediaLoaded)).then(() => {
				const event = new CustomEvent('mediaLoaded');
				window.dispatchEvent(event);
				defaultInAnm(next);
			});
		},
	},
	{
		from: '/news(.*)',
		to: 'archive-transition',
		out: (next) => {
			const el = document.querySelector('.articles-list');
			if (el) {
				el.classList.add('is-loading');
			}
			next();
		},
		in: (next) => {
			const loadMedia = [].slice.call(
				document.querySelectorAll(SELECTOR_LOAD_MEDIA)
			);
			TweenLite.set(SELECTOR_PAGE_MAIN, { css: { opacity: '1' } });
			const event = new CustomEvent('archiveTransition');
			window.dispatchEvent(event);
			next();
		},
	},
	{
		from: '(.*)',
		to: 'menu-transition',
		out: (next) => {
			menuOutAnm(next);
		},
		in: (next) => {
			handleScrollIn();
			const loadMedia = [].slice.call(
				document.querySelectorAll(SELECTOR_LOAD_MEDIA)
			);
			Promise.all(loadMedia.map(mediaLoaded)).then(() => {
				const event = new CustomEvent('mediaLoaded');
				window.dispatchEvent(event);
				menuInAnm(next);
			});
		},
	},
	{
		from: '(.*)',
		to: 'post-transition',
		out: (next) => {
			window.scroll({ top: 0, left: 0, behavior: 'smooth' }); // scrolls top (replaces swup scroll plugin)
			postOutAnm(next);
		},
		in: (next) => {
			handleScrollIn();
			// mostly for popstate
			const loadMedia = [].slice.call(
				document.querySelectorAll(SELECTOR_LOAD_MEDIA)
			);
			Promise.all(loadMedia.map(mediaLoaded)).then(() => {
				const event = new CustomEvent('mediaLoaded');
				window.dispatchEvent(event);
			});
			postInAnm(next);
		},
	},
	{
		from: '(.*)',
		to: 'insider-mobile-transition',
		out: (next) => {
			// with fade out
			insiderMobileOutAnm(next);
			// without fade out
			// next();
		},
		in: (next) => {
			handleScrollIn();
			const loadMedia = [].slice.call(
				document.querySelectorAll(SELECTOR_LOAD_MEDIA)
			);
			Promise.all(loadMedia.map(mediaLoaded)).then(() => {
				const event = new CustomEvent('mediaLoaded');
				window.dispatchEvent(event);
				insiderMobileInAnm(next);
			});
		},
	},
];

// const optionsScrollPlugin = {
// 	doScrollingRightAway: true,
// 	animateScroll: true,
// 	scrollFriction: 0.03,
// 	scrollAcceleration: 0.4,
// };

// --------------------------------
// #endregion
// --------------------------------

// --------------------------------
// #region Polyfills
// --------------------------------

// Init smoothscroll
smoothscroll.polyfill();

// --------------------------------
// #endregion
// --------------------------------

// --------------------------------
// #region Swup
// --------------------------------

const initNavUpdates = function () {
	// bail early if already defined
	if (window.supt && typeof window.supt.updateNav === 'function') {
		return;
	}

	// define global function
	window.supt = window.supt || {};
	window.supt.updateNav = function (data) {
		// update lang switcher active item
		if (data.langs && Object.keys(data.langs).length) {
			const langItems = [].slice.call(
				document.querySelectorAll('[data-nav-lang]')
			);
			langItems.forEach((el) => {
				const code = el.getAttribute('data-nav-lang');
				if (typeof data.langs[code] !== 'undefined') {
					const langData = data.langs[code];
					const isActive =
						typeof langData.isActive !== 'undefined'
							? langData.isActive
							: false;
					const link =
						typeof langData.link !== 'undefined' ? langData.link : false;
					// update link
					if (link && link.length) {
						const elLink = el.querySelector('a');
						if (elLink) {
							elLink.setAttribute('href', link);
						}
					}
					// toggle active lang
					if (isActive) {
						el.classList.add('is-active');
					}
					else {
						el.classList.remove('is-active');
					}
				}
			});
		}

		// update 1st level navigation active item
		if (data.nav && Object.keys(data.nav).length) {
			const allClasses = [
				'is-current',
				'is-current-parent',
				'is-current-ancestor',
			];
			const navItems = [].slice.call(
				document.querySelectorAll('[data-nav-item]')
			);
			navItems.forEach((el) => {
				const id = el.getAttribute('data-nav-item');
				if (typeof data.nav[id] !== 'undefined') {
					const newClasses = Object.values(data.nav[id]);
					const classesToRemove = allClasses.filter(
						(x) => !newClasses.includes(x)
					);
					el.classList.add(...newClasses);
					el.classList.remove(...classesToRemove);
				}
			});
		}
	};
};

if (document.querySelector('.swup')) {
	const swup = new Swup({
		// get all internal links that satisfy both conditions:
		// - begin with "/" or window.location.origin
		// - don't have the attribute "data-no-swup"
		linkSelector: `a[href^="${window.location.origin}"]:not([data-no-swup]):not([target="_blank"]):not([download]):not([href="#cookie-law-settings"]), a[href^="/"]:not([data-no-swup]):not([target="_blank"]):not([download]):not([href="#cookie-law-settings"])`,
		// skipPopStateHandling() {
		// 	return true;
		// },
		containers: ['.swup'],
		cache: false,
		animateHistoryBrowsing: true,
		plugins: [
			// new DebugPlugin(),
			new HeadPlugin(),
			new SwupGtmPlugin(),
			// Note: we disabled the scroll plugin as we don't always want it
			// (i.e. transition between filters in archive page)
			// + we want to use native scrollTo method for consistency, instead of the Scrl library
			// used in swup, see:
			// https://github.com/swup/scroll-plugin/blob/55cddff08d1347eda051601c2ea0270c145a6cc5/src/index.js#L29
			//
			// new ScrollPlugin(optionsScrollPlugin),
			new JsPlugin(optionsJsPlugin),
			new ScriptsPlugin({ head: false, body: true }), // just run inline scripts in the body
			new BodyClassPlugin(),
			new PreloadPlugin(),
		],
	});

	if (swup.options.animateHistoryBrowsing) {
		window.history.scrollRestoration = 'manual';
	}

	swup.on('clickLink', () => {
		scrollPositions[window.location.href] = window.scrollY;
	});
	swup.on('samePage', () => {
		if (navView.state.isMenuOpened) {
			navView.menuToggleHandler();
		}
	});
	swup.on('popState', () => {
		// save scroll position of page
		scrollPositions[locationHref] = window.scrollY;

		isPopState = true;
	});
	swup.on('transitionStart', () => {
		// turn off smooth scroll
		document.documentElement.style.scrollBehavior = 'auto';

		// close modals if any open (i.e. cookie law consent)
		const dialogs = [].slice.call(
			document.querySelectorAll('[role="dialog"]:not(.cookie-law-banner)')
		);
		dialogs.forEach((el) => {
			el.setAttribute('aria-hidden', 'true');
		});

		// Progress Bar Animation
		TweenLite.fromTo(
			SELECTOR_PROGRESS_BAR,
			10,
			{
				css: {
					scaleX: 0,
					opacity: 1,
					transformOrigin: 'left top',
				},
			},
			{
				ease: Power0.easeNone,
				css: {
					scaleX: 0.8,
				},
			}
		);
	});
	swup.on('willReplaceContent', () => {
		const deleted = componentsDestroy();
		// console.log(deleted);

		// define global function that will be called from inline script
		// we do it this way so that:
		// - we print a minimum of code inline (just the data)
		// - we don't run this on first load, just after swup loads a new page
		initNavUpdates();
	});
	swup.on('contentReplaced', (ev) => {
		const newd = componentsLoader();
		// console.log(newd);

		const postTransition = document.documentElement.classList.contains(
			CLASS_POST_TRANSITION
		);

		if (mediaCloned && !postTransition) {
			mediaCloned.remove();
			mediaCloned = null;
		}

		if (postTransition) {
			// remove post media
			const postMedia = document.querySelector(SELECTOR_POST_MEDIA);
			while (postMedia.firstChild) {
				postMedia.removeChild(postMedia.firstChild);
			}
		}

		// Progress Bar Animation
		// if popstate event animationInStart is skipped
		if (isPopState) {
			const tl = new TimelineLite();
			tl.to(SELECTOR_PROGRESS_BAR, defaultAniDuration, {
				ease: Power2.easeIn,
				css: {
					scaleX: 1,
				},
			}).to(SELECTOR_PROGRESS_BAR, 0.2, {
				css: {
					opacity: 0,
				},
			});
		}
	});
	swup.on('animationInStart', () => {
		// Progress Bar Animation
		const tl = new TimelineLite();
		tl.to(SELECTOR_PROGRESS_BAR, defaultAniDuration, {
			ease: Power2.easeIn,
			css: {
				scaleX: 1,
			},
		}).to(SELECTOR_PROGRESS_BAR, 0.2, {
			css: {
				opacity: 0,
			},
		});

		if (isPopState) {
			window.scroll({
				top: scrollPositions[window.location.href],
				left: 0,
				behavior: 'auto',
			});
		}
	});
	swup.on('transitionEnd', () => {
		// turn on smooth scroll
		document.documentElement.style.scrollBehavior = '';
		isPopState = false;
		locationHref = window.location.href;
	});
}



// --------------------------------
// #endregion
// --------------------------------
