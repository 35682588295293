/* eslint-disable curly */
import BaseView from '../../js/base-view';
import AccountData from '../../js/services/account-data';

// Sections
import SectionContactDetails from './section-contact-details/section-contact-details';
import SectionEarlyBirds from './section-early-birds/section-early-birds';
import SectionPackage from './section-package/section-package';
import SectionPreferences from './section-preferences/section-preferences';
import SectionWelcome from './section-welcome/section-welcome';
import SectionBenefits from './section-benefits/section-benefits';

const CLASS_UD_NAV = 'ud-nav';
const CLASS_ACTIVE_NAV_ITEM = `${CLASS_UD_NAV}__group-item--active`;

const SELECTOR_SECTION = '.my-section';
const SELECTOR_SIDE_NAV_ITEM = '.my-account-nav .ud-nav__group-item';

const sectionComponents = {
	'contact-details': SectionContactDetails,
	'early-birds': SectionEarlyBirds,
	'package': SectionPackage,
	'preferences': SectionPreferences,
	'welcome': SectionWelcome,
	'benefits': SectionBenefits,
}

export default class UserDashboard extends BaseView {
	init() {
		this.accountData = AccountData();

		this.refs = {
			navItems: [...this.el.querySelectorAll(SELECTOR_SIDE_NAV_ITEM)],
			activeNavItem: null,

			sections: [...this.el.querySelectorAll(SELECTOR_SECTION)],
			sectionsView: [],

			logoutLinks: [...this.el.querySelectorAll('a[href*="action=logout"]')],
		}

		this.observer = null;

		this.loadSections();
		this.updateLogoutUrls();
		this.bindEvents();
	}

	loadSections() {
		this.refs.sections.forEach(el => {
			const View = sectionComponents[el.dataset.section] ?? null;
			if (View) {
				try {
					const v = new View(el, `UserDashboard-Section-${el.dataset.section}`);
					v.init();
					this.refs.sectionsView.push(v);
				}
				catch (error) {
					console.error('Could not init section view:', el.dataset.section, '⬇️');
					console.error(error);
				}
			}
		});
	}

	updateLogoutUrls() {
		const nonce = this.accountData.get('logout.nonce');
		if (nonce === null || nonce === '') return;

		this.refs.logoutLinks.forEach((el) => {
			// eslint-disable-next-line no-param-reassign
			el.href = el.href.replace(/_wpnonce=[^&]+/, `_wpnonce=${nonce}`)
		})
	}

	getIntersectionObserverOptions() {
		const options = {
			root: null,
			threshold: 0,
		};

		const vpWidth = this.viewport.getCurrentWidth();
		const vpHeight = this.viewport.getCurrentHeight();

		// Those are magic number which I set by trial & error in the browser
		if      (vpWidth >= 1440) options.rootMargin = `-350px 0px -${vpHeight - 500}px 0px`;
		else if (vpWidth >= 1234) options.rootMargin = `-300px 0px -${vpHeight - 450}px 0px`;
		else                      options.rootMargin = `-200px 0px -${vpHeight - 350}px 0px`;

		return options;
	}

	bindEvents() {
		this.on('resize', this.onResize.bind(this));
		if (this.viewport.getCurrentWidth() >= 1200) this.bindObserver();
	}

	bindObserver() {
		this.destroyObserver();
		this.observer = new IntersectionObserver(this.onIntersect.bind(this), this.getIntersectionObserverOptions());
		this.refs.sections.forEach((s) => this.observer.observe(s))
	}

	onResize({ previous, current }) {
		if (current.width >= 1440 && previous.width < 1440
		  || current.width >= 1234 && current.width < 1440 && (previous.width >= 1440 || previous.width < 1234)
		  || current.width >= 1200 && current.width < 1234 && (previous.width >= 1234 || previous.width < 1200)
		) this.bindObserver();
		else if (current.width < 1200) this.destroyObserver();
	}

	onIntersect(entries) {
		entries.forEach(entry => {
			if (!entry.isIntersecting) return
			const navItem = this.refs.navItems.find((item) => item.dataset.rel === entry.target.id);
			if (navItem) this.toggleActiveNavItem(navItem);
		})
	}

	toggleActiveNavItem(newActiveItem = null) {
		if (this.refs.activeNavItem === newActiveItem) return
		if (this.refs.activeNavItem) this.refs.activeNavItem.classList.remove(CLASS_ACTIVE_NAV_ITEM);
		if (newActiveItem) {
			newActiveItem.classList.add(CLASS_ACTIVE_NAV_ITEM);
			this.refs.activeNavItem = newActiveItem;
		}
	}

	destroyObserver() {
		if (!this.observer) return
		this.observer.disconnect();
		this.observer = null;
		this.toggleActiveNavItem();
	}

	beforeDestroy() {
		this.refs.sectionsView.forEach((v) => v.destroy())
		this.destroyObserver();
	}
}
