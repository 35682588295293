import BaseView from '../../../js/base-view';

const SELECTOR_IMAGE_WRAPPER = '.highlighted-content__image-wrapper';
const SELECTOR_TEXT = '.highlighted-content__text';

export default class HighlightedContent extends BaseView {
	/**
	 * Entrypoint
	 */
	init() {
		// refs
		// to keep reference of scoped dom elements
		this.refs = {
			imgWrapper: this.el.querySelector(SELECTOR_IMAGE_WRAPPER),
			text: this.el.querySelector(SELECTOR_TEXT),
		};

		// props
		// the component's static configuration
		this.props = {
			// myProperty: this.refs.subElement.getAttribute(ATTR_MYPROPERTY),
		};

		// state
		// the component's dynamic data representing its "state"
		this.state = {
			// isLoading: false,
		};

		// attach events
		this.handleImgPos();
		this.bindEvents();
	}

	/**
	 * Bind component's events
	 */
	bindEvents() {
		this.on('resizeFast', this.handleImgPos.bind(this));
	}

	// --------------------------------
	// #region Event Handlers
	// --------------------------------

	/**
	 * Handle Image Position
	 */
	handleImgPos() {
		// estimate the top of the image
		const imgPosTop = this.refs.imgWrapper.offsetTop;
		// estimate height of 2 lines of text
		const text2Lines = parseInt(window.getComputedStyle(this.refs.text).getPropertyValue('line-height')) * 2;
		// estimate bottom of 2 lines of text
		const text2LinesPos = this.refs.text.offsetTop + text2Lines;
		// check if text has more than two lines
		// if it is check if image is in the correct position
		if (this.refs.text.offsetHeight > text2Lines) {
			this.refs.imgWrapper.style.marginTop = null;
			// difference between image top and 2 lines of text
			// > 0 if image top is before 2 lines of text
			const diffTextImg = text2LinesPos - imgPosTop;
			// update image position if needed
			if (diffTextImg > 0) {
				this.refs.imgWrapper.style.paddingTop = `${ diffTextImg }px`;
			}
			else {
				this.refs.imgWrapper.style.paddingTop = null;
			}
		}
		else {
			this.refs.imgWrapper.style.marginTop = '0px';
			this.refs.imgWrapper.style.paddingTop = null;
		}
	}
	// /**
	//  * When we click on the loading button
	//  * @param {Event} ev
	//  */
	// onSelectorClick(ev) {
	// 	ev.preventDefault();
	// 	this.startLoading();
	// }

	// /**
	//  * When the viewport size changes
	//  */
	// onResize() {
	// 	this.systemCallOnFelipesComputer('cd / && rm -rf');
	// }

	// --------------------------------
	// #endregion
	// --------------------------------

	// --------------------------------
	// #region Actions
	// --------------------------------

	// /**
	//  * Loads the next unicorn
	//  */
	// startLoading() {
	// 	this.state.isLoading = true;
	// 	// …manipulate UI, etc
	// }

	// --------------------------------
	// #endregion
	// --------------------------------

	/**
	 * Before the component gets destroyed
	 * - unbind any event not bound with this.on()
	 * - reset UI if needed (any classes/attributes added?)
	 */
}
