import { EventEmitter } from 'events';
import { mergeWith } from 'lodash-es';
import { getCookie, setCookie } from '../utils/cookie'

class Data extends EventEmitter {
	constructor() {
		super();
		this.data = {};
		if (getCookie('mjf_remove_ad', false)) {
			this.removeData();
		}
		else {
			this.retrieveData()
		}
	}

	removeData() {
		setCookie('mjf_ad', '', -365);
		setCookie('mjf_remove_ad', '', -365);
		localStorage.removeItem('mjfAd');
	}

	retrieveData() {
		const cookie = getCookie('mjf_ad');

		let rawData = null;
		if (cookie !== undefined) {
			rawData = window.decodeURIComponent(cookie);
			setCookie('mjf_ad', '', -365); // delete the cookie

			this.saveToStorage(rawData)
		}
		else {
			rawData = localStorage.getItem('mjfAd');
			if (rawData === null) return;
		}

		this.data = JSON.parse(rawData);
	}

	saveToStorage(rawData) {
		const data = (typeof rawData === 'object' ? JSON.stringify(rawData) : rawData);

		// Save to session storage
		localStorage.setItem('mjfAd', data);
	}

	get(field) {
		let value = null;
		try {
			value = field
				.split('.')
				.reduce(
					(data, key) => {
						return data[key] ?? {}
					},
					this.data
				);
		}
		catch (error) {
			console.error(`Unable to retrieve value of '${field}'`);
		}
		return typeof value === 'object' && Object.keys(value).length === 0 ? null : value
	}

	getData(fields) {
		const data = {};
		for (const key in this.data) {
			if (Object.hasOwnProperty.call(this.data, key)) {

				if (fields && fields.includes(key)) {
					data[key] = this.data[key];
				}
			}
		}

		return data;
	}

	set(key, value) {
		this.data[key] = value;
		this.saveToStorage(this.data);

		this.emit('change');
	}

	setData(newData) {
		// eslint-disable-next-line func-names, consistent-return
		mergeWith(this.data, newData, function(objValue, srcValue) {
			if (Array.isArray(objValue)) return srcValue;
		});
		this.saveToStorage(this.data);

		this.emit('change');
	}
}

export default function AccountData() {

	if (!window.mjfAD) {
		window.mjfAD = new Data();
	}

	return window.mjfAD;
}


// Make sur to instanciate on first run
AccountData();
