import Header from '../../../js/header';

const CLASS_BIG_TOP_MARGIN = 'page-header--big-top-margin';
const THRESHOLD_BIG_MARGIN = 3;

export default class PageHeader extends Header {
	/**
	 * Entrypoint
	 */
	init() {
		this.checkTitleNbLines.bind(this);
		this.on('resize', this.checkTitleNbLines.bind(this));
	}

	/**
	 * Bind component's events
	 */
	// bindEvents() {
	// }

	checkTitleNbLines() {
		const titleHeight = this.refs.titleDom.offsetHeight;
		const titleStyles = window.getComputedStyle(this.refs.titleDom);

		const nbLines = parseInt(titleHeight) / parseInt(titleStyles.lineHeight);

		this.el.classList[ nbLines < THRESHOLD_BIG_MARGIN ? 'add' : 'remove' ](CLASS_BIG_TOP_MARGIN);
	}

	// --------------------------------
	// #region Event Handlers
	// --------------------------------

	// --------------------------------
	// #endregion
	// --------------------------------

	// --------------------------------
	// #region Actions
	// --------------------------------

	// /**
	//  * Loads the next unicorn
	//  */
	// startLoading() {
	// 	this.state.isLoading = true;
	// 	// …manipulate UI, etc
	// }

	// --------------------------------
	// #endregion
	// --------------------------------

	/**
	 * Before the component gets destroyed
	 * - unbind any event not bound with this.on()
	 * - reset UI if needed (any classes/attributes added?)
	 */
	// beforeDestroy() {
	// 	// this.resetUI();
	// }
}
