/* eslint-disable curly */
import MySection from '../my-section/my-section';

export default class SectionContactDetails extends MySection {
	init() {
		this.refs = {
			nonce: this.el.querySelector('[name="user_details_nonce"]'),
			gender: this.el.querySelector('#gender'),
			firstname: this.el.querySelector('#firstname'),
			lastname: this.el.querySelector('#lastname'),
			email: this.el.querySelector('#email'),
			phonenumber: this.el.querySelector('#phonenumber'),
			birthdate: this.el.querySelector('#birthdate'),
			address: {
				street: this.el.querySelector('#address_street'),
				number: this.el.querySelector('#address_number'),
				postcode: this.el.querySelector('#address_postcode'),
				city: this.el.querySelector('#address_city'),
				country: this.el.querySelector('#address_country'),
			}
		};

		this.setData();
		this.bindFormEvents();
	}

	setData() {
		const data = this.accountData.get('contact');
		if (this.refs.nonce)       this.refs.nonce.value       = data.nonce;
		if (this.refs.gender)      this.refs.gender.value      = data?.gender ?? '';
		if (this.refs.firstname)   this.refs.firstname.value   = data?.first_name ?? '';
		if (this.refs.lastname)     this.refs.lastname.value     = data?.last_name ?? '';
		if (this.refs.email)       this.refs.email.value       = data?.email ?? '';
		if (this.refs.phonenumber) this.refs.phonenumber.value = data?.phone_number ?? '';
		if (this.refs.birthdate)   this.refs.birthdate.value   = data?.birthdate ?? '';

		if (this.refs.address.street)   this.refs.address.street.value   = data.address?.street ?? '';
		if (this.refs.address.number)   this.refs.address.number.value   = data.address?.number ?? '';
		if (this.refs.address.postcode) this.refs.address.postcode.value = data.address?.postcode ?? '';
		if (this.refs.address.city)     this.refs.address.city.value     = data.address?.city ?? '';
		if (this.refs.address.country)  this.refs.address.country.value  = data.address?.country ?? '';
	}

	bindFormEvents() {
		this.on('form:success', 'form', this.onFormSuccess.bind(this))
		this.on('form:reset','form', this.onFormReset.bind(this))
	}

	onFormSuccess({ detail: data }) {
		this.accountData.setData(data);
		this.setData();
	}

	onFormReset() {
		this.setData();
	}
}
