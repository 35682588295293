import { isEmpty } from 'lodash-es';

import BaseInput from '../_0-input/input';

const CLASS_FILLED = 'is-filled';
const CLASS_FOCUS = 'is-focus';

const SELECTOR_FIELD = '.input-text__field';

/**
 * @class InputText
 */
export default class InputText extends BaseInput {
	init() {
		super.init(SELECTOR_FIELD, true);
		this.toggleFilled();
	}

	bindEvents() {
		this.on('keyup', SELECTOR_FIELD, this.toggleFilled.bind(this));
		this.on('change', SELECTOR_FIELD, this.toggleFilled.bind(this));
		this.on('focus', SELECTOR_FIELD, this.toggleFocus.bind(this));
		this.on('blur', SELECTOR_FIELD, this.onBlur.bind(this));
	}

	onFocus() {
		this.toggleFocus(true);
	}

	onBlur() {
		this.toggleFocus(false);
		this.checkValidity();
	}

	/**
	 * Set the state of is_filled and it's class to the element
	 */
	toggleFilled() {
		this.state.isFilled = ! isEmpty(this.refs.field.value);
		this.el.classList[ this.state.isFilled ? 'add' : 'remove' ](CLASS_FILLED);
	}

	toggleFocus(isFocus) {
		this.state.isFocus = isFocus;
		this.el.classList[ this.state.isFocus ? 'add' : 'remove' ](CLASS_FOCUS);
	}

	reset() {
		super.reset();

		this.state.isFilled = false;
		this.state.isFocus = false;
		this.el.classList.remove(CLASS_FILLED);
		this.el.classList.remove(CLASS_FOCUS);
	}

	getValue() {
		return this.refs.field.value;
	}
}
