import BaseInput from '../_0-input/input';

const SELECTOR_FIELD = '.input-textarea__field';

/**
 * @class InputTextarea
 */
export default class InputTextarea extends BaseInput {
	init() {
		super.init(SELECTOR_FIELD, true);
	}

	bindEvents() {
		this.on('blur', SELECTOR_FIELD, this.onBlur.bind(this));
	}

	onBlur() {
		this.checkValidity();
	}
}
