/* eslint-disable curly */
import MySection from '../my-section/my-section';

export default class SectionWelcome extends MySection {
	init() {
		this.refs = {
			title: this.el.querySelector('.my-section--welcome .my-section__title'),
		};

		if (this.accountData.get('is_new_insider')) {
			this.showWelcomeMessage();
			this.accountData.set('is_new_insider', false);
		}
	}

	showWelcomeMessage() {
		const rawTitle = this.refs.title.textContent;
		const matches = [...rawTitle.matchAll(/\{\{\s?([^{]+)\s?\}\}/g)]
		const data = this.accountData.get('contact');

		// Replace found tags in the title
		this.refs.title.textContent =	matches
			.reduce((title, [tag, variable]) => title.replaceAll(tag, data[variable.trim()]), rawTitle);

		// Show the section
		this.el.classList.remove('is-hidden');
	}

}
