/* eslint-env browser */

import * as Sentry from '@sentry/browser';
import { isNil } from 'lodash-es';
import BaseView from '../../../js/base-view';
import InputText from '../../atoms/inputs/1-input-text/input-text';
import InputTextArea from '../../atoms/inputs/2-input-textarea/input-textarea';
import InputDate from '../../atoms/inputs/3-input-date/input-date';
import InputSelect from '../../atoms/inputs/4-input-select/input-select';
import InputRadio from '../../atoms/inputs/5-input-radio/input-radio';
import InputCheckbox from '../../atoms/inputs/6-input-checkbox/input-checkbox';
import InputFile from '../../atoms/inputs/7-input-file/input-file';
import InputPrice from '../../atoms/inputs/8-input-price/input-price';
import OptionGroup from '../../molecules/option-group/option-group';

const INPUT_VIEWS = {
	'text': InputText,
	'date': InputDate,
	'file': InputFile,
	'price': InputPrice,
	'radio': InputRadio,
	'select': InputSelect,
	'checkbox': InputCheckbox,
	'textarea': InputTextArea,
	'option-group': OptionGroup,
}

const CLASS_SENDING = 'is-sending';
const CLASS_SUCCESS = 'is-success';
const CLASS_ERROR = 'is-error';
const CLASS_REDIRECT = 'is-redirect';
// const CLASS_DISABLED = 'is-disabled';

const RECAPTCHA_URL = 'https://www.google.com/recaptcha/api.js';
const RECAPTCHA_MAX_LOAD_TRY = 7;

const TIMEOUT_ERROR = 30000; // If no response, it's an error

const ATTR_FIELD = 'data-input-field';

const SELECTOR_RECAPTCHA = 'input[name=rcpa]';
const SELECTOR_FIELD = '[data-input-field]';
const SELECTOR_MESSAGE_BTN = '.form__message-btn';
const SELECTOR_SUBMIT_BUTTON = '.form__submit';

const SCROLL_TOP_OFFSET = 150;

export default class Form extends BaseView {
	init() {
		this.refs = {
			content: this.el.querySelector('.form__content'),
			inputs: Array.from(this.el.querySelectorAll(SELECTOR_FIELD)),
			recaptchaField: this.el.querySelector(SELECTOR_RECAPTCHA),
			submit: this.el.querySelector(SELECTOR_SUBMIT_BUTTON),
		};

		this.props = {
			method: 'POST',
			action: this.el.getAttribute('action'),
			hasRecaptcha: this.refs.recaptchaField !== null,
			hasSteps: false,
		};

		this.state = {
			sending: false,
			success: this.el.classList.contains(CLASS_SUCCESS),
			error: this.el.classList.contains(CLASS_ERROR),
			currentStep: null,

			inputChangesStack: [],
			fieldsConditionRunning: false,

			recaptchaMaxLoadTry: RECAPTCHA_MAX_LOAD_TRY,
		};

		// We keep this state "global" to ensure if there are more
		// than 1 form on the page, recaptcha is not loaded twice or more
		window.recaptchaLoaded = window.recaptchaLoaded || false;

		this.loadFields();

		if (this.props.hasRecaptcha) {
			this.loadRecaptcha();
		}

		this.bindEvents();

		// As we have JS, add the novalidate attribute
		// because we handle the validation ourself
		this.el.setAttribute('novalidate', 'true');
	}

	/**
	 * Initialise the inputs features
	 */
	loadFields() {
		this.refs.fields = this.refs.inputs
			.map((element) => {
				try {
					const View = INPUT_VIEWS[element.getAttribute(ATTR_FIELD)] ?? InputText
					const v = new View(element, element.getAttribute(ATTR_FIELD));
					v.init();
					return v;
				}
				catch (error) {
					console.error('Can\'t to load field in form', element, error)
					return null
				}
			})
			.filter(Boolean);
	}

	checkStepValidity() {
		const fields = (
			this.props.hasSteps ?
				this.refs.steps[ this.state.currentStep ].fields :
				this.refs.fields
		);

		const invalidFields = fields.filter((f) => ! f.checkValidity());

		if (invalidFields.length) {
			invalidFields[ 0 ].focus();
			return false;
		}

		return true;
	}

	/**
	 * Render the reCAPTCHA instance &
	 * register a global function to be accessed by reCAPTCHA
	 */
	initRecaptcha() {
		// this.el.classList.remove(CLASS_DISABLED);
		const recaptchaInput = this.el.querySelector(SELECTOR_RECAPTCHA);

		// Render the recaptcha element
		this.recaptchaId = window.grecaptcha.render(
			recaptchaInput,
			{
				size: 'invisible',
				sitekey: recaptchaInput.value,
				callback: this.submitHandler.bind(this),
			},
		);
	}

	/**
	 * Wait for reCAPTCHA to be loaded and available
	 */
	waitRecaptchaLoaded() {
		if (! window.grecaptcha || typeof window.grecaptcha.render !== 'function') {
			if (this.state.recaptchaMaxLoadTry > 0) {
				this.state.recaptchaMaxLoadTry -= 1;
				setTimeout(this.waitRecaptchaLoaded.bind(this), 200);
			}
		}
		else {
			this.initRecaptcha();
		}
	}

	/**
	 * Load reCAPTCHA script
	 */
	loadRecaptcha() {
		if (window.recaptchaLoaded) {
			this.initRecaptcha();
			return;
		}

		window.recaptchaLoaded = true;

		const script = document.createElement('script');
		document.querySelector('head').appendChild(script);
		script.addEventListener('load', this.waitRecaptchaLoaded.bind(this));
		script.src = RECAPTCHA_URL;
	}

	bindEvents() {
		this.on('submit', this.onSubmit.bind(this));
		this.on('input:change', SELECTOR_FIELD, this.onInputChange.bind(this));
		this.on('click', SELECTOR_MESSAGE_BTN, this.onCloseMessage.bind(this));
	}

	/**
	 * Handle the submit Event and execute the appropiate process
	 *
	 * @param {Event} event The submit event
	 */
	onSubmit(event) {
		event.preventDefault();

		if (! this.checkStepValidity()) {
			return;
		}

		// Execute recaptcha validation if enabled or process to form submission
		if (this.props.hasRecaptcha) {
			window.grecaptcha.execute();
			return;
		}

		this.submitHandler();
	}

	onInputChange(event) {
		// console.log('⚡️️️️️️️⚡️⚡️', 'onInputChange', '⚡️️️️️️️⚡️⚡️');
		this.state.inputChangesStack.push(event.detail);
		this.updateFieldsCondition();
	}

	onCloseMessage(event) {
		event.preventDefault();
		this.closeMessage();
	}

	// #endregion

	// #region ACTIONS

	updateFieldsCondition() {
		if (this.state.fieldsConditionRunning) {
			return;
		}
		this.state.fieldsConditionRunning = true;

		while (this.state.inputChangesStack.length) {
			const condition = this.state.inputChangesStack.shift();

			this.refs.fields.forEach((f) => f.checkCondition(condition.name, condition.value));
		}

		if (this.props.hasSteps) {
			this.updateContentHeight(this.state.currentStep);
		}

		this.state.fieldsConditionRunning = false;
	}

	gotoStep(idx = 0, focusFirstField = true) {
		// Bail early if no steps
		if (! this.props.hasSteps) {
			return;
		}

		this.switchSteps(this.state.currentStep, idx).then(() => {
			// Update current step state
			this.state.currentStep = idx;

			// Focus on first field
			if (focusFirstField) {
				this.refs.steps[ this.state.currentStep ].fields[ 0 ].focus();
			}
		});
	}

	switchSteps(currentIdx, nextIdx) {
		return new Promise((resolve) => {
			const currentStep = this.refs.steps[ currentIdx ];
			const nextStep = this.refs.steps[ nextIdx ];

			// Remove previous is-current
			if (currentIdx !== null) {
				currentStep.el.classList.add('leave-current');
				setTimeout(() => {
					currentStep.el.classList.remove('is-current');
					currentStep.el.classList.remove('leave-current');
				}, 250);
			}

			this.updateStepIndicator(nextIdx);
			this.updateStepClasses(nextIdx);

			// Show new current step
			nextStep.el.classList.add('enter-current');

			this.updateContentHeight(nextIdx);

			setTimeout(() => {
				nextStep.el.classList.add('is-current');
				setTimeout(() => {
					nextStep.el.classList.remove('enter-current');
					resolve();
				}, 300);

				this.scrollToTop();
			}, 	250);
		});
	}

	scrollToTop() {
	// Scroll up to form__content
		const rect = this.el.getBoundingClientRect();
		if (rect.top - SCROLL_TOP_OFFSET < 0) {
			window.scrollBy({
				top: (rect.top - SCROLL_TOP_OFFSET),
				behavior: 'smooth',
			});
		}
	}

	updateStepIndicator(nextIdx) {
		// Updates the step number indicator
		this.refs.stepIndicatorCurrent.forEach((el) => {
			// eslint-disable-next-line no-param-reassign
			el.innerText = (nextIdx + 1);
		});
	}

	/**
	 * Updates the class step state first/last & button text
	 *
	 * @param {number} nextIdx Next id
	 */
	updateStepClasses(nextIdx) {
		if (nextIdx === 0) {
			this.el.classList.remove('is-last-step');
			this.el.classList.add('is-first-step');
			this.refs.submit.firstElementChild.innerText = this.props.nextStepText;
		}
		else if (nextIdx === this.props.nbSteps - 1) {
			this.el.classList.remove('is-first-step');
			this.el.classList.add('is-last-step');

			this.refs.submit.firstElementChild.innerText = this.props.submitText;
		}
		else {
			this.el.classList.remove('is-first-step');
			this.el.classList.remove('is-last-step');
			this.refs.submit.firstElementChild.innerText = this.props.nextStepText;
		}
	}

	updateContentHeight(stepIdx) {
		// Update form content height
		const newHeight = this.refs.steps[ stepIdx ].el.clientHeight;
		this.refs.content.style.height = `${ newHeight }px`;
	}

	submitHandler(token = null) {
		// Bail early if already submitting the form
		if (this.state.sending) {
			return;
		}
		this.toggleSending(true);

		const data = new FormData(this.el);

		// add the recaptcha token to the form data
		if (this.props.hasRecaptcha && token) {
			data.append('g-recaptcha-response', token);
		}

		// Clear inputs error params
		this.refs.fields.forEach((field) => field.resetError());

		// Send the request
		// Define timeout for the request
		Promise.race([this.sendForm(data), this.startSendTimeout()])
			.then((response) => {
				// Clear recaptcha as needed and discribed in the doc
				if (this.props.hasRecaptcha) {
					window.grecaptcha.reset();
				}
				if (typeof response.data !== 'undefined' && typeof response.data.redirect !== 'undefined') {
					this.triggerSaferpayRedirect(response.data.redirect);
				}
				else if (response.success) {
					this.triggerSuccess(response.data ?? null);
				}
				else {
					if (! isNil(response.data.global)) {
						Sentry.captureMessage(`Form global: ${ response.data.global }`);
						this.triggerError(`Form global: ${ response.data.global }`);
					}
					this.showFieldsError(response.data);
					this.toggleSending(false);
				}
			})
			.catch((err) => {
				Sentry.captureException(err);
				Sentry.captureMessage('empty err - possibly timeout?');
				this.triggerError(err ? err.toString() : 'empty err - possibly timeout?');
			});
	}

	/**
	 * Send the form with the given data
	 *
	 * @param {FormData} data FormData Object containing the form input values
	 * @return {Promise} A promise containing the response,
	 *                    in JSON object format on success or raw response on error
	 */
	sendForm(data = {}) {
		return new Promise((resolve, reject) => {
			fetch(this.props.action, {
				method: this.props.method,
				body: data,
				credentials: 'same-origin',
				headers: new Headers({ 'X-Request-Ajax': true }),
			})
				.then((promise) => resolve(promise.json()))
				.catch(reject);
		});
	}

	/**
	 * Starts a Promise based timeout
	 *
	 * @return {Promise} Return promise with timeout
	 */
	startSendTimeout() {
		return new Promise((resolve, reject) => setTimeout(reject, TIMEOUT_ERROR));
	}

	showFieldsError(errors) {
		Object.keys(errors).forEach((name) => {
			const field = this.refs.fields.find((f) => (f.getName() === name));
			if (typeof field !== 'undefined') {
				field.setError(errors[ name ]);
			}
		});
	}

	closeMessage() {
		if (this.state.success) {
			this.toggleSuccess(false);
			this.reset();
		}
		else if (this.state.error) {
			this.toggleError(false);
		}
	}

	/**
	 * Trigger the success state.
	 * SENDING => SUCCESS
	 *
	 * @param {any} data Some data received by the query
	 */
	triggerSuccess(data) {
		this.trigger('form:success');
		this.trigger('form:success', { detail: data }, false);

		this.toggleSending(false);
		setTimeout(() => this.toggleSuccess(true), 300); // 300ms timeout for smooth message transition
	}

	/**
	 * Trigger the redirect state.
	 * SENDING => Will redirect
	 *
	 * @param {string} url The url where to redirect to.
	 */
	triggerSaferpayRedirect(url) {
		this.trigger('form:redirect');

		this.toggleRedirect(false);
		setTimeout(() => {
			window.location.assign(url);
		}, 3000);
	}

	/**
	 * Trigger the error state
	 * SENDING => ERROR
	 *
	 * @param {string} errMsg Optional. Error message to output to the console
	 */
	triggerError(errMsg = null) {
		this.trigger('form:error');

		this.toggleSending(false);
		setTimeout(() => this.toggleError(true), 300); // 300ms timeout for smooth message transition

		if (errMsg) {
			console.error(errMsg);
		}
	}

	toggleSending(isSending) {
		this.el.classList[(isSending ? 'add' : 'remove')](CLASS_SENDING);
		this.state.sending = isSending;
	}

	toggleRedirect() {
		this.el.classList.add(CLASS_REDIRECT);
	}

	toggleSuccess(isSuccess) {
		this.el.classList[(isSuccess ? 'add' : 'remove')](CLASS_SUCCESS);
		this.state.success = isSuccess;
	}

	toggleError(isError) {
		this.el.classList[(isError ? 'add' : 'remove')](CLASS_ERROR);
		this.state.error = isError;
	}

	reset() {
		// Reset each fields
		this.refs.fields.forEach((field) => field.reset());

		// Reset the Form element
		this.el.reset();

		// Reset state & state classes
		this.toggleSending(false);
		this.toggleSuccess(false);
		this.toggleError(false);

		this.gotoStep(0, false);

		this.trigger('form:reset');
		this.trigger('form:reset', null, false);
	}

	// #endregion

	destroy() {
		this.refs.fields.forEach((field) => field.destroy());
		super.destroy();
	}
}
