/* eslint-disable no-restricted-globals */
import webkitLineClamp from 'webkit-line-clamp';

// Add on HTML element to be line clamped
// data-line-clamp="x"
// where x = number of lines
// OR
// data-line-clamp=tiny:3;medium:4
// where tiny is the device size & 3 is the number of line

const SELECTOR = '[data-line-clamp]';

export default function LineClamp() {
	const elementsToClamp = Array.from(document.querySelectorAll(SELECTOR));

	elementsToClamp.forEach((element) => {
		let lineClamp = false;
		if (element.style.webkitLineClamp) { // element is already "clamped"
			return;
		}
		if (! isNaN(parseInt(element.dataset.lineClamp))) {
			lineClamp = parseInt(element.dataset.lineClamp);
		}
		else {
			const items = element.dataset.lineClamp.split(';');
			let i = 0;
			do {
				const [size, nbLines] = items[ i ].split(':');

				if (size === 'all') {
					lineClamp = nbLines;
				}
				else if (size === 'desktop' && viewport_service.getViewportWidth() >= 1280) {
					lineClamp = nbLines;
				}
				else if (size === 'laptop' && viewport_service.getViewportWidth() >= 1024) {
					lineClamp = nbLines;
				}
				else if (size === 'medium' && viewport_service.getViewportWidth() >= 900) {
					lineClamp = nbLines;
				}
				else if (size === 'tablet' && viewport_service.getViewportWidth() >= 768) {
					lineClamp = nbLines;
				}
				else if (size === 'small' && viewport_service.getViewportWidth() >= 600) {
					lineClamp = nbLines;
				}
				else if (size === 'tiny' && viewport_service.getViewportWidth() < 600) {
					lineClamp = nbLines;
				}
				i += 1;
			} while (lineClamp === false && i < items.length);
		}

		if (lineClamp !== false) {
			webkitLineClamp(element, lineClamp);
		}
	});
}
