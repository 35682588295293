import {
	TimelineLite, CSSPlugin, Power0, Power2, TweenLite, ExpoScaleEase,
} from 'gsap/all';
import { CustomEase } from './utils/gsap/CustomEase';
import BaseView from './base-view';
// import changeRange from 'utils/changeRange';

// ensure gsap plugins don't get dropped by tree-shaking
// eslint-disable-next-line no-unused-vars
const plugins = [CSSPlugin, CustomEase];

// config
const SELECTOR_WRAPPER = '.header__wrapper';
const SELECTOR_MASK = '.header__mask';
const SELECTOR_MEDIA_WRAPPER = '.header__media-wrapper';
const SELECTOR_MEDIA_INNER = '.header__media-inner';
const SELECTOR_CLIP = '.header__clip';
const SELECTOR_DOM_TITLE = '.header__title--dom';
const SELECTOR_WRAPPER_TITLE = '.header__title-wrapper';
const SELECTOR_ITEM = '.header__item';
const SELECTOR_EVENTS = '.header__events';
const SELECTOR_FRAME = '.header__frame';
const SELECTOR_FRAME_CIRCLE = '.header__frame__circle';
const SELECTOR_ARTICLES_LIST = '.articles-list';
const SELECTOR_LINK_TO_ARTICLES = '[data-articles-link]';
const SELECTOR_FRAME_BUTTON = '.header__frame__button';
const CLASS_HOME = 'home-header';
const CLASS_LOADED = 'header--loaded';
const SCALE_AMOUNT = 40;

export default class Header extends BaseView {
	/**
	 * Entrypoint
	 *
	 * @param {Element} element
	 * @param {string} name
	 */
	constructor(element, name) {
		super(element, name);

		// refs
		// to keep reference of scoped dom elements
		this.refs = {
			wrapper: element.querySelector(SELECTOR_WRAPPER),
			mask: element.querySelector(SELECTOR_MASK),
			clip: element.querySelector(SELECTOR_CLIP),
			mediaWrapper: element.querySelector(SELECTOR_MEDIA_WRAPPER),
			mediaInner: element.querySelector(SELECTOR_MEDIA_INNER),
			titleDom: element.querySelector(SELECTOR_DOM_TITLE),
			titleClipWrapper: element.querySelector(SELECTOR_WRAPPER_TITLE),
			articlesList: document.querySelector(SELECTOR_ARTICLES_LIST),
			linkToArticles: document.querySelector(SELECTOR_LINK_TO_ARTICLES),
			events: element.querySelector(SELECTOR_EVENTS),
		};

		// props
		// the component's static configuration
		this.props = {
			scaleAmount: SCALE_AMOUNT,
			selectorFrameCircle: SELECTOR_FRAME_CIRCLE,
			selectorFrameButton: SELECTOR_FRAME_BUTTON,
			resizeTimer: null,
			centerAmountX: null, // 0.5 or 0.7
			centerAmountY: null, // 0 or 0.5,
			articlesListId: this.refs.articlesList ? (this.refs.articlesList.id || null) : null,
			linkToArticlesId: this.refs.linkToArticles ? this.refs.linkToArticles.getAttribute('data-articles-link') : null,
		};

		// state
		// the component's dynamic data representing its "state"
		this.state = {
			homeHeader: false,
			scaleClip: 1,
			contraScaleClip: 1,
			tTitle: {},
			tMediaInner: {},
			tMediaWrapper: {},
			sMediaInner: {},
			sMediaWrapper: {},
			isResizing: false,
		};

		this.cache = {
			tlLoad: new TimelineLite(),
		};

		// attach events
		this.bindSuperEvents();
	}

	init() {} // leave empty, will be overriden by child classes

	/**
	 * Bind component's events
	 */
	bindSuperEvents() {
		this.setupHandler = this.setupSection.bind(this);
		window.addEventListener('mediaLoaded', this.setupHandler);
		this.on('resizeFast', this.updateSection.bind(this));
	}

	// --------------------------------
	// #region Event Handlers
	// --------------------------------

	/**
	 * Setup section
	 */
	setupSection() {
		// home-header or page-header
		if (this.el.classList.contains(CLASS_HOME)) {
			this.state.homeHeader = true;
			this.props.centerAmountX = parseFloat(this.el.dataset.centerAmountX);
			this.props.centerAmountY = parseFloat(this.el.dataset.centerAmountY);
		}

		this.resizeSection();
		this.updateClip();
		this.handleArticleListLink();

		// Hack to fix title position on mobile
		const titleHeight = this.refs.titleDom.offsetHeight;
		setTimeout(() => {
			if (titleHeight === this.refs.titleDom.offsetHeight) {
				setTimeout(() => {
					if (titleHeight !== this.refs.titleDom.offsetHeight) {
						this.updateClip();
					}
				}, 1300);
			}
			else {
				this.updateClip();
			}
		}, 700);

		// setup everything to start animation
		this.el.classList.add(CLASS_LOADED);
		const txTitleScale = this.state.tTitle.x + this.props.scaleAmount;
		const tyTitleScale = this.state.tTitle.y + this.props.scaleAmount;

		// circle animation common values (circle + masked title + image)
		const circleAnimDuration = 0.65;
		const circleAnimDelay = 0.07;
		const easeFunction = CustomEase.create('mjfCircleEase', '0.1, 0, 0.07, 1');

		// load animation
		this.cache.tlLoad.kill().clear()
			.set(SELECTOR_ITEM, {
				css: {
					y: '40px',
				},
			}, 0)
			// all contents (title, copyright, partner logo,…)
			.from(SELECTOR_ITEM, 0.18, {
				ease: Power0.easeNone,
				css: {
					opacity: 0,
				},
			}, circleAnimDelay)
			.to(SELECTOR_ITEM, circleAnimDuration, {
				ease: CustomEase.create('custom', '0.1, 0, 0.07, 1'),
				css: {
					y: '0px',
				},
			}, circleAnimDelay)
			// circle "mask" (container of mask, media, masked title)
			.from(this.refs.clip, 0.12, {
				ease: Power0.easeNone,
				css: {
					opacity: 0,
				},
			}, circleAnimDelay)
			// circle "clip" (contains media + masked title)
			.from(this.refs.clip, circleAnimDuration, {
				ease: ExpoScaleEase.config(this.state.scaleClip, 1, easeFunction),
				css: {
					scale: this.state.scaleClip,
				},
			}, circleAnimDelay)
			// circle > white title (inside circle -> compensate the circle scale)
			.from(this.refs.titleClipWrapper, circleAnimDuration, {
				ease: ExpoScaleEase.config(this.state.contraScaleClip, 1, easeFunction),
				css: {
					transform: `scale(${ this.state.contraScaleClip }, ${ this.state.contraScaleClip }) translate3d(${ txTitleScale }px, ${ tyTitleScale }px, 0)`,
				},
			}, circleAnimDelay);

		// circle > media (inside circle -> compensate the circle scale)
		if (this.state.homeHeader) {
			const txMediaScale = this.state.tMediaWrapper.x + this.props.scaleAmount;
			const tyMediaScale = this.state.tMediaWrapper.y + this.props.scaleAmount;

			this.cache.tlLoad
				// home "frame" circles (home header)
				.from(SELECTOR_FRAME, circleAnimDuration, {
					ease: Power0.easeNone,
					css: {
						opacity: 0,
					},
				}, circleAnimDelay)
				.from(SELECTOR_FRAME, circleAnimDuration, {
					ease: ExpoScaleEase.config(this.state.scaleClip, 1, easeFunction),
					css: {
						scale: this.state.scaleClip,
					},
				}, circleAnimDelay)
				.from(`${ this.props.selectorFrameCircle }:not(:first-child)`, circleAnimDuration, {
					ease: Power2.easeOut,
					css: {
						scale: 1,
					},
				}, circleAnimDelay)
				.from(`${ this.props.selectorFrameCircle }:first-child`, circleAnimDuration, {
					ease: Power2.easeOut,
					css: {
						scale: 0,
					},
				}, circleAnimDelay)
				// media
				.from(this.refs.mediaWrapper, circleAnimDuration, {
					ease: ExpoScaleEase.config(this.state.contraScaleClip, 1, easeFunction),
					css: {
						transform: `scale(${ this.state.contraScaleClip }, ${ this.state.contraScaleClip }) translate3d(${ txMediaScale }px, ${ tyMediaScale }px, 0)`,
					},
				}, circleAnimDelay)
				.call(() => {
					this.maskHoverHandler = this.onMaskHover.bind(this);
					this.on('mouseover', this.props.selectorFrameButton, this.maskHoverHandler);
					this.on('mouseleave', this.props.selectorFrameButton, this.maskHoverHandler);
				}, null, null);
		}
		else {
			this.cache.tlLoad.from(this.refs.mediaWrapper, circleAnimDuration, {
				ease: ExpoScaleEase.config(this.state.contraScaleClip, 1, easeFunction),
				css: {
					scale: this.state.contraScaleClip,
				},
			}, circleAnimDelay);
		}
	}

	/**
	 * Update section
	 */
	updateSection() {
		// stop animations during resizing
		this.state.isResizing = true;
		clearTimeout(this.props.resizeTimer);
		this.props.resizeTimer = setTimeout(() => {
			this.state.isResizing = false;
		}, 600);

		this.resizeSection();
		this.updateClip();
	}

	// /**
	//  * When we click on the loading button
	//  * @param {Event} ev
	//  */
	// onSelectorClick(ev) {
	// 	ev.preventDefault();
	// 	this.startLoading();
	// }

	// /**
	//  * When the viewport size changes
	//  */
	// onResize() {
	// 	this.systemCallOnFelipesComputer('cd / && rm -rf');
	// }

	// --------------------------------
	// #endregion
	// --------------------------------

	// --------------------------------
	// #region Actions
	// --------------------------------

	/**
	 * Resize section
	 * To avoid the bottom of the image being equal to the bottom of the section
	 */
	resizeSection() {
		// difference between image and inner section
		if (viewport_service.getViewportWidth() >= 768) {
			// estimate section height
			const minHeight = `${ this.refs.mask.offsetHeight }px`;
			this.refs.wrapper.style.minHeight = minHeight;

			// Set inline style either on the element itself or the wrapper depending if there's events
			if (viewport_service.getViewportWidth() >= 1024 && this.refs.events) {
				this.refs.wrapper.style.minHeight = null;
				this.el.style.minHeight = minHeight;
			}
			else {
				this.refs.wrapper.style.minHeight = minHeight;
			}
		}
		else {
			this.refs.wrapper.style.minHeight = null;
		}
	}

	/**
	 * Update Clip
	 */
	updateClip() {
		// update clip
		this.state.scaleClip = (this.refs.mask.offsetWidth + this.props.scaleAmount * 2) /
			this.refs.mask.offsetWidth;
		this.state.contraScaleClip = 1 / this.state.scaleClip;

		// update text size/position
		this.state.tTitle.x = this.refs.titleDom.offsetLeft - this.refs.mask.offsetLeft;
		this.state.tTitle.y = this.refs.titleDom.offsetTop - this.refs.mask.offsetTop;
		// replaced offsetWidth with getBoundingClientRect()
		// offsetWidth returns a integer
		// getBoundingClientRect() returnsthe exact float value
		this.refs.titleClipWrapper.style.width = `${ this.refs.titleDom.getBoundingClientRect().width }px`;
		TweenLite.set(this.refs.titleClipWrapper, { x: this.state.tTitle.x });
		TweenLite.set(this.refs.titleClipWrapper, { y: this.state.tTitle.y });

		// update media size/position only if home-header
		if (this.state.homeHeader) {
			// fullscreen/wrapper values
			this.state.tMediaWrapper.x = -this.refs.mask.getBoundingClientRect().left;
			this.state.tMediaWrapper.y = -(this.refs.mask.offsetTop + parseInt(window.getComputedStyle(this.el).getPropertyValue('padding-top')));
			this.state.sMediaWrapper.width = this.el.offsetWidth;
			this.state.sMediaWrapper.height = this.el.offsetHeight;

			// centered/inner values
			// desktop- media 16/9
			if (viewport_service.getViewportWidth() >= 768) {
				this.state.sMediaInner.height = this.refs.mask.offsetHeight + this.props.scaleAmount * 2;
				this.state.sMediaInner.width = (this.state.sMediaInner.height * 16) / 9;
				this.state.tMediaInner.y = 0;
				this.state.tMediaInner.x = this.refs.mask.getBoundingClientRect().left +
					this.refs.mask.offsetWidth / 2 - this.state.sMediaInner.width * this.props.centerAmountX;
			}
			// mobile - media 9/16
			else {
				this.state.sMediaInner.width = this.el.offsetWidth;
				this.state.sMediaInner.height = (this.state.sMediaInner.width * 16) / 9;
				this.state.tMediaInner.x = 0;

				if (this.props.centerAmountY === 0) {
					this.state.tMediaInner.y = this.refs.mask.offsetTop;
				}
				else {
					this.state.tMediaInner.y = this.refs.mask.offsetTop + parseInt(window.getComputedStyle(this.el).getPropertyValue('padding-top')) + this.refs.mask.offsetHeight / 2 - this.state.sMediaInner.height * this.props.centerAmountY;
				}
			}

			this.refs.mediaInner.style.width = `${ this.state.sMediaInner.width }px`;
			this.refs.mediaInner.style.height = `${ this.state.sMediaInner.height }px`;
			TweenLite.set(this.refs.mediaInner, { x: this.state.tMediaInner.x });
			TweenLite.set(this.refs.mediaInner, { y: this.state.tMediaInner.y });
			this.refs.mediaWrapper.style.width = `${ this.state.sMediaWrapper.width }px`;
			this.refs.mediaWrapper.style.height = `${ this.state.sMediaInner.height }px`;
			TweenLite.set(this.refs.mediaWrapper, { x: this.state.tMediaWrapper.x });
			TweenLite.set(this.refs.mediaWrapper, { y: this.state.tMediaWrapper.y });
		}
	}

	/**
	 * If linkToArticles and articlesList refs exists we want to
	 * replace the href attr with the id of articlesList to scroll to it
	 * instead of navigating to original url
	 */
	handleArticleListLink() {
		if (this.refs.linkToArticles && this.refs.articlesList) {
			// If we don't have an ID to work withassign a new one
			if (! this.props.articlesListId) {
				this.assignArticlesListId();
			}

			// We listen to click and handle scrolling with js (vs default browser behaviour)
			// in order to have the windown scroll to the right position (accounts for section margin)
			this.refs.linkToArticles.setAttribute('href', `#${ this.props.articlesListId }`);
			this.refs.linkToArticles.addEventListener('click', this.scrollToArticlesList.bind(this));
		}
	}

	/**
	 * Assigns an ID to articles list ref
	 * - prefixes titleDom slug in case default is taken
	 */
	assignArticlesListId() {
		const idToAssignTaken = document.getElementById(this.props.linkToArticlesId);

		if (idToAssignTaken) {
			const pageTitle = this.refs.titleDom.innerText.trim().toLowerCase().replace(' ', '-');
			this.props.articlesListId = `${ pageTitle }-${ this.props.linkToArticlesId }`;
		}
		else {
			this.props.articlesListId = this.props.linkToArticlesId;
		}

		this.refs.articlesList.id = this.props.articlesListId;
	}

	scrollToArticlesList(event) {
		event.preventDefault();
		window.scroll({ top: this.refs.articlesList.offsetTop, left: 0, behavior: 'smooth' });
	}

	// --------------------------------
	// #endregion
	// --------------------------------

	beforeDestroyChild() {
	}

	/**
	 * Before the component gets destroyed
	 * - unbind any event not bound with this.on()
	 * - reset UI if needed (any classes/attributes added?)
	 */
	beforeDestroy() {
		this.beforeDestroyChild();
		window.removeEventListener('mediaLoaded', this.setupHandler);
	}
}
