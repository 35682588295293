import BaseView from '../../../js/base-view';

const SIDE_COLUMN_SELECTOR = '.wp-block-supt-column-side';
const PAGE_NAVBAR_SELECTOR = '.navbar';
const WORDPRESS_ADMINBAR_SELECTOR = '#wpadminbar';
const VIEWPORT_THRESHOLD = 768; // pixels, below this we don't update calculations

export default class Columns extends BaseView {
	/**
	 * Entrypoint
	 */
	init() {
		// refs
		// to keep reference of scoped dom elements
		this.refs = {
			sideColumn: this.el.querySelector(SIDE_COLUMN_SELECTOR),
			// eslint-disable-next-line max-len
			navBar: document.querySelector(PAGE_NAVBAR_SELECTOR), // need to access it outside of this component scope
		};

		// props
		// the component's static configuration
		this.props = {
			// myProperty: this.refs.subElement.getAttribute(ATTR_MYPROPERTY),
		};

		// state
		// the component's dynamic data representing its "state"
		this.state = {
			// isLoading: false,
		};

		// attach events
		this.bindEvents();
	}

	/**
	 * Bind component's events
	 */
	bindEvents() {
		// to avoid getting the element size/position without being fully loaded
		this.loadHandler = this.maybeDefineSideColumnHeight.bind(this);
		window.addEventListener('load', this.loadHandler);
		document.addEventListener('swup:contentReplaced', this.loadHandler);
		this.on('resizeFast', this.handleResize.bind(this));
	}

	// --------------------------------
	// #region Event Handlers
	// --------------------------------

	/**
	 * Update side column height
	 */
	maybeDefineSideColumnHeight() {
		// Remove previously defined height if on mobile
		// TODO: update code once we got rid of our viewport_service
		// (would be better to export helper functions like getViewportHeight())
		if (window.viewport_service.current_width < VIEWPORT_THRESHOLD) {
			this.refs.sideColumn.style.removeProperty('height');
			return;
		}
		// Apply new inline height
		const sideColumnHeight = this.calcTotalHeight(this.refs.sideColumn);
		this.refs.sideColumn.style.height = `${ sideColumnHeight }px`;

		// Account for site navbar and possibly WP admin bar
		let topCompensationHeight = this.refs.navBar.clientHeight;

		if (document.body.classList.contains('admin-bar')) {
			// eslint-disable-next-line max-len
			this.refs.wordPressAdminBar = document.querySelector(WORDPRESS_ADMINBAR_SELECTOR);
			if (this.refs.wordPressAdminBar) {
				topCompensationHeight += this.refs.wordPressAdminBar.clientHeight;
			}
		}
		// if side column height is bigger than the viewport height, make it stick to bottom
		this.refs.sideColumn.style.top = window.viewport_service.current_height < sideColumnHeight ? `calc(100vh - ${ sideColumnHeight + 80 }px)` : `${ topCompensationHeight }px`;
	}

	/**
	 * Calculate total height of elements included in an element
	 *
	 * @param element
	 */
	calcTotalHeight(element) {
		let totalHeight = 0;
		for (let i = 0; i < element.children.length; i++) {
			if (element.children[ i ].tagName === 'FIGURE') {
				if (element.getElementsByTagName('img').length && !element.getElementsByTagName('img')[0].getAttribute('src')) {
					// Remove the image block if no image was specified in the editor.
					element.removeChild(element.children[i]);
				} else {
					// Even if the image is not loaded yet, we can deduce its height
					// from its width because it's a square image
					totalHeight += element.clientWidth;
					// …and then we just need to add the caption height as well, if present
					const captionEl = element.children[ i ].querySelector('figcaption');
					if (captionEl != null) {
						totalHeight += captionEl.clientHeight;
					}
				}
			}
			else {
				// eslint-disable-next-line max-len
				totalHeight += element.children[ i ].clientHeight + parseInt(getComputedStyle(element.children[ i ]).marginTop, 10) + parseInt(getComputedStyle(element.children[ i ]).marginBottom, 10);
			}
		}
		return totalHeight;
	}

	/**
	 * Update calculations on resize
	 */
	handleResize() {
		this.maybeDefineSideColumnHeight();
	}

	/**
	 * Before the component gets destroyed
	 * - unbind any event not bound with this.on()
	 * - reset UI if needed (any classes/attributes added?)
	 */
	beforeDestroy() {
		// this.resetUI();
		window.removeEventListener('load', this.loadHandler);
		document.removeEventListener('swup:contentReplaced', this.loadHandler);
	}
}
