import party from 'party-js';
import MySection from '../my-section/my-section';

const TIMER_SELECTOR = '[data-timer]';
const BUTTON_SELECTOR = '.my-section__button';

const CIRCLE_YELLOW ='<svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg"><circle cx="1513" cy="610" r="17.5" transform="translate(-1493 -590)" stroke="#FFED3B" stroke-width="5" fill="none" fill-rule="evenodd"/></svg>';
const CIRCLE_GREEN ='<svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg"><circle cx="1513" cy="610" r="17.5" transform="translate(-1493 -590)" stroke="#B1FFCD" stroke-width="5" fill="none" fill-rule="evenodd"/></svg>';
const CIRCLE_GRAD ='<svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg"><defs><linearGradient x1="104.825%" y1="-2.012%" x2="0%" y2="44.873%" id="a"><stop stop-color="#FFFE54" offset="0%"/><stop stop-color="#B1FFCD" offset="100%"/></linearGradient></defs><circle cx="1377" cy="396" r="17.5" transform="translate(-1357 -376)" stroke="url(#a)" stroke-width="5" fill="none" fill-rule="evenodd"/></svg>';
const ARC_YELLOW = '<svg width="36" height="36" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg"><path d="M3 33C3 16.431 16.431 3 33 3" stroke="#FFED3B" stroke-width="5" fill="none" fill-rule="evenodd" stroke-linecap="round"/></svg>';
const ARC_GREEN = '<svg width="36" height="36" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg"><path d="M3 33C3 16.431 16.431 3 33 3" stroke="#B1FFCD" stroke-width="5" fill="none" fill-rule="evenodd" stroke-linecap="round"/></svg>';
const BULLET_YELLOW = '<svg width="15" height="15" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg"><circle cx="1474.5" cy="519.5" r="7.5" transform="translate(-1467 -512)" fill="#FFED3B" fill-rule="evenodd"/></svg>';
const BULLET_GREEN = '<svg width="15" height="15" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg"><circle cx="1474.5" cy="519.5" r="7.5" transform="translate(-1467 -512)" fill="#B1FFCD" fill-rule="evenodd"/></svg>';
const ZIGZAG_YELLOW = '<svg width="54" height="20" viewBox="0 0 54 20" xmlns="http://www.w3.org/2000/svg"><path d="m1.913 3.526 12.5 12.5 12.5-12.5 12.5 12.5 12.5-12.5" stroke="#FFED3B" stroke-width="5" fill="none" fill-rule="evenodd"/></svg>';
const ZIGZAG_GREEN = '<svg width="54" height="20" viewBox="0 0 54 20" xmlns="http://www.w3.org/2000/svg"><path d="m1.913 3.526 12.5 12.5 12.5-12.5 12.5 12.5 12.5-12.5" stroke="#B1FFCD" stroke-width="5" fill="none" fill-rule="evenodd"/></svg>';
const ZIGZAG_GRAD = '<svg width="35" height="47" viewBox="0 0 35 47" xmlns="http://www.w3.org/2000/svg"><defs><linearGradient x1="-64.13%" y1="59.371%" x2="104.825%" y2="46.749%" id="a"><stop stop-color="#FFFE54" offset="0%"/><stop stop-color="#B1FFCD" offset="100%"/></linearGradient></defs><path d="m1313 441 12.5 12.5L1338 441l12.5 12.5L1363 441" transform="scale(1 -1) rotate(58 1099.098 -980.378)" stroke="url(#a)" stroke-width="5" fill="none" fill-rule="evenodd"/></svg>';

export default class SectionEarlyBirds extends MySection {
	/**
	 * Entrypoint
	 */
	init() {
		this.refs = {
			timer: this.el.querySelector(TIMER_SELECTOR),
			button: this.el.querySelector(BUTTON_SELECTOR),
		};

		this.state = {
			confetti: false,
		}

		const hasLink = this.refs.button && this.refs.button.querySelector('a') && this.refs.button.querySelector('a').href;

		if (!this.refs.timer && hasLink) {
			this.handleTimerFinished();
			return;
		}

		this.bindEvents();
	}

	/**
	 * Bind component's events
	 */
	bindEvents() {
		this.on('finished', TIMER_SELECTOR, this.handleTimerFinished.bind(this));
	}

	MJFConfetti(source, options) {
		party.resolvableShapes.circleYellow = CIRCLE_YELLOW;
		party.resolvableShapes.circleGreen = CIRCLE_GREEN;
		party.resolvableShapes.circleGrad = CIRCLE_GRAD;
		party.resolvableShapes.zigzagYellow = ZIGZAG_YELLOW;
		party.resolvableShapes.zigzagGreen = ZIGZAG_GREEN;
		party.resolvableShapes.zigzagGrad = ZIGZAG_GRAD;
		party.resolvableShapes.bulletYellow = BULLET_YELLOW;
		party.resolvableShapes.bulletGreen = BULLET_GREEN;
		party.resolvableShapes.arcYellow = ARC_YELLOW;
		party.resolvableShapes.arcGreen = ARC_GREEN;

		const populated = party.util.overrideDefaults(
			{
				count: party.variation.range(25, 50),
				spread: party.variation.range(20, 30),
				speed: party.variation.range(300, 600),
				size: party.variation.skew(0.8, 1),
				rotation: () => party.Vector.forward.scale(party.random.randomRange(0, 360)),
				color: () => party.Color.white,
				modules: [
					new party.ModuleBuilder()
						.drive('size')
						.by((t) => Math.min(1, t * 3))
						.relative()
						.build(),
					new party.ModuleBuilder()
						.drive('rotation')
						.by((t) => new party.Vector(0, 0, 260).scale(t))
						.relative()
						.build(),
				],
				shapes: ['circleYellow',
					'circleGreen',
					'circleGrad',
					'zigzagYellow',
					'zigzagGreen',
					'zigzagGrad',
					'bulletYellow',
					'bulletGreen',
					'arcYellow',
					'arcGreen']
			},
			options
		);

		const emitter = party.scene.current.createEmitter({
			emitterOptions: {
				loops: 1,
				duration: 8,
				modules: populated.modules,
			},
			emissionOptions: {
				rate: 0,
				bursts: [{ time: 0, count: populated.count }],

				sourceSampler: party.sources.dynamicSource(source),
				angle: party.variation.skew(
					-90,
					party.variation.evaluateVariation(populated.spread)
				),

				initialLifetime: 8,
				initialSpeed: populated.speed,
				initialSize: populated.size,
				initialRotation: populated.rotation,
				initialColor: populated.color,
			},
			rendererOptions: {
				shapeFactory: populated.shapes,
				applyLighting: undefined,
			},
		});

		return emitter;
	}

	startConfetti() {
		if(this.state.confetti) return;
		this.MJFConfetti(this.refs.button);
		this.state.confetti = true;
	}

	/**
	 * Handle Timer finished
	 *
	 * @param {boolean} showConfettis Optional. Should confettis be thrown. Default true
	 */
	handleTimerFinished(showConfettis = true) {
		this.el.classList.add('timerIsFinished');
		this.refs.button.classList.remove('button--disabled');
		this.refs.button.firstElementChild.removeAttribute('disabled');
		if (showConfettis) this.startConfetti();
	}
}
