/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
/* eslint-disable curly */
/* eslint-disable operator-linebreak */
/**
 * External depedencies
 */
import { isEmpty } from 'lodash-es';
import Fuse from 'fuse.js';

/**
 * Local depedencies
 */
import BaseView from '../../../js/base-view';

const MAX_NB_RESULTS = 8; // TODO maybe adapt when integrated in main menu

const CLASS_IS_FILLED = 'is-filled';
const CLASS_IS_FOCUS = 'is-focus';

const SELECTOR_BAR = '.artist-search__bar';
const SELECTOR_BAR_INPUT = '.artist-search__bar-input';
const SELECTOR_BAR_CLEAR = '.artist-search__bar-clear';

const SELECTOR_LIST = '.artist-search__list';
const SELECTOR_LIST_WRAP = '.artist-search__list-wrap';

const SELECTOR_IS_FOCUS = `.${ CLASS_IS_FOCUS }`;

const SELECTOR_MENU_DROPDOWN = '.dropdown__menu';

export default class ArtistSearch extends BaseView {
	init() {
		if (! window.supt || ! window.supt.artistSearch) {
			console.error('Could not load "ArtistSearch" config');
			return false;
		}

		this.config = window.supt.artistSearch;

		this.refs = {
			bar: this.el.querySelector(SELECTOR_BAR),
			barInput: this.el.querySelector(SELECTOR_BAR_INPUT),
			list: this.el.querySelector(SELECTOR_LIST),
			listWrap: this.el.querySelector(SELECTOR_LIST_WRAP),
			menuDropdown: this.el.closest(SELECTOR_MENU_DROPDOWN),
		};

		this.props = {
			artists: [], // will load asynchronously
			fuse: null,
		};

		this.state = new Proxy(
			{
				searchValue: '',
				isBarInputFilled: false,
				currentItemFocus: -1,
				artistsLoaded: false,
				fuseReady: false,
				listHeight: null,
				artistResults: [],
			},
			{ set: this.stateChange.bind(this) }
		);

		// Finish to init only when the component will be focused
		this.on('focus', SELECTOR_BAR_INPUT, this.initDelayed.bind(this));

		return this;
	}

	initDelayed() {
		this.off('focus', SELECTOR_BAR_INPUT);
		this.loadArtists();
		this.bindEvents();
	}

	/**
	 * Trap handler for the state object
	 *
	 * @param {Object} state Current state object
	 * @param {string} property The name of the property to set in the state
	 * @param {any} value The new value of the property to set
	 *
	 * @return {boolean} Indicate wether or not the assignment succeeded
	 */
	stateChange(state, property, value) {
		// Bail early if value did not change
		if (value === state[ property ]) {
			return true;
		}

		const prevValue = Array.isArray(value) ? [...state[ property ]] : state[ property ];

		// eslint-disable-next-line no-param-reassign
		state[ property ] = value; // Update the state

		// eslint-disable-next-line default-case
		switch (property) {
			case 'searchValue':
				// Perform search
				this.state.isBarInputFilled = ! isEmpty(value);
				this.state.currentItemFocus = -1;
				this.updateInputValue();

				if (isEmpty(value)) this.state.listHeight = null;
				else this.calcListHeight();

				if (JSON.stringify(prevValue) !== JSON.stringify(value)) this.searchArtists();
				break;

			case 'isBarInputFilled':
				this.toggleIsBarInputFilled();
				break;

			case 'artistResults':
				this.renderList();
				break;

			case 'fuseReady':
			case 'artistsLoaded':
				this.searchArtists();
				break;

			case 'currentItemFocus':
				// make sure to keep focus number within the range of 0 and list length
				// eslint-disable-next-line no-param-reassign
				state[ property ] = Math.max(Math.min(value, (state.artistResults.length - 1)), -1);
				this.moveFocus();
				break;

			case 'listHeight':
				this.updateListHeight();
				break;
		}

		return true;
	}

	loadArtists() {
		fetch(`${ this.config.api }/artists`)
			.then((res) => res.json())
			.then((res) => {
				this.props.artists = res;
				this.state.artistsLoaded = true;
				this.initFuse();
			});
	}

	initFuse() {
		if (! this.state.artistsLoaded) return;

		// TODO: maybe refine the search options
		this.props.fuse = new Fuse(this.props.artists, {
			shouldSort: true,
			tokenize: true,
			threshold: 0.2,
			location: 0,
			distance: 0,
			maxPatternLength: 16,
			minMatchCharLength: 1,
			keys: ['name', 'name_norm'],
		});

		this.state.fuseReady = true;
	}

	/**
	 * Bind component's events
	 */
	bindEvents() {
		this.clearArtistSearchHandler = this.onBarClearClick.bind(this);
		this.on('keydown', SELECTOR_BAR_INPUT, this.onBarInputKeydown.bind(this));
		this.on('keyup', SELECTOR_BAR_INPUT, this.onBarInputKeyup.bind(this));
		this.on('click', SELECTOR_BAR_CLEAR, this.clearArtistSearchHandler);
		window.addEventListener('clearArtistSearch', this.clearArtistSearchHandler);
	}

	// --------------------------------
	// #region Event Handlers
	// --------------------------------

	/**
	 * Filter out & trigger on specific keys
	 * which have a special behaviour
	 *
	 * @param {*} ev
	 */
	onBarInputKeydown(ev) {
		switch (ev.key) {
			case 'ArrowDown':
				ev.preventDefault();
				this.state.currentItemFocus += 1;
				break;

			case 'ArrowUp':
				ev.preventDefault();
				this.state.currentItemFocus -= 1;
				break;

			case 'Enter':
				ev.preventDefault();
				this.navigateTo();
				break;
		}
	}

	/**
	 * When user type in the search bar
	 *
	 * @param {Event} ev
	 */
	onBarInputKeyup(ev) {
		this.state.searchValue = ev.target.value;
	}

	/**
	 * When user click on the clear button
	 *
	 * @param {Event} ev
	 */
	onBarClearClick(ev) {
		ev.preventDefault();
		this.state.searchValue = '';
	}

	// --------------------------------
	// #endregion
	// --------------------------------

	// --------------------------------
	// #region Actions
	// --------------------------------

	searchArtists() {
		if (! this.state.fuseReady) return;

		this.state.artistResults = isEmpty(this.state.searchValue)
			? []
			: this.props.fuse.search(this.state.searchValue).slice(0, MAX_NB_RESULTS);
	}

	navigateTo() {
		if (this.state.currentItemFocus < 0) return;
		const linkEl = this.refs.list.children[ this.state.currentItemFocus ].querySelector('a');
		if (linkEl) linkEl.click();
	}

	/**
	 * This
	 */
	calcListHeight() {
		if (! this.refs.menuDropdown) return;

		const listTop = this.refs.listWrap.offsetTop;
		const parentTop = this.el.parentElement.offsetTop;
		const menuDPHeight = this.refs.menuDropdown.offsetHeight;

		this.state.listHeight = menuDPHeight - (listTop + parentTop);
	}

	// eslint-disable-next-line no-unused-vars
	_x(text, context = null, domain = null) {
		if (this.config.texts[ text ]) text = this.config.texts[ text ];
		return text;
	}

	_nx(single, plural, number, context = null, domain = null) {
		const text = number > 1 ? plural : single;
		return this._x(text, context, domain);
	}

	// --------------------------------
	// #endregion
	// --------------------------------

	// --------------------------------
	// #region DOM Manipulation
	// --------------------------------

	updateInputValue() {
		this.refs.barInput.value = this.state.searchValue;
	}

	toggleIsBarInputFilled() {
		this.refs.bar.classList[ this.state.isBarInputFilled ? 'add' : 'remove' ](CLASS_IS_FILLED);
	}

	moveFocus() {
		const currentEl = this.refs.list.querySelector(SELECTOR_IS_FOCUS);
		if (currentEl) currentEl.classList.remove(CLASS_IS_FOCUS);

		if (this.state.currentItemFocus < 0) return;

		this.refs.list.children[ this.state.currentItemFocus ].classList.add(CLASS_IS_FOCUS);
	}

	renderList() {
		while (this.refs.list.firstChild) {
			this.refs.list.removeChild(this.refs.list.firstChild);
		}

		/**
		 * Both rendering below are a copy of the component
		 *   atoms/artist-search-item/artist-search-item.twig
		 * Therefore don't forget to replicate the changes you do here
		 */
		if (isEmpty(this.state.artistResults) && ! isEmpty(this.state.searchValue)) {
			this.refs.list.insertAdjacentHTML(
				'beforeend',
				`<article class="artist-search-item artist-search-item--no-result">
					<div class="artist-search-item__img">
					</div>
					<div class="artist-search-item__content">
						<h1 class="artist-search-item__name">${ this._x('No artist found', 'Article search item', 'supt') }</h1>
					</div>
				</article>`
			);
		}

		this.state.artistResults.forEach((data) => {
			this.refs.list.insertAdjacentHTML(
				'beforeend',
				`<article class="artist-search-item">
					<div class="artist-search-item__img">
						<img src="${ data.img.src }" alt="${ data.img.alt }">
					</div>
					<div class="artist-search-item__content">
						<h1 class="artist-search-item__name">
							<a class="artist-search-item__link" data-swup-transition="menu-transition" href="${ data.link }">${ data.name }</a>
						</h1>
						${ data.nbArticle ? `<p class="artist-search-item__count">${ this._nx('%d article', '%d articles', data.nbArticle, 'Artist search item', 'supt').replace('%d', data.nbArticle) }</p>` : '' }
					</div>
					${ data.tickets ? `<div class="wp-block-button artist-search-item__tickets-tag button--tertiary">
							<span class="wp-block-button__link">${ this._x('Tickets', 'Article search item', 'supt') }</span>
						</div>` : '' }
				</article>`
			);
		});
	}

	updateListHeight() {
		this.refs.listWrap.style.height = (this.state.listHeight ? `${ this.state.listHeight }px` : null);
	}

	// --------------------------------
	// #endregion
	// --------------------------------

	/**
	 * Before the component gets destroyed
	 * - unbind any event not bound with this.on()
	 * - reset UI if needed (any classes/attributes added?)
	 */
	beforeDestroy() {
		window.removeEventListener('clearArtistSearch', this.clearArtistSearchHandler);
	}
}
