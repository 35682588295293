
import BaseInput from '../_0-input/input';

const SELECTOR_FIELD = '.input-price__field';
const SELECTOR_CUSTOM_AMOUNT = '.input-price__label input';

/**
 * @class InputText
 */
export default class InputPrice extends BaseInput {
	init() {
		super.init(SELECTOR_FIELD);

		const customAmountInput = this.el.querySelector(SELECTOR_CUSTOM_AMOUNT);
		if (customAmountInput) {
			this.refs.customAmountInput = customAmountInput;
			this.state.isCustomAmoutSet = false;

			this.on('focus', SELECTOR_FIELD, this.onFocus.bind(this));
			this.on('focus', SELECTOR_CUSTOM_AMOUNT, this.onCustomAmountFocus.bind(this));
			this.on('blur', SELECTOR_CUSTOM_AMOUNT, this.onCustomAmountBlur.bind(this));
			this.on('keypress', SELECTOR_CUSTOM_AMOUNT, this.onCustomAmountChange.bind(this));
		}
	}

	onFocus(e) {
		if (e.relatedTarget && e.relatedTarget === this.refs.customAmountInput) {
			const previousPriceRadio = this.el.parentElement.previousElementSibling
				.querySelector(SELECTOR_FIELD);

			if (previousPriceRadio) {
				previousPriceRadio.focus();
				previousPriceRadio.checked = true;
			}
		}
		else {
			this.refs.customAmountInput.focus();
		}
	}

	onCustomAmountFocus() {
		this.refs.field.checked = true;
		this.refs.label.classList.add('is-focus');
	}

	onCustomAmountBlur() {
		this.formatValue();
		this.checkCustomInputFilled();
		this.refs.label.classList.remove('is-focus');
	}

	onCustomAmountChange() {
		this.checkCustomInputFilled();
	}

	checkCustomInputFilled() {
		this.state.isCustomAmoutSet = (this.refs.customAmountInput.value !== '');
		const action = (this.state.isCustomAmoutSet ? 'add' : 'remove');

		this.refs.label.classList[ action ]('is-filled');
	}

	formatValue() {
		const amount = parseFloat(this.refs.customAmountInput.value);

		// eslint-disable-next-line no-restricted-globals
		if (isNaN(amount) || amount === 0) {
			this.refs.customAmountInput.value = '';
			this.refs.field.value = '';
		}
		else {
			this.refs.field.value = amount;
			this.refs.customAmountInput.value = amount.toFixed(2);
		}
	}
}
