import BaseView from '../../../js/base-view';

const VIDEO_CONTAINER_SELECTOR = '.video__video';
const VIDEO_WRAPPER_SELECTOR = '.video__wrapper';
const VIDEO_PLAY_ICON_SELECTOR = 'svg.video__play';
const IS_PLAYING_CLASS = 'is-playing';

export default class Video extends BaseView {
	init() {
		this.videoContainer = this.el.querySelector(VIDEO_CONTAINER_SELECTOR);
		this.videoWrapper = this.el.querySelector(VIDEO_WRAPPER_SELECTOR);
		this.video_url = this.el.getAttribute('data-video');
		this.video = this.parseVideo(this.video_url);
		this.bindEvents();
	}

	bindEvents() {
		this.on('click', VIDEO_WRAPPER_SELECTOR, this.handlePlay.bind(this));
	}

	handlePlay() {
		this.attachVideo();
		this.forceHideCustomPlayButton();
	}

	attachVideo() {
		this.el.classList.add(IS_PLAYING_CLASS);
		this.videoContainer.innerHTML = `<iframe src="${ this.video.embedUrl }" frameborder="0" allow="autoplay;fullscreen" autoplay allowfullscreen></iframe>`;
	}

	forceHideCustomPlayButton() {
		this.el.querySelector(VIDEO_PLAY_ICON_SELECTOR).style.opacity = 0;
	}

	/**
	 * Parses given url and returns object with usable information
	 *
	 * @param  {string} url The original video URL
	 * @return {Object}     Video id, provider and embedable url
	 *
	 * Supported YouTube URL formats
	 * - http://www.youtube.com/watch?v=My2FRPA3Gf8
	 * - http://youtu.be/My2FRPA3Gf8
	 * - https://youtube.googleapis.com/v/My2FRPA3Gf8
	 *
	 * Supported Vimeo URL formats
	 * - http://vimeo.com/25451551
	 * - http://player.vimeo.com/video/25451551
	 *
	 * Supported Infomaniak URL formats
	 * - https://player.vod2.infomaniak.com/embed/1jhvl2uqchsd3
	 *
	 * Also supports relative URLs
	 *  - //player.vimeo.com/video/25451551
	 */
	parseVideo(url) {
		const pattern = /https?:\/\/(player\.|www\.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com)|vod2\.infomaniak\.com)\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(&list=)?([A-Za-z0-9._%-]*)?(&\S+)?/;
		const matches = pattern.exec(url);

		const [, , source, , , id, , list] = matches;
		let provider = '';
		let embedUrl = '';

		if (matches !== null) {
			if (source.indexOf('youtu') > -1) {
				provider = 'youtube';
				embedUrl = `https://www.youtube.com/embed/${ id }?${ list && list.length ? `list=${ list }&` : '' }rel=0&showinfo=0&autoplay=1&color=white&controls=1`;
			}
			else if (source.indexOf('vimeo') > -1) {
				provider = 'vimeo';
				embedUrl = `https://player.vimeo.com/video/${ id }?autoplay=1`;
			}
			else if (source.indexOf('infomaniak') > -1) {
				provider = 'infomaniak';
				embedUrl = `https://player.vod2.infomaniak.com/embed/${ id }?autostart=1`;
			}
		}

		return {
			id,
			provider,
			embedUrl,
		};
	}
}
