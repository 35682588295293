import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { setTimeout } from 'core-js';

import BaseView from '../../../js/base-view';
import InputText from '../../atoms/inputs/1-input-text/input-text';

const SELECTOR_MODAL = '.modal-benefit';
const SELECTOR_CLOSE = '.modal-benefit__close';
const SELECTOR_CONTAINER = '.modal-benefit__container';
const SELECTOR_INNER = '.modal-benefit__inner';
const SELECTOR_TITLE = '.modal-benefit__content-title';
const SELECTOR_DESCRIPTION_WRAPPER = '.modal-benefit__content-descWrapper';
const SELECTOR_DESCRIPTION = '.modal-benefit__content-desc';
const SELECTOR_SEPARATOR = 'hr';
const SELECTOR_BUTTON = '.modal-benefit__btn';
const SELECTOR_FORM = '.modal-benefit__form';
const SELECTOR_FIELD = '[data-input-field]';

export default class ModalBenefit extends BaseView {

	init() {
		this.texts = JSON.parse(this.el.querySelector('#texts').innerHTML);

		this.refs = {
			modal: this.el.querySelector(SELECTOR_MODAL),
			close: this.el.querySelector(SELECTOR_CLOSE),
			container: this.el.querySelector(SELECTOR_CONTAINER),
			inner: this.el.querySelector(SELECTOR_INNER),
			title: this.el.querySelector(SELECTOR_TITLE),
			descWrapper: this.el.querySelector(SELECTOR_DESCRIPTION_WRAPPER),
			desc: this.el.querySelector(SELECTOR_DESCRIPTION),
			button: this.el.querySelector(SELECTOR_BUTTON),
			sep: this.el.querySelector(SELECTOR_SEPARATOR),
			form: this.el.querySelector(SELECTOR_FORM),
			field: new InputText(this.el.querySelector(SELECTOR_FIELD), 'text'),
			submit: this.el.querySelector(`${SELECTOR_FORM} button[type="submit"]`),
		};

		this.refs.field.init()

		this.state = {
			step: 'initial',
		}

		this.bindEvents();
	}

	bindEvents() {
		this.on('click', SELECTOR_MODAL, this.handleClick.bind(this));
		this.on('click', SELECTOR_CLOSE, this.close.bind(this));
		this.on('click', SELECTOR_BUTTON, this.onButtonClick.bind(this));
		this.on('submit', SELECTOR_FORM, this.onSubmit.bind(this))
		this.refs.field.on('keyup', this.onInputChange.bind(this));
		this.on('resizeFast', this.setScrollGradient.bind(this))
		this.on('resizeFast', this.setClipPath.bind(this))
		this.on('resize', this.moveCloseButton.bind(this))
	}

	handleClick(ev) {
		if(ev.delegateTarget !== ev.target) return;
		this.close();
	}

	setPosition(top = 0, left = 0, bottom = 0) {
		this.el.style.top = `${top}px`;
		this.el.style.left = `${left}px`;
		this.el.style.bottom = `${bottom}px`;
	}

	setPositionChangeCallback(callback = null) {
		if (typeof callback !== 'function') return false;

		if (this.changePosHandler) this.off('resizeFast', this.changePosHandler);

		this.changePosHandler = callback;
		this.on('resizeFast', this.changePosHandler);
		return true;
	}

	onButtonClick() {
		if (this.state.step === 'initial') {
			this.refs.button.setAttribute('aria-hidden', true)
			this.refs.form.setAttribute('aria-hidden', false)
			this.setClipPath();
			this.state.step = 'submit'
			this.refs.field.focus()
		}
		else if (this.state.step === 'success') {
			this.close();
		}
	}

	close() {
		this.el.setAttribute('aria-hidden', true);
		this.trigger('close', null, false);
		enableBodyScroll(this.refs.modal);
	}

	open() {
		disableBodyScroll(this.refs.modal);
		this.moveCloseButton()
		this.el.setAttribute('aria-hidden', false);

		setTimeout(() => {
			this.setScrollGradient();
			this.setClipPath();
		}, 0);
	}

	hide() {
		this.el.setAttribute('aria-hidden', true);
		this.refs.form.setAttribute('aria-hidden', true)
		this.refs.button.setAttribute('aria-hidden', false)
	}

	setClipPath() {
		const { width, height, top: containerTop } = this.refs.container.getBoundingClientRect()
		const { top: sepTop } = this.refs.sep.getBoundingClientRect()

		const curveH = 80
		const curveW = 16
		const curvePosY = sepTop - containerTop;
		const curveTopY = curvePosY - (curveH / 2);
		const curveBottomY = curvePosY + (curveH / 2);
		this.refs.container.style.clipPath = `path("M0,0 L${width},0 L${width},${curveTopY} C${width},${curveTopY + 25} ${width - curveW},${curveTopY + 25} ${width - curveW},${curvePosY} C${width - curveW},${curvePosY + 15}  ${width},${curvePosY + 15}  ${width},${curveBottomY} L${width},${height} L0,${height} L0,${curveBottomY} C0,${curvePosY + 15} ${curveW},${curvePosY + 15} ${curveW},${curvePosY} C${curveW},${curveTopY + 25} 0,${curveTopY + 25} 0,${curveTopY} L0,0")`
	}

	setScrollGradient() {
		const hasVerticalScrollbar = this.refs.desc.scrollHeight > this.refs.desc.clientHeight;

		if(hasVerticalScrollbar) {
			this.on('scroll', SELECTOR_DESCRIPTION_WRAPPER,this.handleContentScroll.bind(this));
		}
		else {
			this.off('scroll', SELECTOR_DESCRIPTION_WRAPPER,this.handleContentScroll.bind(this));
		}
		this.handleContentScroll();
	}

	handleContentScroll() {
		// top grad
		if(this.refs.desc.scrollTop > 0) {
			this.refs.descWrapper.classList.add('showGradTop');
		}
		else {
			this.refs.descWrapper.classList.remove('showGradTop');
		}
		// bottom grad
		if(this.refs.desc.scrollTop < this.refs.desc.scrollHeight - this.refs.desc.clientHeight) {
			this.refs.descWrapper.classList.add('showGradBottom');
		}
		else {
			this.refs.descWrapper.classList.remove('showGradBottom');
		}
	}

	onInputChange() {
		if (this.refs.field.getValue() !== '') this.refs.submit.removeAttribute('disabled');
		else this.refs.submit.setAttribute('disabled', 'disabled');
	}

	onSubmit(event) {
		event.preventDefault();

		if (this.refs.field.getValue() === '') return;

		fetch(this.refs.form.getAttribute('action'), {
			method: 'POST',
			body: new FormData(this.refs.form),
			credentials: 'same-origin',
			headers: new Headers({ 'X-Request-Ajax': true }),
		})
			.then((res) => res.json())
			.then((res) => {
				if (res.success) {
					this.refs.title.innerHTML = this.texts.title.success;
					this.refs.desc.innerHTML = `<p>${this.texts.description.success}</p>`;
					this.refs.button.firstElementChild.innerHTML = this.texts.button.success;
					this.refs.form.setAttribute('aria-hidden', true)
					this.refs.button.setAttribute('aria-hidden', false)
					this.setScrollGradient();
					this.setClipPath();
					this.state.step = 'success';
					this.trigger('success', null, false);
				}
				else {
					// eslint-disable-next-line no-lonely-if
					if (res.data.code) this.refs.field.setError(res.data.code);
					else throw new Error(res.data?.global ?? '');
				}
			})
			.catch((err) => {
				// TODO: Display error
				console.error(err);
			});
	}

	moveCloseButton() {
		if (this.viewport.getViewportWidth() < 768)
			this.el.insertAdjacentElement('afterbegin', this.refs.close);
		else
			this.refs.container.insertAdjacentElement('afterbegin', this.refs.close);
	}

	beforeDestroy() {
		this.refs.field.destroy();
		clearAllBodyScrollLocks();
	}
}
