/* eslint-disable operator-linebreak */
import BaseView from '../../../js/base-view';

const WRAPPER_SELECTOR = '.page-navigation__wrapper';
const LIST_SELECTOR = '.page-navigation__list';
const TOGGLE_SELECTOR_MOBILE = '.page-navigation__wrapper-header';
const TOGGLE_SELECTOR_DESKTOP = '.page-navigation__more';
const FILTER_MODIFIER_CLASS = 'page-navigation--filter';
const NAVBAR_SELECTOR = '.navbar';
const LINK_SELECTOR = '.page-navigation__list__item';

const NO_MORE_CLASS = 'no-more';
const IS_OPEN_CLASS = 'is-open';

export default class PageNavigation extends BaseView {
	/**
	 * Entrypoint
	 */
	init() {
		// refs
		// to keep reference of scoped dom elements
		this.refs = {
			wrapper: this.el.querySelector(WRAPPER_SELECTOR),
			wrapperHeader: this.el.querySelector(TOGGLE_SELECTOR_MOBILE),
			list: this.el.querySelector(LIST_SELECTOR),
			more: this.el.querySelector(TOGGLE_SELECTOR_DESKTOP),
			navBar: document.querySelector(NAVBAR_SELECTOR),
		};

		// state
		// the component's dynamic data representing its "state"
		this.state = {
			isOpen: false,
		};

		// attach events
		this.bindEvents();
		this.displayMore();
		this.setTabIndex();

		// TODO: to improve the init, add a "data-no-swup" attribute on all items that
		//       have an anchor link (= begins with '#')
		//       See https://github.com/swup/scroll-plugin/blob/55cddff08d1347eda051601c2ea0270c145a6cc5/src/index.js#L88
		//       NOTE: everything works fine for now because this component gets instanciated
		//             later than swup, and we use an event.preventDefault() here so swup doesn't
		//             get triggered. But if this changes in the future, there will be a conflict.

		// Allows the chaining
		return this;
	}

	/**
	 * Bind component's events
	 */
	bindEvents() {
		this.on('click', TOGGLE_SELECTOR_MOBILE, this.toggle.bind(this));
		this.on('click', TOGGLE_SELECTOR_DESKTOP, this.toggle.bind(this));
		this.on('keydown', TOGGLE_SELECTOR_DESKTOP, this.triggerClick.bind(this));
		this.on('resize', this.onResize.bind(this));
		this.on('click', LINK_SELECTOR, this.onLinkClick.bind(this));
	}

	// --------------------------------
	// #region Event Handlers
	// --------------------------------

	/**
	 * When we click on toggle button
	 */
	toggle() {
		if (! this.state.isOpen) {
			this.refs.wrapper.classList.add(IS_OPEN_CLASS);

			this.refs.more.lastElementChild.textContent = 'less';
			this.state.isOpen = ! this.state.isOpen;
			this.setTabIndex();
		}
		else {
			this.refs.wrapper.removeAttribute('style');
			this.refs.more.removeAttribute('style');
			this.refs.wrapper.classList.remove(IS_OPEN_CLASS);
			this.refs.more.lastElementChild.textContent = 'more';
			this.state.isOpen = ! this.state.isOpen;
			this.setTabIndex();
		}
	}

	/**
	 * Get the more button accessible
	 *
	 * @param {MouseEvent} event
	 */
	triggerClick(event) {
		if (event.code === 'Space' || event.code === 'Enter') {
			this.refs.more.click();
		}
	}

	/**
	 * When the viewport size changes
	 */
	onResize() {
		if (this.state.isOpen) {
			this.toggle();
		}
	}

	/**
	 * When we click on a navigation link
	 *
	 * @param {Event} event the original event
	 */
	onLinkClick(event) {
		// vars
		const el = event.target.attributes.href ? event.target : event.target.parentElement; // markup `a > span`
		const anchor = el.attributes.href.value;

		// bail early if link is not an anchor
		if (! anchor || ! anchor.length || anchor[ 0 ] !== '#') {
			return;
		}

		// prevent native event and scroll to the anchor
		event.preventDefault();
		this.scrollToAnchor(anchor);
	}

	// --------------------------------
	// #endregion
	// --------------------------------

	// --------------------------------
	// #region Actions
	// --------------------------------

	/**
	 * Hijacks the click-on-anchor behavior
	 * in order to scroll exactly where we want to
	 * (and not have the navbar cover the content we were supposed to scroll to)
	 *
	 * @param {string} anchor the anchor, with the '#' (ex: '#my-section')
	 */
	scrollToAnchor(anchor) {
		// vars
		const targetElement = document.querySelector(anchor);

		// bail early if target doesn't exist
		if (! targetElement) {
			return;
		}

		// scroll to target
		// TODO: smooth scrolling does not seem so smooth on Safari
		window.scroll({ top: targetElement.offsetTop, left: 0, behavior: 'smooth' });
		window.history.pushState(null, null, `${ anchor }`);
	}

	/**
	 * Calculate if we need to display the more button or not
	 */
	displayMore() {
		if (this.refs.list.children.length <= 7) {
			this.refs.wrapper.classList.add(NO_MORE_CLASS);
		}
	}

	/**
	 * Desactivate tab on hidden items
	 */
	setTabIndex() {
		if (this.el.classList[ 1 ] === FILTER_MODIFIER_CLASS) {
			Object.values(this.refs.list.children).forEach((key) => {
				if (key.offsetTop > 8 && ! this.state.isOpen) {
					key.setAttribute('tabindex', '-1');
				}
				else {
					key.removeAttribute('tabindex');
				}
			});
		}
	}

	// --------------------------------
	// #endregion
	// --------------------------------

	/**
	 * Before the component gets destroyed
	 * - unbind any event not bound with this.on()
	 * - reset UI if needed (any classes/attributes added?)
	 */
	beforeDestroy() {

	}
}
