import MySection from '../my-section/my-section';
import ModalBenefit from '../../molecules/modal-benefit/modal-benefit';

const SELECTOR_TABS_ITEM = '.benefits-list__tabs__item';
const SELECTOR_LIST = '.benefits-list__list';
const SELECTOR_BENEFIT_USE_BTN = '.card-benefit__btn';
const SELECTOR_MODAL = '.modal-benefit';
const CLASS_LIST_EMPTY = 'benefits-list__list--empty';

export default class SectionBenefits extends MySection {
	init() {
		this.state = {
			isLoading: false,
			selectedTab: this.el.querySelector(`${SELECTOR_TABS_ITEM}.is-selected`),
		}

		this.refs = {
			tabs: [...this.el.querySelectorAll(SELECTOR_TABS_ITEM)],
			list: this.el.querySelector(SELECTOR_LIST)
		}
		this.bindFormEvents();
		this.bindObserver();
		this.updateSelectedTabAttr();
	}

	fetchBenefitsList() {
		// Do not re-fetch if already requesting
		if (this.state.isLoading) return;
		this.state.isLoading = true;
		this.refs.list.classList.remove(CLASS_LIST_EMPTY);

		const fadeOutPromise = new Promise((res) => {
			this.refs.list.style.opacity = 0;
			setTimeout(res, 150);
		})

		const data = new FormData();
		data.append('action', 'mjf-fetch-benefits-list');
		data.append('type', this.state.selectedTab.getAttribute('rel'));
		fetch(document.location, {
			method: 'POST', // to pass over varnish
			body: data,
			credentials: 'same-origin',
			headers: new Headers({ 'X-Request-Ajax': true }),
		})
			.then((res) => res.json())
			.then(async (res) => {
				await fadeOutPromise;
				if (res.success === true) {
					this.refs.list.innerHTML = res.data.join('\n');
					if(!res.data.join('\n')) this.refs.list.classList.add(CLASS_LIST_EMPTY);
				}
				else throw Error('Error while fetching benefits');
			})
			.catch((error) => console.error(error))
			.finally(() => 	{
				this.state.isLoading = false
				this.refs.list.style.opacity = 1;
			})
	}


	bindFormEvents() {
		this.on('click', SELECTOR_TABS_ITEM, this.onTabChange.bind(this))
		this.on('click', SELECTOR_BENEFIT_USE_BTN, this.onUseBenefit.bind(this))

		this.on('success', SELECTOR_MODAL, this.fetchBenefitsList.bind(this))
		this.on('close', SELECTOR_MODAL, this.onModalClose.bind(this))
	}

	onTabChange({ target }) {
		if (this.state.selectedTab === target) return;

		if (this.state.selectedTab) this.state.selectedTab.classList.remove('is-selected')
		target.classList.add('is-selected')
		this.state.selectedTab = target

		this.updateSelectedTabAttr();
		this.fetchBenefitsList();
	}

	updateSelectedTabAttr(){
		this.refs.list.dataset.tab = this.state.selectedTab.dataset.tab;
	}

	bindObserver() {
		this.observer = new IntersectionObserver(
			this.onIntersect.bind(this), { threshold: 0 }
		);
		this.observer.observe(this.el);
	}

	onIntersect(entries) {
		entries.forEach(entry => {
			if (!entry.isIntersecting) return
			this.updateSelectedTabAttr();
			this.fetchBenefitsList()
			this.destroyObserver();
		})
	}

	destroyObserver() {
		if (!this.observer) return
		this.observer.disconnect();
		this.observer = null;
	}

	onUseBenefit({ target }) {
		const btn = target.closest(SELECTOR_BENEFIT_USE_BTN);

		const url = new URL(document.location);
		url.searchParams.append('action', 'mjf-fetch-modal-benefit')
		url.searchParams.append('benefit_id', btn.dataset.id)

		fetch(url.toString(), {
			method: 'GET', // to pass over varnish
			credentials: 'same-origin',
			headers: new Headers({ 'X-Request-Ajax': true }),
		})
			.then((res) => res.text())
			.then((res) => {
				this.el.insertAdjacentHTML('beforeend', res)
				this.refs.modal = new ModalBenefit(this.el.querySelector(SELECTOR_MODAL), 'modal-benefit');
				this.refs.modal.init();
				this.showModal();
			})
			.catch((error) => console.error(error))
	}

	showModal() {
		const setModalPosition = () => {
			const navbar = document.querySelector('.navbar');
			const { bottom } = navbar.getBoundingClientRect()
			const sideMenu = document.querySelector('.my-account-nav');
			const { right } = this.viewport.getViewportWidth() < 1200 ? 0 : sideMenu.getBoundingClientRect()
			const footer = document.querySelector('.page__footer');
			const footerPos = this.viewport.getViewportHeight() - footer.getBoundingClientRect().top > 0 ? this.viewport.getViewportHeight() - footer.getBoundingClientRect().top : 0;
			this.refs.modal.setPosition(Math.floor(bottom), Math.floor(right), Math.floor(footerPos));
		}

		setModalPosition();
		this.refs.modal.setPositionChangeCallback(setModalPosition);
		this.refs.modal.open();
	}

	onModalClose() {
		this.refs.modal.hide();
		// wait for fade out animation to be complete
		setTimeout(() => {
			this.el.removeChild(this.refs.modal.el);
			this.refs.modal.destroy();
			this.refs.modal = null
		}, 200);
	}

	beforeDestroy() {
		if (this.observer) this.destroyObserver()
	}

}
