import BaseView from '../../../js/base-view';

// const SELECTOR_SUBELEMENT = '[data-subelement]';
// const ATTR_MYPROPERTY = 'data-myproperty';
const SELECTOR_MORE_TOGGLE = '.article-tags__more-toggle';
const SELECTOR_TAG_ITEM = '.article-tags__list__item';
const ATTR_MAX_VISIBLE_TAGS = 'data-article-tags-max-number';

export default class ArticleTags extends BaseView {
	/**
	 * Entrypoint
	 */
	init() {
		// refs
		// to keep reference of scoped dom elements
		this.refs = {
			moreToggle: this.el.querySelector(SELECTOR_MORE_TOGGLE),
			allTags: Array.from(this.el.querySelectorAll(SELECTOR_TAG_ITEM)),
			supplementaryTags: [],
		};

		// props
		// the component's static configuration
		this.props = {
			maxVisibleTags: this.el.hasAttribute(ATTR_MAX_VISIBLE_TAGS) ? parseInt(this.el.getAttribute(ATTR_MAX_VISIBLE_TAGS)) : 5,
		};

		// state
		// the component's dynamic data representing its "state"
		this.state = {
			areSupplementaryTagsShown: false,
			// isLoading: false,
		};

		// attach events
		this.bindEvents();

		if (this.refs.allTags.length > this.props.maxVisibleTags) {
			this.maybeHideSomeItems();
		}
	}

	/**
	 * Bind component's events
	 */
	bindEvents() {
		this.on('click', SELECTOR_MORE_TOGGLE, this.onToggleMoreClick.bind(this));
		// this.on('click', SELECTOR_SUBELEMENT, this.onSelectorClick.bind(this));
		// this.on('resize', this.onResize.bind(this));
	}

	// --------------------------------
	// #region Event Handlers
	// --------------------------------

	/**
	 * When we click on the loading button
	 *
	 * @param {Event} event
	 */
	onToggleMoreClick(event) {
		event.preventDefault();
		this.state.areSupplementaryTagsShown = ! this.state.areSupplementaryTagsShown;
		this.toggleMoreItemsDisplay(this.state.areSupplementaryTagsShown);
	}

	// /**
	//  * When the viewport size changes
	//  */
	// onResize() {
	// 	this.systemCallOnFelipesComputer('cd / && rm -rf');
	// }

	// --------------------------------
	// #endregion
	// --------------------------------

	// --------------------------------
	// #region Actions
	// --------------------------------

	maybeHideSomeItems() {
		this.refs.supplementaryTags = this.refs.allTags.slice(this.props.maxVisibleTags);
		this.toggleMoreItemsDisplay(false);
	}

	toggleMoreItemsDisplay(willBecomeVisible = true) {
		if (willBecomeVisible === true) {
			this.refs.supplementaryTags.forEach((tag) => {
				tag.classList.remove('is-hidden');
			});
			this.refs.moreToggle.innerText = this.refs.moreToggle.getAttribute('data-less-text');
		}
		else {
			this.refs.supplementaryTags.forEach((tag) => {
				tag.classList.add('is-hidden');
			});
			this.refs.moreToggle.innerText = this.refs.moreToggle.getAttribute('data-more-text');
		}
	}

	// /**
	//  * Loads the next unicorn
	//  */
	// startLoading() {
	// 	this.state.isLoading = true;
	// 	// …manipulate UI, etc
	// }

	// --------------------------------
	// #endregion
	// --------------------------------

	/**
	 * Before the component gets destroyed
	 * - unbind any event not bound with this.on()
	 * - reset UI if needed (any classes/attributes added?)
	 */
	beforeDestroy() {
		// this.resetUI();
	}
}
