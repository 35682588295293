// Utils
// import smoothscroll from 'smoothscroll-polyfill';
import lineClamp from '../js/utils/line-clamp';

/**
 * Auto injected components Imports
 * ↓ please keep comments (starting with `// ===`) to keep the auto insertion working
 */
// === Imports ===
import ApWatch from './organisms/ap-watch/ap-watch';
import ArtistSearch from './molecules/artist-search/artist-search';
import ResponsiveVideo from './2-javascript/responsive-video/responsive-video';
import Programme from './organisms/programme/programme';
import Navigation from './organisms/navigation/navigation';
import MediaHeader from './wp-blocks/media-header/media-header';
import HomeHeader from './organisms/home-header/home-header';
import PageHeader from './organisms/page-header/page-header';
import Accordion from './molecules/accordion/accordion';
import ArticleTags from './molecules/article-tags/article-tags';
import PageNavigation from './organisms/page-navigation/page-navigation';
import HighlightedContent from './wp-blocks/highlighted-content/highlighted-content';
import ArticleBreaker from './wp-blocks/article-breaker/article-breaker';
import Loadmore from './2-javascript/loadmore/loadmore';
import Gallery from './wp-blocks/gallery/gallery';
import Columns from './wp-blocks/columns/columns';
import Breadcrumbs from './molecules/breadcrumbs/breadcrumbs';
import Button from './atoms/button/button';
import Form from './organisms/form/form';
import Video from './molecules/video/video';
import Timer from './molecules/timer/timer';

import PageMedia from './templates/page/page-media';

import UdNav from './user-dashboard/ud-nav/ud-nav';
import UserDashboard from './user-dashboard/user-dashboard';

// === Loader Object ===
const COMPONENTS_TO_LOAD = [
	{ view: ApWatch, name: 'ApWatch', selector: '[data-ap-watch]' },
	{
		view: ArtistSearch,
		name: 'ArtistSearch',
		selector: '[data-artist-search]',
	},
	{
		view: ResponsiveVideo,
		name: 'ResponsiveVideo',
		selector: '[data-responsive-video]',
	},
	{ view: Programme, name: 'Programme', selector: '[data-programme]' },
	{ view: Navigation, name: 'Navigation', selector: '[data-navigation]' },
	{ view: MediaHeader, name: 'MediaHeader', selector: '[data-media-header]' },
	{ view: HomeHeader, name: 'HomeHeader', selector: '[data-home-header]' },
	{ view: PageHeader, name: 'PageHeader', selector: '[data-page-header]' },
	{
		view: HighlightedContent,
		name: 'HighlightedContent',
		selector: '[data-highlighted-content]',
	},
	{
		view: PageNavigation,
		name: 'PageNavigation',
		selector: '[data-page-navigation]',
	},
	// { view: Gallery, name: 'Gallery', selector: '[data-gallery]' },
	{
		view: ArticleBreaker,
		name: 'ArticleBreaker',
		selector: '[data-article-breaker]',
	},
	{ view: Loadmore, name: 'Loadmore', selector: '[data-loadmore]' },
	{ view: ArticleTags, name: 'ArticleTags', selector: '[data-article-tags]' },
	// { view: Loadmore, name: 'Loadmore', selector: '[data-loadmore]' },
	{ view: Columns, name: 'Columns', selector: '[data-supt-columns]' },
	{ view: Accordion, name: 'Accordion', selector: '[data-accordion]' },
	{ view: Gallery, name: 'Gallery', selector: '[data-gallery]' },
	{ view: Breadcrumbs, name: 'Breadcrumbs', selector: '[data-breadcrumbs]' },
	{ view: Button, name: 'Button', selector: '[data-button]' },
	{ view: Form, name: 'Form', selector: '[data-form]' },
	{ view: Video, name: 'Video', selector: '[data-video]' },
	{ view: PageMedia, name: 'PageMedia', selector: '[data-page-media-content]' },
	{ view: UdNav, name: 'UdNav', selector: '.ud-nav' },
	{ view: UserDashboard, name: 'UserDashboard', selector: '.page--user-dashboard' },
	// to load after other components
	{ view: Timer, name: 'Timer', selector: '[data-timer]' },
];

// eslint-disable-next-line prefer-const
let LOADED_COMPONENTS = {};

const LOAD_ONCE_COMPONENTS = ['Navigation'];

// window.components = LOADED_COMPONENTS;

// Execute utilities before components loading
lineClamp();

/**
 * Load all the components.
 * All the component are builded using the same interface, so...
 * the .bind method does the magic for everyone.
 */
export function componentsLoader() {
	for (let i = 0; i < COMPONENTS_TO_LOAD.length; i++) {
		const { selector, view, name } = COMPONENTS_TO_LOAD[i];
		const element = document.querySelectorAll(selector);
		// Only if the DOM element exist
		// we load the view.
		if (element && element.length) {
			for (let j = 0; j < element.length; j++) {
				// Create the view instance
				// and push the view in the loaded view cache object.
				if (name + j in LOADED_COMPONENTS) {
					// eslint-disable-next-line no-continue
					continue;
				}

				let v;
				try {
					// eslint-disable-next-line new-cap
					v = new view(element[j], name);
					// TODO: try/catch
					v.init();
				}
 				catch (error) {
					console.error('Could not init view:', name, '⬇️');
					console.error(error);
				}

				LOADED_COMPONENTS[name + j] = v;
			}
		}
		lineClamp();
	}

	// DEBUG: Exposes the loaded views for dev purposes
	// window.COMPONENTS = LOADED_COMPONENTS;

	return LOADED_COMPONENTS;
}

export function componentsDestroy() {
	Object.keys(LOADED_COMPONENTS).forEach((key) => {
		const destroy = !LOAD_ONCE_COMPONENTS.includes(LOADED_COMPONENTS[key].name);

		if (destroy) {
			LOADED_COMPONENTS[key].destroy();
			delete LOADED_COMPONENTS[key];
		}
	});
	// DEBUG: Exposes the loaded views for dev purposes
	// window.COMPONENTS = LOADED_COMPONENTS;
	return LOADED_COMPONENTS;
}

/**
 * Smooth Scroll behavior polyfill
 * https: //caniuse.com/#search=scrollTo
 * https: //github.com/iamdustan/smoothscroll
 */
// smoothscroll.polyfill();
