import BaseInput from '../_0-input/input';

const SELECTOR_FIELD = '.input-select__field';

const CLASS_SELECTED = 'is-selected';

export default class InputSelect extends BaseInput {
	init() {
		super.init(SELECTOR_FIELD, true);
		this.toggleSelected();

		this.state.value = this.refs.field[ this.refs.field.selectedIndex ];
		this.triggerChange();
	}

	bindEvents() {
		this.on('change', SELECTOR_FIELD, this.handleSelectChange.bind(this));
	}

	handleSelectChange() {
		this.toggleSelected();
		this.triggerChange();
		this.resetError();
	}

	checkCondition(name, value) {
		// Trigger a new change if the condition state has changed
		if (super.checkCondition(name, value)) {
			this.triggerChange();
		}
	}

	toggleSelected() {
		const index = this.refs.field.selectedIndex;
		this.state.isSelected = index > 0;
		this.state.value = this.refs.field[ index ].value;

		this.el.classList[ this.state.isSelected ? 'add' : 'remove' ](CLASS_SELECTED);
	}

	triggerChange() {
		const value =
		this.state.isDisabled ? null : this.refs.field[ this.refs.field.selectedIndex ].value;
		this.trigger('input:change', { detail: { name: this.props.name, value } }, false);
	}

	reset() {
		super.reset();

		this.state.isSelected = false;
		this.refs.field.selectedIndex = 0;
		this.el.classList.remove(CLASS_SELECTED);
	}

	setValue(value) {
		// eslint-disable-next-line eqeqeq
		const indexToSelected = [...this.refs.field.options].findIndex((opt) => opt.value == value)
		if (indexToSelected > 0) {
			this.refs.field.selectedIndex = indexToSelected
			this.refs.field.dataset.value = value
		}
	}
}
