import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { TimelineLite, TweenLite, CSSPlugin, Power0, Power2 } from 'gsap/all';
import { CustomEase } from '../../../js/utils/gsap/CustomEase';
import { DrawSVGPlugin } from '../../../js/utils/gsap/DrawSVGPlugin';
import BaseView from '../../../js/base-view';
import NavScrollhandler from './navigation-scroll';

// ensure gsap plugins don't get dropped by tree-shaking
// eslint-disable-next-line no-unused-vars
const plugins = [CSSPlugin, CustomEase, DrawSVGPlugin];

const SELECTOR_BACKGROUND = '.nav__background';
const SELECTOR_NAVBAR = '.navbar';
const SELECTOR_NB_TOP = '.navbar__top';
const SELECTOR_NB_TOP_BACKGROUND = '.navbar__top__background';
const SELECTOR_NB_MAIN_INNER = '.navbar__main__inner';
const SELECTOR_NB_MAIN_BACKGROUND = '.navbar__background';
const SELECTOR_NB_MENU = '.navbar__menu';
const SELECTOR_NB_LOGO = '.navbar__logo';
const SELECTOR_NB_PROGRAM = '.navbar__program';
const SELECTOR_NB_TOGGLE = '.navbar__toggle';
const SELECTOR_NB_FESTDATE = '.navbar__inner__festdate';
const SELECTOR_NB_TOGGLE_MENU = '.navbar__icon--menu';
const SELECTOR_NB_TOGGLE_BACK = '.navbar__icon--back';
const SELECTOR_NB_TOGGLE_CLOSE = '.navbar__icon--close';
const SELECTOR_NB_MENU_ITEM = '.navbar__menu__item';
const SELECTOR_NB_MENU_ITEM_LINK = '.navbar__menu__item .item__content';
const SELECTOR_DROPDOWN = '.dropdown';
const SELECTOR_DROPDOWN_OVERLAY = '.dropdown__menu__overlay';
const SELECTOR_DROPDOWN_CARD = '.dropdown__cards .card-cta';
// const SELECTOR_SUBMENU = '.submenu';
const SELECTOR_SUBMENU_ACTIVE = '.submenu--active';
const CLASS_BODY_PARTNER = 'page--partner';
const CLASS_NAV_MOBILE = 'nav--mobile';
const CLASS_NAV_PROGRAM = 'nav--program';
const CLASS_NAV_OPENED = 'nav--opened';
const CLASS_NAV_HOVERED = 'nav--hovered';
const CLASS_DROPDOWN_OPENED = 'nav--dropdown-opened';
const CLASS_MENU_ITEM_ACTIVE = 'is-active';
const CLASS_SUBMENU_ACTIVE = 'submenu--active';

// Size Values
// navbar menu
const NB_MENU_DESKTOP = 112;
const NB_MENU_DESKTOP_BIG = 153;
const NB_MENU_MOBILE = 80;
const NB_MENU_MOBILE_BIG = 92;
// navbar logo
const NB_LOGO_DESKTOP = 80;
const NB_LOGO_DESKTOP_BIG = 96;
const NB_LOGO_MOBILE = 64;
const NB_LOGO_MOBILE_BIG = 68;
// navbar toggle
const NB_TOGGLE = 35;
const NB_TOGGLE_MARGIN = 25;

// Transition Duration Values (s)
const LOAD_DURATION = 0.65;
const MINIMIZE_DURATION = 0.45;
const TOGGLE_DURATION = 0.2;

export default class Navigation extends BaseView {
	/**
	 * Entrypoint
	 */
	init() {
		// refs
		// to keep reference of scoped dom elements
		this.refs = {
			navbar: this.el.querySelector(SELECTOR_NAVBAR),
			nbMainInner: this.el.querySelector(SELECTOR_NB_MAIN_INNER),
			nbMenu: this.el.querySelector(SELECTOR_NB_MENU),
			nbLogo: this.el.querySelector(SELECTOR_NB_LOGO),
			nbProgram: this.el.querySelector(SELECTOR_NB_PROGRAM),
			dropdown: this.el.querySelector(SELECTOR_DROPDOWN),
			links: this.el.querySelectorAll('a:not([target=_blank])'),
		};

		// props
		// the component's static configuration
		this.props = {
			// myProperty: this.refs.subElement.getAttribute(ATTR_MYPROPERTY),
		};

		// state
		// the component's dynamic data representing its "state"
		this.state = {
			nbMenuWidth: 0,
			isMobileMode: false,
			isSmallMode: false,
			isMenuOpened: false,
			isDropdownOpened: false,
			isPageScrolled: false,
			isMinimized: true,
			isProgramAvailable: false,
			currentSubmenuId: '',
		};

		this.cache = {
			currentMenuItem: null,
			oldSubmenu: null,
			currentSubmenu: null,
			tlMenu: new TimelineLite(),
			tlPartner: new TimelineLite(),
			tlMenuUpdate: new TimelineLite(),
			tlMinimize: new TimelineLite(),
			tlSubmenu: new TimelineLite(),
		};

		// attach events
		this.ScrollHandler = NavScrollhandler(this.el);
		this.setupNavbar();
		this.bindEvents();
	}

	/**
	 * Bind component's events
	 */
	bindEvents() {
		this.menuToggleHandler = this.onToggleMenu.bind(this);
		this.menuHoverHandler = this.onHoverMenu.bind(this);
		this.updateStateHandler = this.updateState.bind(this);
		this.loadAnimationHandler = this.loadAnimation.bind(this);
		this.on('resizeFast', this.updateNav.bind(this));
		this.on('minimize', (ev) => this.onNavbarMinimize(ev));
		this.on('click', SELECTOR_NB_TOGGLE, this.menuToggleHandler);
		this.on('click', SELECTOR_NB_MENU_ITEM_LINK, this.onNavbarClick.bind(this));
		this.on('mouseover', SELECTOR_NB_MENU_ITEM_LINK, this.menuHoverHandler);
		this.on('mouseleave', SELECTOR_NB_MENU_ITEM_LINK, this.menuHoverHandler);
		this.on('focus', SELECTOR_NB_MENU_ITEM, this.menuHoverHandler);
		this.on('blur', SELECTOR_NB_MENU_ITEM, this.menuHoverHandler);
		document.addEventListener('swup:contentReplaced', this.updateStateHandler);
		window.addEventListener('mediaLoaded', this.loadAnimationHandler);
		this.on('keypress', SELECTOR_NB_MENU_ITEM_LINK, (ev) => {
			if (ev.key === 'Enter') {
				ev.target.click();
			}
		});
	}

	// --------------------------------
	// #region Event Handlers
	// --------------------------------

	// /**
	//  * Setup navbar
	//  */
	setupNavbar() {
		this.state.isProgramAvailable =
			this.el.classList.contains(CLASS_NAV_PROGRAM);
		this.updateState();
		this.updateNav();

		if (!this.state.isPageScrolled) {
			this.state.isMinimized = false;
			this.growAnimation(false);
		}
	}

	// /**
	//  * Update State Navbar
	//  */
	updateState() {
		this.state.isPartner = document.body.classList.contains(CLASS_BODY_PARTNER);
		if (this.state.isPartner) {
			this.cache.tlPartner
				.clear()
				.set(
					[SELECTOR_NB_MAIN_BACKGROUND, SELECTOR_NB_TOP_BACKGROUND],
					{
						css: {
							backgroundColor: '#EFEFEF',
						},
					},
					0
				)
				.set(
					SELECTOR_NB_TOP,
					{
						css: {
							borderBottomColor: '#FFFFFF',
						},
					},
					0
				)
				.play();
		}
		else {
			this.cache.tlPartner
				.clear()
				.set(
					[SELECTOR_NB_MAIN_BACKGROUND, SELECTOR_NB_TOP_BACKGROUND],
					{
						css: {
							backgroundColor: '#FFFFFF',
						},
					},
					0
				)
				.set(
					SELECTOR_NB_TOP,
					{
						css: {
							borderBottomColor: '#DEDEDE',
						},
					},
					0
				)
				.play();
		}
	}

	// /**
	//  * Update navbar
	//  */
	updateNav() {
		this.updateMobileMode();
		this.updateSmallMode();
		if (this.state.isMobileMode && this.state.isMenuOpened) {
			this.getNavbarMenuHeight();
			if (this.state.isDropdownOpened) {
				const offsetTopItem = Math.floor(this.cache.currentMenuItem.offsetTop);
				// TODO Fix Animation
				TweenLite.set(`${SELECTOR_NB_MENU_ITEM}[aria-expanded="true"]`, {
					css: {
						y: `-${offsetTopItem}`,
					},
				});
			}
		}
	}

	// /**
	//  * Minimize Navbar
	//  */
	onNavbarMinimize(ev) {
		this.state.isPageScrolled = ev.detail;

		if (this.state.isPageScrolled) {
			if (!this.state.isMinimized) {
				this.state.isMinimized = true;
				this.shrinkAnimation();
			}
		}
		else if (this.state.isMinimized) {
			this.state.isMinimized = false;
			this.growAnimation();
		}
	}

	// /**
	//  * Hover Menu
	//  */
	onHoverMenu(ev) {
		if (ev.type === 'mouseover' || ev.type === 'focus') {
			this.el.classList.add(CLASS_NAV_HOVERED);

			if (this.cache.currentMenuItem) {
				this.cache.currentMenuItem.children[0].blur();
			}
		}
		else {
			this.el.classList.remove(CLASS_NAV_HOVERED);
		}
	}

	// /**
	//  * on menu toggle
	//  */
	onToggleMenu() {
		if (this.state.isMenuOpened) {
			if (this.state.isMobileMode) {
				if (this.state.isDropdownOpened) {
					this.closeSubmenuAnmMobile();
					this.cleanActiveSubmenu();
				}
				else {
					this.closeMenuAnmMobile();
					this.toggleMenu(false);
				}
			}
			else {
				this.closeMenuAnmDesktop();
				this.cleanActiveSubmenu();
				this.toggleMenu(false);
			}
		}
		else {
			this.toggleMenu(true);
			this.openMenuAnmMobile();
		}
	}

	// /**
	//  * on navbar menu click
	//  */
	onNavbarClick(ev) {
		const target = ev.delegateTarget.parentElement;
		// check if menu item has submenu
		if (
			target.getAttribute('aria-haspopup') === 'false' ||
			!target.getAttribute('aria-haspopup')
		) {
			return;
		}
		// if menu opened
		if (this.state.isMenuOpened) {
			// if active submenu opened
			if (target.getAttribute('aria-expanded') === 'true') {
				if (this.state.isMobileMode) {
					this.closeSubmenuAnmMobile();
				}
				else {
					this.closeMenuAnmDesktop();
				}

				this.cleanActiveSubmenu();
				if (!this.state.isMobileMode) {
					this.toggleMenu(false);
				}
			}
			// if active submenu closed
			else {
				if (this.state.isDropdownOpened) {
					this.cleanActiveSubmenu();
				}
				this.updateActiveSubmenu(target);
				if (this.state.isMobileMode) {
					this.openSubmenuAnmMobile();
				}
				else {
					this.updateSubmenuAnmDesktop();
				}
			}
		}
		// if menu closed
		else {
			this.toggleMenu(true);
			this.updateActiveSubmenu(target);
			if (!this.state.isMobileMode) {
				this.openMenuAnmDesktop();
			}
		}
	}

	// --------------------------------
	// #endregion
	// --------------------------------

	// --------------------------------
	// #region Actions
	// --------------------------------

	// /**
	//  * Get Navbar Menu Height
	//  */
	getNavbarMenuHeight() {
		this.cache.navbarMenuItemHeight = Math.floor(
			this.refs.nbMenu.querySelector(SELECTOR_NB_MENU_ITEM).offsetHeight
		);
		this.el.style.setProperty(
			'--navbar-menu-item-height',
			`${this.cache.navbarMenuItemHeight}px`
		);
	}

	// /**
	//  * Check if navbar menu has enough space to be displayed
	//  */
	updateMobileMode() {
		if (!this.state.isMobileMode) {
			this.state.nbMenuWidth = this.refs.nbMenu.offsetWidth;
		}
		const nbMainInnerWidth = this.refs.nbMainInner.offsetWidth;
		const nbProgramWidth = this.refs.nbProgram?.offsetWidth ?? 0;
		let nbToggleWidth = NB_TOGGLE;
		if (nbProgramWidth > 0) {
			nbToggleWidth += NB_TOGGLE_MARGIN;
		}
		const menuFits =
			this.state.nbMenuWidth +
				NB_LOGO_DESKTOP +
				nbProgramWidth +
				nbToggleWidth <
			nbMainInnerWidth;

		// if menu mode didn't change
		if (!menuFits === this.state.isMobileMode) {
			return;
		}
		// if menu mode changes
		// Desktop
		if (menuFits) {
			this.el.classList.remove(CLASS_NAV_MOBILE);
			this.state.isMobileMode = false;
			// close menu
			if (this.state.isMenuOpened) {
				if (this.state.isDropdownOpened) {
					this.closeSubmenuAnmMobile(false);
					this.cleanActiveSubmenu();
				}
				this.closeMenuAnmMobile(false);
				this.toggleMenu(false);
			}
			// setup desktop
			this.cache.tlMenu
				.set(SELECTOR_NB_MENU, { css: { display: "flex", visibility: "visible" } })
				.set(SELECTOR_NB_MENU_ITEM, { css: { opacity: 1, y: 0 } })
				.set(SELECTOR_NB_TOGGLE, { css: { display: 'none' } })
				.set(SELECTOR_NB_TOGGLE_CLOSE, { css: { opacity: 1 } })
				.set(`${SELECTOR_NB_TOGGLE_CLOSE} path`, { drawSVG: '100%' })
				.set(SELECTOR_NB_TOGGLE_MENU, { css: { opacity: 0 } })
				.play();
		}
		// Mobile
		else {
			this.el.classList.add(CLASS_NAV_MOBILE);
			this.state.isMobileMode = true;
			// close menu
			if (this.state.isMenuOpened) {
				this.closeMenuAnmDesktop(false);
				this.cleanActiveSubmenu();
				this.toggleMenu(false);
			}
			// setup mobile
			this.cache.tlMenu
				// can't be display none, because we need to call getNavbarMenuHeight before the opening animation starts
				.set(SELECTOR_NB_MENU, {
					css: { display: 'block', visibility: 'hidden', y: 0 },
				})
				.set(SELECTOR_NB_TOGGLE, { css: { display: 'block' } })
				.set(SELECTOR_NB_TOGGLE_CLOSE, { css: { opacity: 0 } })
				.set(SELECTOR_NB_TOGGLE_MENU, { css: { opacity: 1 } })
				.set(`${SELECTOR_NB_TOGGLE_MENU} path`, { drawSVG: '100%' })
				.play();
		}

		// Update size of Nav
		if (this.state.isPageScrolled) {
			this.shrinkAnimation();
		}
		else {
			this.growAnimation();
		}
	}

	// /**
	//  * Check if screen under 768
	//  */
	updateSmallMode() {
		// if mode didn't change
		if (this.state.isSmallMode === viewport_service.getViewportWidth() < 768) {
			return;
		}

		this.state.isSmallMode = viewport_service.getViewportWidth() < 768;

		// under 768
		if (this.state.isSmallMode) {
			// if menu closed
			if (this.state.isMenuOpened) {
				TweenLite.set(SELECTOR_NB_TOP, { css: { display: 'flex' } });
			}
			else {
				TweenLite.set(SELECTOR_NB_TOP, { css: { display: 'none' } });
				TweenLite.set(SELECTOR_NB_PROGRAM, { css: { display: 'none' } });
			}
		}
		// above 768
		else {
			TweenLite.set(SELECTOR_NB_TOP, { css: { display: 'flex' } });
			if (this.state.isProgramAvailable) {
				TweenLite.set(SELECTOR_NB_PROGRAM, { css: { display: 'block' } });
			}
		}

		// Update size of Nav to fix translations
		if (
			this.state.isPageScrolled ||
			this.state.isMinimized ||
			this.state.isMenuOpened
		) {
			this.shrinkAnimation();
		}
		else {
			this.growAnimation();
		}
	}

	// /**
	//  * Open/Close menu
	//  */
	toggleMenu(open) {
		if (open) {
			this.el.classList.add(CLASS_NAV_OPENED);
			this.state.isMenuOpened = true;
			this.refs.links.forEach((link) =>
				link.setAttribute('data-swup-transition', 'menu-transition')
			);
		}
		else {
			this.el.classList.remove(CLASS_NAV_OPENED);
			this.state.isMenuOpened = false;
			this.refs.links.forEach((link) =>
				link.removeAttribute('data-swup-transition')
			);
		}
	}

	// /**
	//  * Update Menu item and submenu opened
	//  */
	updateActiveSubmenu(newMenu) {
		this.el.classList.add(CLASS_DROPDOWN_OPENED);
		this.state.isDropdownOpened = true;
		this.cache.currentMenuItem = newMenu;
		this.cache.currentMenuItem.setAttribute('aria-expanded', 'true');
		this.cache.currentMenuItem.classList.add(CLASS_MENU_ITEM_ACTIVE);
		this.state.currentSubmenuId =
			this.cache.currentMenuItem.getAttribute('aria-controls');
		this.cache.currentSubmenu = document.getElementById(
			this.state.currentSubmenuId
		);
		this.cache.currentSubmenu.classList.add(CLASS_SUBMENU_ACTIVE);
	}

	// /**
	//  * Clean Menu item and links opened
	//  */
	cleanActiveSubmenu() {
		this.el.classList.remove(CLASS_DROPDOWN_OPENED);
		this.state.isDropdownOpened = false;
		this.cache.currentMenuItem.setAttribute('aria-expanded', 'false');
		this.cache.currentMenuItem.classList.remove(CLASS_MENU_ITEM_ACTIVE);
		this.cache.currentMenuItem.children[0].blur();
		this.cache.currentMenuItem = null;
		this.state.currentSubmenuId = null;
		this.cache.currentSubmenu.classList.remove(CLASS_SUBMENU_ACTIVE);
		this.cache.oldSubmenu = this.cache.currentSubmenu;
		this.cache.currentSubmenu = null;
	}

	// --------------------------------
	// #endregion
	// --------------------------------

	// --------------------------------
	// #region Animations
	// --------------------------------

	/**
	 * Load Navbar Animation
	 */

	loadAnimation() {
		const loadDuration = this.state.isMinimized ? 0 : LOAD_DURATION;
		const tlLoad = new TimelineLite();
		tlLoad
			.kill()
			.clear()
			.to(
				this.el,
				loadDuration,
				{
					ease: Power0.easeNone,
					css: {
						opacity: 1,
					},
				},
				0
			)
			.play();
	}

	/**
	 * Shrink Navbar Animation
	 *
	 * @param {boolean }animate
	 */
	shrinkAnimation(animate = true) {
		const speed = animate ? 1 : 0;
		const speedify = (seconds) => speed * seconds;

		const tweenDuration = speedify(MINIMIZE_DURATION);

		this.cache.tlMinimize
			.clear()
			.set(
				SELECTOR_NB_MAIN_BACKGROUND,
				{
					css: {
						opacity: 1,
					},
				},
				0
			)
			.to(
				SELECTOR_NB_MAIN_BACKGROUND,
				tweenDuration,
				{
					ease: CustomEase.create('custom', '0.34, 0, 0.63, 1'),
					css: {
						transformOrigin: 'left top',
						scaleY: 1,
					},
				},
				0
			)
			.to(
				SELECTOR_NB_LOGO,
				tweenDuration,
				{
					ease: CustomEase.create('custom', '0.3, 0, 0.11, 1'),
					css: {
						transformOrigin: 'top',
						scale: 1,
					},
				},
				0
			)
			.to(
				[SELECTOR_NB_LOGO, SELECTOR_NB_TOGGLE, SELECTOR_NB_FESTDATE, SELECTOR_NB_PROGRAM],
				tweenDuration,
				{
					ease: CustomEase.create('custom', '0.3, 0, 0.11, 1'),
					css: {
						transformOrigin: 'left center',
						y: 0,
					},
				},
				0
			)
			.play();

		if (!this.state.isMobileMode) {
			this.cache.tlMinimize.to(
				[SELECTOR_NB_MENU],
				tweenDuration,
				{
					ease: CustomEase.create('custom', '0.3, 0, 0.11, 1'),
					css: {
						transformOrigin: 'left center',
						y: 0,
					},
				},
				0
			);
		}

		if (this.state.isSmallMode) {
			this.cache.tlMinimize.set(
				SELECTOR_NB_TOP_BACKGROUND,
				{
					css: {
						opacity: 0,
					},
				},
				0
			);
		}
		else {
			this.cache.tlMinimize.set(
				SELECTOR_NB_TOP_BACKGROUND,
				{
					css: {
						opacity: 1,
					},
				},
				0
			);

			if (this.state.isProgramAvailable) {
				this.cache.tlMinimize.to(
					SELECTOR_NB_PROGRAM,
					tweenDuration,
					{
						ease: CustomEase.create('custom', '0.3, 0, 0.11, 1'),
						css: {
							transformOrigin: 'left center',
							y: 0,
						},
					},
					0
				);
			}
		}

		if (
			this.state.isPartner &&
			this.state.isPageScrolled &&
			!this.state.isMenuOpened &&
			!this.state.isSmallMode
		) {
			this.cache.tlMinimize
				.to(
					[SELECTOR_NB_MAIN_BACKGROUND, SELECTOR_NB_TOP_BACKGROUND],
					tweenDuration,
					{
						css: {
							backgroundColor: '#FFFFFF',
						},
					},
					0
				)
				.to(
					SELECTOR_NB_TOP,
					tweenDuration,
					{
						css: {
							borderBottomColor: '#DEDEDE',
						},
					},
					0
				);
		}
	}

	/**
	 * Grow Navbar Animation
	 *
	 * @param {boolean} animate
	 * @param {boolean} pageTransition
	 * @param {boolean} hideBackground
	 */
	growAnimation(animate = true, pageTransition = false, hideBackground = true) {
		if (this.state.isPageScrolled && !pageTransition) {
			return;
		}

		if (this.state.isPartner) {
			// eslint-disable-next-line no-param-reassign
			hideBackground = false;
		}

		const speed = animate ? 1 : 0;
		const speedify = (seconds) => speed * seconds;

		const tweenDuration = speedify(MINIMIZE_DURATION);

		let tyInner = (NB_MENU_DESKTOP_BIG - NB_MENU_DESKTOP) / 2;
		let sBackground = NB_MENU_DESKTOP_BIG / NB_MENU_DESKTOP;
		let sLogo = NB_LOGO_DESKTOP_BIG / NB_LOGO_DESKTOP;

		if (this.state.isMobileMode) {
			tyInner = (NB_MENU_MOBILE_BIG - NB_MENU_MOBILE) / 2;
			sBackground = NB_MENU_MOBILE_BIG / NB_MENU_MOBILE;
			sLogo = NB_LOGO_MOBILE_BIG / NB_LOGO_MOBILE;
		}

		this.cache.tlMinimize
			.clear()
			.to(
				SELECTOR_NB_MAIN_BACKGROUND,
				tweenDuration,
				{
					ease: CustomEase.create('custom', '0.34, 0, 0.63, 1'),
					css: {
						transformOrigin: '0px 0px 0px',
						scaleY: sBackground,
					},
				},
				0
			)
			.to(
				SELECTOR_NB_LOGO,
				tweenDuration,
				{
					ease: CustomEase.create('custom', '0.34, 0, 0.08, 1'),
					css: {
						transformOrigin: 'top',
						scale: sLogo,
					},
				},
				0
			)
			.to(
				[SELECTOR_NB_LOGO, SELECTOR_NB_TOGGLE, SELECTOR_NB_FESTDATE, SELECTOR_NB_PROGRAM],
				tweenDuration,
				{
					ease: CustomEase.create('custom', '0.34, 0, 0.08, 1'),
					css: {
						transformOrigin: 'left center',
						y: tyInner,
					},
				},
				0
			)
			.play();

		if (hideBackground) {
			this.cache.tlMinimize
				.set(SELECTOR_NB_MAIN_BACKGROUND, {
					css: {
						opacity: 0,
					},
				})
				.set(SELECTOR_NB_TOP_BACKGROUND, {
					css: {
						opacity: 0,
					},
				});
		}

		if (!this.state.isMobileMode) {
			this.cache.tlMinimize.to(
				[SELECTOR_NB_MENU],
				tweenDuration,
				{
					ease: CustomEase.create('custom', '0.34, 0, 0.08, 1'),
					css: {
						transformOrigin: 'left center',
						y: tyInner,
					},
				},
				0
			);
		}

		if (this.state.isPartner) {
			this.cache.tlMinimize
				.to(
					[SELECTOR_NB_MAIN_BACKGROUND, SELECTOR_NB_TOP_BACKGROUND],
					tweenDuration,
					{
						css: {
							backgroundColor: '#EFEFEF',
						},
					},
					0
				)
				.to(
					SELECTOR_NB_TOP,
					tweenDuration,
					{
						css: {
							borderBottomColor: '#FFFFFF',
						},
					},
					0
				);
		}
	}

	// Desktop Animations
	/**
	 * Open Menu Animation
	 *
	 * @param {boolean} animate
	 */
	openMenuAnmDesktop(animate = true) {
		const speed = animate ? 1 : 0;
		const speedify = (seconds) => speed * seconds;

		const bgDuration = 0.45;
		const bgDelay = this.state.isMinimized ? 0 : 0.2;
		const ddDuration = 0.9;
		const cardDuration = 0.3;
		const waveDuration = 0.35;
		const waveDelay = 0.2;
		const closeMenuAnmDesktopActive = this.cache.tlMenu.isActive();

		this.cache.tlMenu
			.clear()
			.set(
				SELECTOR_BACKGROUND,
				{
					css: {
						height: '100%',
					},
				},
				0
			)
			.set(
				`${SELECTOR_DROPDOWN_CARD}:not(:first-child)`,
				{
					css: {
						y: 40,
					},
				},
				0
			)
			.set(
				SELECTOR_DROPDOWN_CARD,
				{
					css: {
						opacity: 0,
					},
				},
				0
			)
			// toggle
			.set(
				SELECTOR_NB_TOGGLE,
				{
					css: {
						display: 'block',
					},
				},
				0
			)
			.set(
				SELECTOR_NB_TOGGLE_MENU,
				{
					css: {
						opacity: 0,
					},
				},
				0
			)
			.set(
				SELECTOR_NB_TOGGLE_CLOSE,
				{
					css: {
						opacity: 1,
					},
				},
				0
			)
			.fromTo(
				`${SELECTOR_NB_TOGGLE_CLOSE} path`,
				speedify(TOGGLE_DURATION),
				{
					drawSVG: '100% 100%',
				},
				{
					ease: Power2.easeOut,
					drawSVG: '0% 100%',
				},
				0
			)
			// shrink
			.call(
				() => {
					this.shrinkAnimation(animate);
				},
				null,
				null,
				0
			)
			// background
			.to(
				SELECTOR_BACKGROUND,
				speedify(bgDuration),
				{
					ease: Power0.easeNone,
					css: {
						backgroundColor: '#EFEFEF',
					},
				},
				speedify(bgDelay)
			)
			.to(
				SELECTOR_NB_TOP,
				speedify(bgDuration),
				{
					ease: Power0.easeNone,
					css: {
						borderBottomColor: '#FFFFFF',
					},
				},
				speedify(bgDelay)
			)
			// dropdown
			.set(
				SELECTOR_DROPDOWN,
				{
					css: {
						display: 'grid',
					},
				},
				speedify(bgDuration + bgDelay)
			)
			.to(
				SELECTOR_DROPDOWN_OVERLAY,
				speedify(ddDuration),
				{
					ease: CustomEase.create('custom', '0.18, 0, 0, 1'),
					css: {
						scaleY: 0,
						transformOrigin: 'center bottom',
					},
				},
				speedify(bgDuration + bgDelay)
			)
			// wave
			.to(
				`${SELECTOR_NB_MENU_ITEM}[aria-expanded="true"] .item__icon`,
				speedify(waveDuration),
				{
					ease: Power0.easeNone,
					css: {
						transformOrigin: 'center bottom',
						scaleY: 1,
					},
					overwrite: 'all',
				},
				speedify(bgDuration + bgDelay + waveDelay)
			)
			// cards
			.staggerTo(
				SELECTOR_DROPDOWN_CARD,
				speedify(cardDuration),
				{
					ease: Power0.easeNone,
					css: {
						opacity: 1,
					},
				},
				speedify(0.05),
				speedify(bgDuration + bgDelay)
			)
			.staggerTo(
				`${SELECTOR_DROPDOWN_CARD}:not(:first-child)`,
				speedify(cardDuration * 2),
				{
					ease: CustomEase.create('custom', '0.05, 0, 0.12, 1'),
					css: {
						y: 0,
					},
				},
				speedify(0.1),
				speedify(bgDuration + bgDelay)
			)
			// handle scrollbar
			.set(this.el, {
				css: {
					height: '100%',
					overflowY: 'auto',
					width: '100vw',
				},
			})
			.call(() => {
				disableBodyScroll(this.el, {
					reserveScrollBarGap: true,
				});
			});

		if (closeMenuAnmDesktopActive) {
			this.updateSubmenuAnmDesktop();
		}
		else {
			this.cache.tlMenu.set(
				SELECTOR_SUBMENU_ACTIVE,
				{
					css: {
						display: 'initial',
					},
					overwrite: 'all',
				},
				0
			);
		}

		if (this.state.isProgramAvailable) {
			this.cache.tlMenu.to(
				SELECTOR_NB_PROGRAM,
				speedify(TOGGLE_DURATION),
				{
					ease: Power2.easeOut,
					css: {
						transformOrigin: 'left center',
						x: -(NB_TOGGLE + NB_TOGGLE_MARGIN),
					},
				},
				0
			);
		}
	}

	/**
	 * Update Submenu Animation
	 *
	 * @param {boolean} animate
	 */
	updateSubmenuAnmDesktop(animate = true) {
		const speed = animate ? 1 : 0;
		const speedify = (seconds) => speed * seconds;

		const submenuDuration = 0.3;
		const waveDuration = 0.4;

		this.cache.tlMenuUpdate
			.clear()
			// setup values
			.set(
				SELECTOR_SUBMENU_ACTIVE,
				{
					css: {
						opacity: 0,
					},
				},
				0
			)
			// old submenu
			.to(
				this.cache.oldSubmenu,
				speedify(submenuDuration),
				{
					ease: Power0.easeNone,
					css: {
						opacity: 0,
						display: 'none',
					},
					overwrite: 'all',
				},
				0
			)
			.to(
				`${SELECTOR_NB_MENU_ITEM}[aria-expanded="false"] .item__icon`,
				speedify(waveDuration),
				{
					ease: Power0.easeNone,
					css: {
						transformOrigin: 'center bottom',
						scaleY: 0,
					},
					overwrite: 'all',
				},
				0
			)
			// current submenu
			.to(
				SELECTOR_SUBMENU_ACTIVE,
				speedify(submenuDuration),
				{
					ease: Power0.easeNone,
					css: {
						opacity: 1,
						display: 'initial',
					},
					overwrite: 'all',
				},
				speedify(submenuDuration)
			)
			.to(
				`${SELECTOR_NB_MENU_ITEM}[aria-expanded="true"] .item__icon`,
				speedify(waveDuration),
				{
					ease: Power0.easeNone,
					css: {
						transformOrigin: 'center bottom',
						scaleY: 1,
					},
					overwrite: 'all',
				},
				speedify(submenuDuration)
			)
			// reset values
			.set(this.cache.oldSubmenu, {
				css: {
					opacity: 1,
				},
			});
	}

	/**
	 * Close Menu Animation
	 *
	 * @param {boolean} animate
	 */
	closeMenuAnmDesktop(animate = true) {
		const speed = animate ? 1 : 0;
		const speedify = (seconds) => speed * seconds;

		const bgDuration = 0.4;
		const bgDelay = 0.2;
		const ddDuration = 0.65;
		const cardDuration = 0.2;
		const cardDelay = 0.1;
		const waveDuration = 0.3;
		const waveDelay = 0.2;

		this.cache.tlMenu
			.clear()
			// dropdown
			.to(
				SELECTOR_DROPDOWN_OVERLAY,
				speedify(ddDuration),
				{
					ease: CustomEase.create('custom', '0.88, 0, 0.88, 1'),
					css: {
						scaleY: 1,
						transformOrigin: 'center bottom',
					},
				},
				0
			)
			.set(
				SELECTOR_DROPDOWN,
				{
					css: {
						display: 'none',
					},
				},
				speedify(ddDuration)
			)
			// cards
			.staggerTo(
				SELECTOR_DROPDOWN_CARD,
				speedify(cardDuration),
				{
					ease: Power0.easeNone,
					css: {
						opacity: 0,
					},
				},
				speedify(-cardDuration),
				speedify(cardDelay)
			)
			// wave
			.to(
				`${SELECTOR_NB_MENU_ITEM}[aria-expanded="true"] .item__icon`,
				speedify(waveDuration),
				{
					ease: Power0.easeNone,
					css: {
						transformOrigin: 'center bottom',
						scaleY: 0,
					},
					overwrite: 'all',
				},
				speedify(waveDelay)
			)
			// background
			.to(
				SELECTOR_BACKGROUND,
				speedify(bgDuration),
				{
					ease: Power0.easeNone,
					css: {
						backgroundColor: 'rgba(255,255,255,0)',
					},
				},
				speedify(ddDuration + bgDelay)
			)
			.to(
				SELECTOR_NB_TOP,
				speedify(bgDuration),
				{
					ease: Power0.easeNone,
					css: {
						borderBottomColor: '#DEDEDE',
					},
				},
				speedify(ddDuration + bgDelay)
			)
			// grow
			.call(
				() => {
					this.growAnimation(animate);
				},
				null,
				null,
				speedify(ddDuration + bgDelay)
			)
			// handle scrollbar
			.call(() => {
				enableBodyScroll(this.el);
			})
			.set(this.el, {
				css: {
					height: 'auto',
					overflowY: 'visible',
					width: '100%',
				},
			})
			// toggle
			.fromTo(
				`${SELECTOR_NB_TOGGLE_CLOSE} path`,
				speedify(TOGGLE_DURATION),
				{
					drawSVG: '100%',
				},
				{
					ease: Power2.easeOut,
					drawSVG: '0%',
				},
				0
			)
			.set(
				SELECTOR_NB_TOGGLE_CLOSE,
				{
					css: {
						opacity: 0,
					},
				},
				speedify(TOGGLE_DURATION)
			)
			// reset values
			.set(SELECTOR_NB_TOGGLE, {
				css: {
					display: 'none',
				},
			})
			.set(SELECTOR_SUBMENU_ACTIVE, {
				css: {
					display: 'none',
				},
				overwrite: 'all',
			})
			.set(SELECTOR_BACKGROUND, {
				css: {
					height: '0%',
				},
			})
			// close artist search
			.call(() => {
				const event = new CustomEvent('clearArtistSearch');
				window.dispatchEvent(event);
			})
			.play();

		if (this.state.isProgramAvailable) {
			this.cache.tlMenu.to(
				SELECTOR_NB_PROGRAM,
				speedify(TOGGLE_DURATION),
				{
					ease: Power2.easeOut,
					css: {
						transformOrigin: 'left center',
						x: 0,
					},
				},
				speedify(TOGGLE_DURATION)
			);
		}
	}

	// Mobile Animations
	/**
	 * Open Menu Animation
	 *
	 * @param {boolean} animate
	 */
	openMenuAnmMobile(animate = true) {
		const speed = animate ? 1 : 0;
		const speedify = (seconds) => speed * seconds;

		const bgDuration = 0.45;
		const bgDelay = this.state.isMinimized ? 0 : 0.2;
		const elementDuration = 0.3;

		this.cache.tlMenu
			.clear()
			// setup values
			.set(this.el, {
				css: {
					overflowY: 'visible',
				},
			})
			.set(
				SELECTOR_NB_MENU_ITEM,
				{
					css: {
						opacity: '0',
						y: 40,
					},
				},
				0
			)
			.set(
				SELECTOR_NB_MENU,
				{
					css: {
						display: 'block',
						visibility: 'visible',
					},
				},
				0
			)
			.set(
				SELECTOR_BACKGROUND,
				{
					css: {
						height: '100%',
					},
				},
				0
			)
			// toggle
			.fromTo(
				`${SELECTOR_NB_TOGGLE_MENU} path`,
				speedify(TOGGLE_DURATION),
				{
					drawSVG: '100%',
				},
				{
					ease: Power2.easeOut,
					drawSVG: '0%',
				},
				0
			)
			.set(
				SELECTOR_NB_TOGGLE_MENU,
				{
					css: {
						opacity: 0,
					},
				},
				speedify(TOGGLE_DURATION)
			)
			.set(
				SELECTOR_NB_TOGGLE_CLOSE,
				{
					css: {
						opacity: 1,
					},
				},
				speedify(TOGGLE_DURATION)
			)
			.fromTo(
				`${SELECTOR_NB_TOGGLE_CLOSE} path`,
				speedify(TOGGLE_DURATION),
				{
					drawSVG: '100% 100%',
				},
				{
					ease: Power2.easeOut,
					drawSVG: '0% 100%',
				},
				speedify(TOGGLE_DURATION)
			)
			// shrink
			.call(
				() => {
					this.shrinkAnimation(animate);
				},
				null,
				null,
				0
			)
			// background
			.to(
				[SELECTOR_BACKGROUND],
				speedify(bgDuration),
				{
					ease: Power0.easeNone,
					css: {
						backgroundColor: '#EFEFEF',
					},
				},
				speedify(bgDelay)
			)
			.to(
				SELECTOR_NB_TOP,
				speedify(bgDuration),
				{
					ease: Power0.easeNone,
					css: {
						borderBottomColor: '#FFFFFF',
					},
				},
				speedify(bgDelay)
			)
			// menu items
			.staggerTo(
				SELECTOR_NB_MENU_ITEM,
				speedify(elementDuration),
				{
					ease: Power2.easeOut,
					css: {
						opacity: 1,
						y: 0,
					},
				},
				speedify(0.05),
				speedify(bgDuration + bgDelay)
			)
			.call(() => {
				this.getNavbarMenuHeight();
			})
			// handle scrollbar
			.set(this.el, {
				css: {
					height: '100%',
					overflowY: 'auto',
					width: '100vw',
				},
			})
			.call(() => {
				disableBodyScroll(this.el, {
					reserveScrollBarGap: true,
				});
			});

		if (this.state.isSmallMode) {
			this.cache.tlMenu
				.set(
					SELECTOR_NB_TOP,
					{
						css: {
							display: 'flex',
							opacity: 0,
						},
					},
					0
				)
				.to(
					SELECTOR_NB_TOP,
					speedify(elementDuration),
					{
						ease: Power0.easeNone,
						css: {
							opacity: 1,
						},
					},
					speedify(bgDuration + bgDelay)
				);

			if (this.state.isProgramAvailable) {
				this.cache.tlMenu
					.set(
						SELECTOR_NB_PROGRAM,
						{
							css: {
								display: 'inline-block',
								opacity: 0,
							},
						},
						0
					)
					.to(
						SELECTOR_NB_PROGRAM,
						speedify(elementDuration),
						{
							ease: Power0.easeNone,
							css: {
								opacity: 1,
							},
						},
						speedify(bgDuration + bgDelay)
					);
			}
		}
	}

	/**
	 * Close Menu Animation
	 *
	 * @param {boolean} animate
	 */
	closeMenuAnmMobile(animate = true) {
		const speed = animate ? 1 : 0;
		const speedify = (seconds) => speed * seconds;

		const bgDelay = 0.55;
		const bgDuration = 0.4;
		const elementDuration = 0.15;

		this.cache.tlMenu
			.clear()
			.set(
				this.el,
				{
					css: {
						overflowY: 'visible',
						// width: '100%',
					},
				},
				0
			)
			.call(
				() => {
					enableBodyScroll(this.el);
				},
				null,
				null,
				0
			)
			// menu items
			.staggerTo(
				SELECTOR_NB_MENU_ITEM,
				speedify(elementDuration),
				{
					ease: Power2.easeOut,
					css: {
						opacity: 0,
						y: 40,
					},
				},
				speedify(-0.05),
				0
			)
			// toggle
			.fromTo(
				`${SELECTOR_NB_TOGGLE_CLOSE} path`,
				speedify(TOGGLE_DURATION),
				{
					drawSVG: '100%',
				},
				{
					ease: Power2.easeOut,
					drawSVG: '0%',
				},
				0
			)
			.set(
				SELECTOR_NB_TOGGLE_CLOSE,
				{
					css: {
						opacity: 0,
					},
				},
				speedify(TOGGLE_DURATION)
			)
			.set(
				SELECTOR_NB_TOGGLE_MENU,
				{
					css: {
						opacity: 1,
					},
				},
				speedify(TOGGLE_DURATION)
			)
			.fromTo(
				`${SELECTOR_NB_TOGGLE_MENU} path`,
				speedify(TOGGLE_DURATION),
				{
					drawSVG: '100% 100%',
				},
				{
					ease: Power2.easeOut,
					drawSVG: '100%',
				},
				speedify(TOGGLE_DURATION)
			)
			// background
			.to(
				SELECTOR_BACKGROUND,
				speedify(bgDuration),
				{
					ease: Power0.easeNone,
					css: {
						backgroundColor: 'rgba(255,255,255,0)',
					},
				},
				speedify(bgDelay)
			)
			.to(
				SELECTOR_NB_TOP,
				speedify(bgDuration),
				{
					ease: Power0.easeNone,
					css: {
						borderBottomColor: '#DEDEDE',
					},
				},
				speedify(bgDelay)
			)
			// grow
			.call(
				() => {
					this.growAnimation(animate);
				},
				null,
				null,
				speedify(bgDelay)
			)
			// handle scrollbar
			.call(() => {
				enableBodyScroll(this.el);
			})
			.set(this.el, {
				css: {
					height: 'auto',
					overflowY: 'visible',
					width: '100%',
				},
			})
			// reset values
			.set(
				SELECTOR_NB_MENU_ITEM,
				{
					css: {
						opacity: 1,
						y: 0,
					},
				}
			)
			.set(SELECTOR_BACKGROUND, {
				css: {
					height: '0%',
				},
			})
			.set(SELECTOR_NB_MENU, {
				css: {
					display: 'none',
				},
			})
			// close artist search
			.call(() => {
				const event = new CustomEvent('clearArtistSearch');
				window.dispatchEvent(event);
			})
			.play();

		if (this.state.isSmallMode) {
			this.cache.tlMenu
				.to(
					SELECTOR_NB_TOP,
					speedify(elementDuration),
					{
						ease: Power0.easeNone,
						css: {
							opacity: 0,
						},
					},
					0
				)
				.set(SELECTOR_NB_TOP, {
					css: {
						display: 'none',
						opacity: 1,
					},
				});

			if (this.state.isProgramAvailable) {
				this.cache.tlMenu
					.to(
						SELECTOR_NB_PROGRAM,
						speedify(elementDuration),
						{
							ease: Power0.easeNone,
							css: {
								opacity: 0,
							},
						},
						0
					)
					.set(SELECTOR_NB_PROGRAM, {
						css: {
							display: 'none',
							opacity: 1,
						},
					});
			}
		}
	}

	/**
	 * Open Submenu Animation
	 *
	 * @param {boolean} animate
	 */
	openSubmenuAnmMobile(animate = true) {
		const speed = animate ? 1 : 0;
		const speedify = (seconds) => speed * seconds;
		const offsetTopItem = Math.floor(this.cache.currentMenuItem.offsetTop);

		const elementDuration = 0.35;
		const elementDelay = 0.1;
		const ddDuration = 0.9;
		const waveDuration = 0.35;
		const cardDuration = 0.3;

		this.cache.tlSubmenu
			.clear()
			// setup values
			.set(
				SELECTOR_SUBMENU_ACTIVE,
				{
					css: {
						display: 'initial',
					},
				},
				0
			)
			.set(
				SELECTOR_DROPDOWN_CARD,
				{
					css: {
						opacity: 0,
					},
				},
				0
			)
			// toggle
			.fromTo(
				`${SELECTOR_NB_TOGGLE_CLOSE} path`,
				speedify(TOGGLE_DURATION),
				{
					drawSVG: '100%',
				},
				{
					ease: Power2.easeOut,
					drawSVG: '0%',
				},
				0
			)
			.set(
				SELECTOR_NB_TOGGLE_CLOSE,
				{
					css: {
						opacity: 0,
					},
				},
				speedify(TOGGLE_DURATION)
			)
			.set(
				SELECTOR_NB_TOGGLE_BACK,
				{
					css: {
						opacity: 1,
					},
				},
				speedify(TOGGLE_DURATION)
			)
			.fromTo(
				`${SELECTOR_NB_TOGGLE_BACK} path`,
				speedify(TOGGLE_DURATION),
				{
					drawSVG: '100% 100%',
				},
				{
					ease: Power2.easeOut,
					drawSVG: '100%',
				},
				speedify(TOGGLE_DURATION)
			)
			// menu list
			.to(
				`${SELECTOR_NB_MENU_ITEM}[aria-expanded="false"]`,
				speedify(elementDuration),
				{
					ease: Power2.easeOut,
					css: {
						opacity: 0,
						y: '-20px',
					},
				},
				0
			)
			.to(
				`${SELECTOR_NB_MENU_ITEM}[aria-expanded="true"]`,
				speedify(elementDuration),
				{
					ease: Power2.easeOut,
					css: {
						y: `-${offsetTopItem}`,
					},
				},
				speedify(elementDelay)
			)
			// dropdown
			.set(
				SELECTOR_DROPDOWN,
				{
					css: {
						display: 'grid',
					},
				},
				speedify(elementDelay + elementDuration)
			)
			.to(
				SELECTOR_DROPDOWN_OVERLAY,
				speedify(ddDuration),
				{
					ease: CustomEase.create('custom', '0.18, 0, 0, 1'),
					css: {
						scaleY: 0,
						transformOrigin: 'center bottom',
					},
				},
				speedify(elementDelay + elementDuration)
			)
			// wave
			.to(
				`${SELECTOR_NB_MENU_ITEM}[aria-expanded="true"] .item__icon`,
				speedify(waveDuration),
				{
					ease: Power0.easeNone,
					css: {
						transformOrigin: 'center bottom',
						scaleY: 1,
					},
				},
				speedify(elementDelay + elementDuration)
			)
			// cards
			.staggerTo(
				SELECTOR_DROPDOWN_CARD,
				speedify(cardDuration),
				{
					ease: Power0.easeNone,
					css: {
						opacity: 1,
					},
				},
				speedify(0.05),
				speedify(elementDelay + elementDuration + ddDuration / 2)
			);
	}

	/**
	 * Close Submenu Animation
	 *
	 * @param {boolean} animate
	 */
	closeSubmenuAnmMobile(animate = true) {
		const speed = animate ? 1 : 0;
		const speedify = (seconds) => speed * seconds;

		const elementDuration = 0.35;
		const elementDelay = 0.1;
		const ddDuration = 0.65;
		const waveDuration = 0.25;
		const cardDuration = 0.2;

		this.cache.tlSubmenu
			.clear()
			// toggle
			.fromTo(
				`${SELECTOR_NB_TOGGLE_BACK} path`,
				speedify(TOGGLE_DURATION),
				{
					drawSVG: '100%',
				},
				{
					ease: Power2.easeOut,
					drawSVG: '0%',
				},
				0
			)
			.set(
				SELECTOR_NB_TOGGLE_BACK,
				{
					css: {
						opacity: 0,
					},
				},
				speedify(TOGGLE_DURATION)
			)
			.set(
				SELECTOR_NB_TOGGLE_CLOSE,
				{
					css: {
						opacity: 1,
					},
				},
				speedify(TOGGLE_DURATION)
			)
			.fromTo(
				`${SELECTOR_NB_TOGGLE_CLOSE} path`,
				speedify(TOGGLE_DURATION),
				{
					drawSVG: '100% 100%',
				},
				{
					ease: Power2.easeOut,
					drawSVG: '0% 100%',
				},
				speedify(TOGGLE_DURATION)
			)
			// dropdown
			.to(
				SELECTOR_DROPDOWN_OVERLAY,
				speedify(ddDuration),
				{
					ease: CustomEase.create('custom', '0.88, 0, 0.88, 1'),
					css: {
						scaleY: 1,
						transformOrigin: 'center bottom',
					},
				},
				0
			)
			.set(
				SELECTOR_DROPDOWN,
				{
					css: {
						display: 'none',
					},
				},
				speedify(ddDuration)
			)
			// wave
			.to(
				`${SELECTOR_NB_MENU_ITEM}[aria-expanded="true"] .item__icon`,
				speedify(waveDuration),
				{
					ease: Power0.easeNone,
					css: {
						transformOrigin: 'center bottom',
						scaleY: 0,
					},
				},
				0
			)
			// cards
			.staggerTo(
				SELECTOR_DROPDOWN_CARD,
				speedify(cardDuration),
				{
					ease: Power0.easeNone,
					css: {
						opacity: 0,
					},
				},
				speedify(-0.05),
				0
			)
			.to(
				`${SELECTOR_NB_MENU_ITEM}[aria-expanded="true"]`,
				speedify(elementDuration),
				{
					ease: Power2.easeOut,
					css: {
						y: 0,
					},
				},
				speedify(ddDuration)
			)
			.to(
				`${SELECTOR_NB_MENU_ITEM}[aria-expanded="false"]`,
				speedify(elementDuration),
				{
					ease: Power2.easeOut,
					css: {
						opacity: 1,
						y: 0,
					},
				},
				speedify(ddDuration + elementDelay)
			)
			// reset values
			.set(SELECTOR_SUBMENU_ACTIVE, {
				css: {
					display: 'none',
				},
			})
			.play();
	}

	// --------------------------------
	// #endregion
	// --------------------------------

	/**
	 * Before the component gets destroyed
	 * - unbind any event not bound with this.on()
	 * - reset UI if needed (any classes/attributes added?)
	 */
	beforeDestroy() {
		window.removeEventListener('mediaLoaded', this.loadAnimationHandler);
	}
}
