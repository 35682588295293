import delegate from 'delegate';
import {
	isFunction, isEmpty, isBoolean, pullAt,
} from 'lodash-es';

/**
 * Generate an incremental
 * view id.
 */
let VIEW_ID = 0;
function generateViewId() {
	VIEW_ID += 1;
	return VIEW_ID;
}

/**
 * Base view class.
 * All the components should extend this class.
 *
 * @class BaseView
 */
export default class BaseView {
	/**
	 * Constructor.
	 *
	 * @param {Element} element
	 * @param {string} name
	 */
	constructor(element, name) {
		this.el = element;
		this.name = name;
		this.channel = document;
		this.vid = generateViewId();
		this.events = {
			global: {},
			element: {},
			viewport: [],
		};
		this.viewport = window.viewport_service;
	}

	/**
	 * Default initialize method.
	 */
	init() {}

	/**
	 * Trigger a global event.
	 *
	 * @param {string} eventName
	 * @param {Object} params
	 * @param global
	 */
	trigger(eventName, params = null, global = true) {
		const event = new CustomEvent(eventName, params);
		if (global) {
			this.channel.dispatchEvent(event);
		}
		else {
			this.el.dispatchEvent(event);
		}
	}

	/**
	 * Bind a callback to an element event or global event.
	 *
	 * @param {string} eventName
	 * @param {string} selector Optional.
	 * @param {Function} handler
	 * @param {boolean} channel Optional. Default: false. Whether or not to bind a global event.
	 */
	on(eventName, selector, handler, channel = false) {
		// TODO: refactor!
		if (eventName === 'resizeFast') {
			const resizeHandler = selector; // dirty af
			// save handler reference to be able to detach
			this.events.viewport.push(resizeHandler);
			// bind handler to viewport_service
			viewport_service.on('changeFast', resizeHandler);
			return;
		}

		if (eventName === 'resize') {
			const resizeHandler = selector; // dirty af
			// save handler reference to be able to detach
			this.events.viewport.push(resizeHandler);
			// bind handler to viewport_service
			viewport_service.on('change', resizeHandler);
			return;
		}

		if (eventName === 'resizeSlow') {
			const resizeHandler = selector; // dirty af
			// save handler reference to be able to detach
			this.events.viewport.push(resizeHandler);
			// bind handler to viewport_service
			viewport_service.on('changeSlow', resizeHandler);
			return;
		}

		// Handle the optional selector argument
		if (isFunction(selector)) {
			// eslint-disable-next-line no-param-reassign
			channel = handler || false;
			// eslint-disable-next-line no-param-reassign
			handler = selector;
			// eslint-disable-next-line no-param-reassign
			selector = null;
		}

		if (channel) {
			if (! (eventName in this.events.global)) {
				this.events.global[ eventName ] = [];
			}

			this.channel.addEventListener(eventName, handler);
			this.events.global[ eventName ].push(handler);
		}
		else {
			if (! (eventName in this.events.element)) {
				this.events.element[ eventName ] = [];
			}

			if (isEmpty(selector)) {
				this.el.addEventListener(eventName, handler);
				this.events.element[ eventName ].push({
					selector: 'self',
					handler,
				});
			}
			else {
				this.events.element[ eventName ].push({
					selector,
					handler,
					delegate: delegate(this.el, selector, eventName, (e) => {
						handler(e);
					}, true),
				});
			}
		}
	}

	/**
	 * Unbind the given element event or global event.
	 *
	 * @param {string} eventName
	 * @param {string} selector Optional.
	 * @param {boolean} channel Optional. Default: false. Whether or not to unbind a global event.
	 */
	off(eventName, selector = null, channel = false) {
		// TODO: refactor!
		if (eventName === 'resizeFast') {
			// detach handler(s) from viewport_service
			this.events.viewport.forEach((handler) => {
				viewport_service.off('changeFast', handler);
			});
			this.events.viewport = [];
			return;
		}

		if (eventName === 'resize') {
			// detach handler(s) from viewport_service
			this.events.viewport.forEach((handler) => {
				viewport_service.off('change', handler);
			});
			this.events.viewport = [];
			return;
		}

		// Handle the optional selector argument
		if (isBoolean(selector)) {
			// eslint-disable-next-line no-param-reassign
			channel = selector;
			// eslint-disable-next-line no-param-reassign
			selector = null;
		}

		if (channel) {
			if (eventName in this.events.global) {
				for (let i = 0; i < this.events.global[ eventName ].length; i++) {
					this.channel.removeEventListener(eventName, this.events.global[ eventName ][ i ]);
				}
			}
			delete this.events.global[ eventName ];
		}
		else if (eventName in this.events.element) {
			if (isEmpty(selector)) {
				// Events binded to the element
				for (let i = this.events.element[ eventName ].length - 1; i >= 0; i--) {
					if (this.events.element[ eventName ][ i ].selector === 'self') {
						this.el.removeEventListener(eventName, this.events.element[ eventName ][ i ].handler);
					}
					else {
						this.events.element[ eventName ][ i ].delegate.destroy();
					}
				}
				delete this.events.element[ eventName ];
			}
			else {
				// unbind delegates events
				for (let i = this.events.element[ eventName ].length - 1; i >= 0; i--) {
					if (selector === this.events.element[ eventName ][ i ].selector) {
						this.events.element[ eventName ][ i ].delegate.destroy();
						pullAt(this.events.element[ eventName ], i);
					}
				}
			}
		}
	}

	/**
	 * Things to do before the component gets destroyed
	 * (reset UI or else)
	 */
	beforeDestroy() {}

	/**
	 * Off all events (element & global).
	 */
	destroy() {
		// custom component hook
		this.beforeDestroy();

		// detach all events
		Object.keys(this.events.global).forEach((eventName) => {
			this.off(eventName, null, null, true);
		});
		Object.keys(this.events.element).forEach((eventName) => {
			this.off(eventName);
		});
		this.events.viewport.forEach((handler) => {
			viewport_service.off('changeFast', handler);
			viewport_service.off('change', handler);
		});
		this.events.viewport = [];
	}
}
