/* eslint-disable curly */
import BaseView from '../../../js/base-view';
import ArticlesList from '../../organisms/articles-list/articles-list';
import PageNavigation from '../../organisms/page-navigation/page-navigation';
import ArticleTags from '../../molecules/article-tags/article-tags';

const SELECTED_CLASS = 'is-selected';
const LOADING_MODIFIER = 'is-loading';

const CATEGORY_ITEM_SELECTOR = '.page-navigation__list__item';
const FILTERS_TITLE_SELECTOR = '.filters__title > span';
const ARTICLE_TAGS_SELECTOR = '.article-tags';
const TOPIC_LINK_SELECTOR = '.article-tags__link:not([data-festival-edition-link])';
const SELECTED_CATEGORY_ITEM_SELECTOR = `${ CATEGORY_ITEM_SELECTOR }.${ SELECTED_CLASS }`;

const SELECTOR_PAGE_NAV_CATEGORY = '[data-page-navigation]';
const SELECTOR_ARTICLES_LIST_BIG = '[data-articles-list="big-cards"]';
const SELECTOR_ARTICLES_LIST_SMALL = '[data-articles-list="small-cards"]';
const SELECTOR_ARTICLE_TAGS = '[data-article-tags]';

const NB_BIG_CARDS_PER_VW = [
	{ min: 2400, nb: 12 },
	{ min: 2000, nb: 10 },
	{ min: 1024, nb: 8 },
	{ min: 768, nb: 6 },
	{ min: 600, nb: 6 },
	{ min: 0, nb: 6 },
];
const FESTIVAL_EDITIONS_WRAPPER_SELECTOR = '.article-tags--festival-editions';

export default class MediaPageContent extends BaseView {
	/**
	 * Entrypoint
	 */
	init() {
		// refs
		// to keep reference of scoped dom elements
		this.refs = {
			filtersTitle: this.el.querySelector(FILTERS_TITLE_SELECTOR),
			articleTags: this.el.querySelector(ARTICLE_TAGS_SELECTOR),

			pageNavCat: null,
			articlesListBig: null,
			articlesListSmall: null,
			festivalEditions: this.el.querySelector(FESTIVAL_EDITIONS_WRAPPER_SELECTOR),
			// loadMoreButton: this.el.querySelector(LOAD_MORE_BUTTON_SELECTOR),
		};

		this.initSubcomponents();

		// props
		// this.props = {};

		// state
		this.state = {
			isLoading: false,
			filtersTitle: null,
			currentSelectedFilter: this.getSelectedFilter(),
		};

		this.cache = {
			// 		oldMenuItem: null,
		};

		// attach events
		this.bindEvents();

		// swup: add "archive" transition on category/tag click
		const linksToArchive = [];
		const catLinks = this.el.querySelectorAll(`${ SELECTOR_PAGE_NAV_CATEGORY } .page-navigation__list__item`);
		const tagLinks = this.el.querySelectorAll(`${ SELECTOR_ARTICLE_TAGS }:not(.article-tags--festival-editions) .article-tags__link`);
		if (catLinks) {
			linksToArchive.push(...[].slice.call(catLinks));
		}
		if (tagLinks) {
			linksToArchive.push(...[].slice.call(tagLinks));
		}
		if (linksToArchive && linksToArchive.length) {
			linksToArchive.forEach((el) => {
				el.setAttribute('data-swup-transition', 'archive-transition');
			});
		}

		// if (window.supt && window.supt.mediaPageHistory) {
		// 	window.supt.mediaPageHistory.states[ 0 ].targetElement = this.state.currentSelectedFilter;
		// }
	}

	initSubcomponents() {
		// const pageNavCat = this.el.querySelector(SELECTOR_PAGE_NAV_CATEGORY);
		// if (pageNavCat) this.refs.pageNavCat = new PageNavigation(pageNavCat).init();

		// const articleTags = this.el.querySelector(SELECTOR_ARTICLE_TAGS);
		// if (articleTags) this.refs.articleTags = new ArticleTags(articleTags).init();

		const articlesListBig = this.el.querySelector(SELECTOR_ARTICLES_LIST_BIG);
		if (articlesListBig) this.refs.articlesListBig = new ArticlesList(articlesListBig).init();

		const articlesListSmall = this.el.querySelector(SELECTOR_ARTICLES_LIST_SMALL);
		if (articlesListSmall) this.refs.articlesListSmall = new ArticlesList(articlesListSmall).init();

		this.maybeRearrangeCardsInLists();
	}

	/**
	 * Bind component's events
	 */
	bindEvents() {
		// this.on('click', CATEGORY_ITEM_SELECTOR, (event) => this.onClickItemLink(event));
		// this.on('click', TOPIC_LINK_SELECTOR, (event) => this.onClickItemLink(event, true));

		this.on('resize', this.maybeRearrangeCardsInLists.bind(this));
		// this.onPopStateHandler = this.onPopState.bind(this);
		// window.addEventListener('popstate', this.onPopStateHandler);
	}

	// --------------------------------
	// #region Event Handlers
	// --------------------------------

	onClickItemLink(event, isTopic = false) {
		event.preventDefault();
		let targetElement; let filtersTitle; let url;

		if (isTopic) {
			targetElement = event.target;
			filtersTitle = event.target.innerHTML;
			url = event.target.attributes.href.value;
		}
		else {
			targetElement = event.target.parentElement;
			filtersTitle = targetElement.children[ 0 ].innerHTML;
			url = targetElement.attributes.href.value;
		}

		this.renderPageContent(targetElement, filtersTitle, url);
	}

	// onPopState(event) {
	// 	if (! event.state) {
	// 		window.history.go(0);
	// 		return;
	// 	}
	// 	const state = JSON.parse(event.state);
	// 	this.renderPageContent(null, state.filtersTitle, state.url);
	// 	// window.history.replaceState(event.state);
	// }

	// --------------------------------
	// #endregion
	// --------------------------------

	// --------------------------------
	// #region Actions
	// --------------------------------

	updateBrowserUrl(url, filtersTitle) {
		const stateObject = { url, targetElement: this.state.currentSelectedFilter, filtersTitle };
		window.history.pushState(JSON.stringify(stateObject), null, url);
		window.supt.mediaPageHistory.states.push(stateObject);
	}

	reloadPageContent(oldHMTLcontent) {
		this.el.innerHTML = oldHMTLcontent;
	}

	// --------------------------------
	// #region Filters & Loading
	// --------------------------------

	getSelectedFilter() {
		return this.el.querySelector(SELECTED_CATEGORY_ITEM_SELECTOR);
	}

	setNewSelectedFilter(target = null) {
		if (target == null) {
			return;
		}
		this.getSelectedFilter().classList.remove(SELECTED_CLASS);
		target.classList.add(SELECTED_CLASS);
		this.state.currentSelectedFilter = target;
	}

	updateFiltersTitle(title) {
		if (this.getSelectedFilter() === this.el.querySelector('.page-navigation__list').firstElementChild) {
			this.state.filtersTitle = '';
		}
		else {
			this.state.filtersTitle = title;
		}
		this.refs.filtersTitle = this.el.querySelector(FILTERS_TITLE_SELECTOR);

		if (this.refs.filtersTitle) {
			this.refs.filtersTitle.classList.add('filters-title--loading');
			this.refs.filtersTitle.innerText = this.state.filtersTitle;
		}
	}

	toggleLoadingModifiers() {
		this.refs.articlesListBig.setIsLoading(this.state.isLoading);

		if (this.refs.articleTags) {
			this.refs.articleTags.classList[ this.state.isLoading ? 'add' : 'remove' ](LOADING_MODIFIER);
		}
	}

	fetchMediaContentPartial(url, filtersTitle) {
		fetch(`${ url }?content_only=true`)
			.then((response) => response.text())
			.then((text) => this.handleFetchResponse(text, url, filtersTitle))
			.catch((error) => console.error(error));
	}

	handleFetchResponse(text, url, filtersTitle) {
		this.destroySubComponents();

		document.querySelector('.media-page-content').innerHTML = text;

		// this.maybeExecLoadMoreInlineScripts();

		this.initSubcomponents();
		this.updateBrowserUrl(url, filtersTitle);

		this.state.isLoading = false;
		this.toggleLoadingModifiers();
	}

	// maybeExecLoadMoreInlineScripts() {
	// 	window.supt = window.supt || {};
	// 	window.supt.loadmore = window.supt.loadmore || {};
	// 	Array.from(this.el.querySelectorAll('[data-load-more-script]'))
	// 		.forEach((script) => {
	// 			const [match, id, config] = script.innerText.match(/(loadmore_[a-z0-9]+)"]\s=\s({.*})/);
	// 			if (id && config) {
	// 				window.supt.loadmore[ id ] = JSON.parse(config);
	// 			}
	// 		});
	// }

	renderPageContent(targetElement, filtersTitle, url) {
		this.state.isLoading = true;
		this.toggleLoadingModifiers();

		this.setNewSelectedFilter(targetElement);

		this.updateFiltersTitle(filtersTitle);
		this.fetchMediaContentPartial(url, filtersTitle);
	}

	maybeRearrangeCardsInLists() {
		// Bail early without both Big & small list
		// eslint-disable-next-line curly
		if (! (this.refs.articlesListBig && this.refs.articlesListSmall)) return;

		const vw = this.viewport.getViewportWidth();
		const { nb: nbToHave } = NB_BIG_CARDS_PER_VW.find((c) => (vw > c.min));

		const nbInBig = this.refs.articlesListBig.getNbCards();

		// eslint-disable-next-line curly
		if (nbInBig === nbToHave) return;

		const diff = Math.abs(nbToHave - nbInBig);
		if (nbInBig > nbToHave) {
			this.refs.articlesListSmall.insertCards(
				this.refs.articlesListBig.extractCards(diff, false),
				'afterbegin'
			);
		}
		else {
			this.refs.articlesListBig.insertCards(
				this.refs.articlesListSmall.extractCards(diff, true),
				'beforeend'
			);
		}
	}

	// --------------------------------
	// #endregion
	// --------------------------------

	destroySubComponents() {
		if (this.refs.pageNavCat) this.refs.pageNavCat.destroy();
		if (this.refs.articlesListBig) this.refs.articlesListBig.destroy();
		if (this.refs.articlesListSmall) this.refs.articlesListSmall.destroy();

		this.refs.pageNavCat = null;
		this.refs.articlesListBig = null;
		this.refs.articlesListSmall = null;
	}

	beforeDestroy() {
		// this.destroySubComponents();
	}
}
