import { EventEmitter } from 'events';
import { debounce } from 'lodash-es';

const DEBOUNCE_DELAY_FAST = 10; // in ms
const DEBOUNCE_DELAY = 100; // in ms
const DEBOUNCE_DELAY_SLOW = 1000; // in ms

// TODO: refactor as singleton, expose only helpers
export default class Viewport extends EventEmitter {
	init() {
		this.resizeHandler();
		this.previousSizes = { width: null, height: null }
	}

	/**
	 * Return the viewport width.
	 * @return {Number}
	 */
	getViewportWidth() {
		return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
	}

	/**
	 * Return the viewport height.
	 * @return {Number}
	 */
	getViewportHeight() {
		return Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
	}

	getViewportRatio() {
		return (this.getCurrentWidth() / this.getCurrentHeight());
	}

	setCurrentWidth(width) {
		this.current_width = width;
	}

	getCurrentWidth() {
		return this.current_width;
	}

	setCurrentHeight(height) {
		this.current_height = height;
	}

	getCurrentHeight() {
		return this.current_height;
	}

	bindResizeFast() {
		window.addEventListener('resize', debounce(this.resizeHandlerFast.bind(this), DEBOUNCE_DELAY_FAST, false));
	}

	bindResize() {
		window.addEventListener('resize', debounce(this.resizeHandler.bind(this), DEBOUNCE_DELAY, false));
	}

	bindResizeSlow() {
		window.addEventListener('resize', debounce(this.resizeHandlerSlow.bind(this), DEBOUNCE_DELAY_SLOW, false));
	}

	resizeHandlerFast() {
		this.previousSizes.width = this.getCurrentWidth();
		this.previousSizes.height = this.getCurrentHeight();
		this.setCurrentWidth(this.getViewportWidth());
		this.setCurrentHeight(this.getViewportHeight());
		this.emit('changeFast', {
			previous: this.previousSizes,
			current: { width: this.getCurrentWidth(), height: this.getCurrentHeight() },
		 });
	}

	resizeHandler() {
		this.setCurrentWidth(this.getViewportWidth());
		this.setCurrentHeight(this.getViewportHeight());
		this.emit('change', {
			previous: this.previousSizes,
			current: { width: this.getCurrentWidth(), height: this.getCurrentHeight() },
		 });
	}

	resizeHandlerSlow() {
		this.setCurrentWidth(this.getViewportWidth());
		this.setCurrentHeight(this.getViewportHeight());
		this.emit('changeSlow');
	}
}


/**
 * Init Viewport service.
 * NOTE This is not so good.
 */
const viewportService = new Viewport();
viewportService.setCurrentWidth(viewportService.getViewportWidth());
viewportService.bindResizeFast();
viewportService.bindResize();
viewportService.bindResizeSlow();
viewportService.init();

// I mean this is not good.
window.viewport_service = viewportService;
