// TODO: update from new JS baseview

import BaseInput from '../../atoms/inputs/_0-input/input';

const SELECTOR_FIELD = 'input';

export default class OptionGroup extends BaseInput {
	init() {
		this.refs = {
			fields: Array.from(this.el.querySelectorAll(SELECTOR_FIELD)),
		};

		this.props = {
			isConditinal: false,
			name: this.refs.fields[ 0 ].name.replace('[]', ''),
			type: this.refs.fields[ 0 ].type,
		};

		this.state = {
			value: this.getValue(),

			isDisabled: false,
			isErroneous: false,
		};

		this.setIsConditional();
		this.bindEvents();
		this.triggerChange();
	}

	bindEvents() {
		this.on('change', SELECTOR_FIELD, this.onChange.bind(this));
	}

	onChange() {
		this.triggerChange();
		this.resetError();
	}

	getValue() {
		const value = Array.from(this.el.querySelectorAll(':checked')).map((f) => f.value);
		return value.length ? value : null;
	}

	checkCondition(name, value) {
		// Trigger a new change if the condition state has changed
		if (super.checkCondition(name, value)) {
			this.triggerChange();
		}
	}

	triggerChange() {
		this.state.value = this.getValue();

		const value = this.state.isDisabled ? null : this.state.value;
		this.trigger('input:change', { detail: { name: this.props.name, value } }, false);
	}

	reset() {
		super.resetError();
		this.refs.fields.forEach((f) => {
			// eslint-disable-next-line no-param-reassign
			f.checked = false;
		});
	}

	checkValidity() {
		if (this.refs.fields.filter((f) => f.checkValidity()).length === this.refs.fields.length) {
			this.resetError();
			return true;
		}

		this.setError(this.refs.fields[ 0 ].validationMessage);
		return false;
	}

	focus() {
		this.refs.fields[ 0 ].focus();
	}

	disable() {
		this.refs.fields.forEach((field) => field.setAttribute('disabled', true));
	}

	enable() {
		// Do not enable field if field is condition & should still be hidden
		if (this.props.isConditional && this.state.isHidden) {
			return;
		}
		this.refs.fields.forEach((field) => field.removeAttribute('disabled'));
	}
}
