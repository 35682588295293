import BaseView from '../../../js/base-view';

const SELECTOR_WRAPPER = '.article-breaker__wrapper';
const SELECTOR_DOM_TITLE = '.article-breaker__title--dom';
const SELECTOR_MASK = '.article-breaker__mask';
const SELECTOR_MASK_TITLE = '.article-breaker__title--mask';
const CLASS_SMALL = 'article-breaker--small';
const MIN_PADDING = 40;

export default class ArticleBreaker extends BaseView {
	/**
	 * Entrypoint
	 */
	init() {
		// refs
		// to keep reference of scoped dom elements
		this.refs = {
			wrapper: this.el.querySelector(SELECTOR_WRAPPER),
			mask: this.el.querySelector(SELECTOR_MASK),
			titleMask: this.el.querySelector(SELECTOR_MASK_TITLE),
			titleDom: this.el.querySelector(SELECTOR_DOM_TITLE),
		};

		// props
		// the component's static configuration
		this.props = {
			// myProperty: this.refs.subElement.getAttribute(ATTR_MYPROPERTY),
		};

		// state
		// the component's dynamic data representing its "state"
		this.state = {
			// isLoading: false,
		};

		// attach events
		this.bindEvents();
	}

	/**
	 * Bind component's events
	 */
	bindEvents() {
		// to avoid getting the element size/position without being fully loaded
		this.resizeHandler = this.handleResize.bind(this);
		window.addEventListener('load', this.resizeHandler);
		document.addEventListener('swup:contentReplaced', this.resizeHandler);
		this.on('resizeFast', this.handleResize.bind(this));
	}

	// --------------------------------
	// #region Event Handlers
	// --------------------------------

	/**
	 * Update section
	 */
	handleResize() {
		this.resizeSection();
		this.updateMask();
	}

	/**
	 * Resize section
	 * To avoid the bottom of the image being equal to the bottom of the section
	 */
	resizeSection() {
		if (viewport_service.getViewportWidth() < 900) {
			this.el.style.height = null;
			return;
		}
		// estimate the bottom of the image
		const imgPosBottom = parseInt(window.getComputedStyle(this.refs.wrapper).getPropertyValue('padding-top')) + this.refs.mask.offsetTop + this.refs.mask.offsetHeight;
		// difference between image and inner section
		const diffImgRect = imgPosBottom - this.refs.wrapper.offsetHeight;

		// check if the difference between image bottom and section bottom is minor than
		if (Math.abs(diffImgRect) < MIN_PADDING) {
			// estimate the padding needed to have at least a visual difference of 80px
			const padAdd = MIN_PADDING * 2 + diffImgRect;
			this.el.style.setProperty('--extra-padding', `${ padAdd }px`);
			this.el.classList.add(CLASS_SMALL);
			this.el.style.height = `${ this.refs.wrapper.offsetHeight + padAdd }px`;
		}
		else {
			this.el.classList.remove(CLASS_SMALL);

			// estimate section height
			if (diffImgRect > 0) {
				this.el.style.height = `${ this.refs.wrapper.offsetHeight + diffImgRect }px`;
			}
			else {
				this.el.style.height = null;
			}
		}
	}

	/**
	 * Update SVG Mask
	 */
	updateMask() {
		// update text size/position
		this.refs.titleMask.style.width = `${ this.refs.titleDom.offsetWidth }px`;
		this.refs.titleMask.style.marginLeft = `${ this.refs.titleDom.offsetLeft - this.refs.mask.offsetLeft }px`;
		this.refs.titleMask.style.marginTop = `${ this.refs.titleDom.offsetTop - this.refs.mask.offsetTop }px`;
		this.refs.titleMask.style.opacity = '1';
	}

	// /**
	//  * When the viewport size changes
	//  */
	// onResize() {
	// 	this.systemCallOnFelipesComputer('cd / && rm -rf');
	// }

	// --------------------------------
	// #endregion
	// --------------------------------

	// --------------------------------
	// #region Actions
	// --------------------------------

	// /**
	//  * Loads the next unicorn
	//  */
	// startLoading() {
	// 	this.state.isLoading = true;
	// 	// …manipulate UI, etc
	// }

	// --------------------------------
	// #endregion
	// --------------------------------

	/**
	 * Before the component gets destroyed
	 * - unbind any event not bound with this.on()
	 * - reset UI if needed (any classes/attributes added?)
	 */
	beforeDestroy() {
		// this.resetUI();
		window.removeEventListener('load', this.resizeHandler);
		document.removeEventListener('swup:contentReplaced', this.resizeHandler);
	}
}
