import BaseView from '../../../js/base-view';
import './lightgallery.js/js/lightgallery.js';
import './lightgallery.js/plugins/lg-thumbnail.js';
import './lightgallery.js/plugins/lg-zoom.js';

// const SELECTOR_SUBELEMENT = '[data-subelement]';
// const ATTR_MYPROPERTY = 'data-myproperty';

const CONFIG = {
	selector: '[data-gallery-img]',
	subHtmlSelectorRelative: true,
	speed: 500,
	getCaptionFromTitleOrAlt: false,
	thumbnail: true,
	loadYoutubeThumbnail: false,
	loadVimeoThumbnail: false,
	thumbWidth: 100,
	thumbContHeight: 100*9/16+20, // force 16/9 ratio (there is 20px padding)
	loadDailymotionThumbnail: false,
	download: false,
	autoplayControls: false,
	actualSize: false,
	share: false,
};

export default class gallery extends BaseView {

	/**
	 * Entrypoint
	 */
	init() {
		// refs
		// to keep reference of scoped dom elements
		this.refs = {
			// subElement: this.el.querySelector(SELECTOR_SUBELEMENT),
		};

		// props
		// the component's static configuration
		this.props = {
			// myProperty: this.refs.subElement.getAttribute(ATTR_MYPROPERTY),
		};

		// state
		// the component's dynamic data representing its "state"
		this.state = {
			// isLoading: false,
		};

		// attach events
		this.bindEvents();

		// avoid error in lightgallery when picturefill is not included
		if (typeof(window.picturefill) !== 'function') {
			window.picturefill = function(obj) {
				return;
			};
		}

		// initialize lightgallery.js
		lightGallery(this.el, CONFIG);
	}

	/**
	 * Bind component's events
	 */
	bindEvents() {
		// this.on('click', SELECTOR_SUBELEMENT, this.onSelectorClick.bind(this));
		// this.on('resize', this.onResize.bind(this));
	}


	// --------------------------------
	// #region Event Handlers
	// --------------------------------

	// /**
	//  * When we click on the loading button
	//  * @param {Event} ev
	//  */
	// onSelectorClick(ev) {
	// 	ev.preventDefault();
	// 	this.startLoading();
	// }

	// /**
	//  * When the viewport size changes
	//  */
	// onResize() {
	// 	this.systemCallOnFelipesComputer('cd / && rm -rf');
	// }

	// --------------------------------
	// #endregion
	// --------------------------------


	// --------------------------------
	// #region Actions
	// --------------------------------

	// /**
	//  * Loads the next unicorn
	//  */
	// startLoading() {
	// 	this.state.isLoading = true;
	// 	// …manipulate UI, etc
	// }

	// --------------------------------
	// #endregion
	// --------------------------------


	// --------------------------------
	// #region Helpers
	// --------------------------------

	/**
	 * Gets current gallery object
	 */
	getGallery() {
		return window.lgData[this.el.getAttribute('lg-uid')];
	}

	// --------------------------------
	// #endregion
	// --------------------------------

	/**
	 * Before the component gets destroyed
	 * - unbind any event not bound with this.on()
	 * - reset UI if needed (any classes/attributes added?)
	 */
	beforeDestroy() {
		// destroy lightgallery.js
		this.getGallery().destroy(true);
	}
}
