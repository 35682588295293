import BaseView from '../../../js/base-view';

export default class Breadcrumbs extends BaseView {
	/**
	 * Init function.
	 */
	init() {
		const bc = this.el.querySelectorAll('span > span > span > span');
		if (bc != null && bc.length) {
			// we are on sub-sub-page or deeper!
			this.el.setAttribute('data-breadcrumbs', bc.length);
		}
	}
}
