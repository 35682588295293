/* eslint-disable no-multi-spaces */
/* eslint-disable no-unused-vars */

import {
	TweenLite,
	Draggable, // https://greensock.com/docs/v2/Utilities/Draggable
	Power1,
} from 'gsap/all';

import charming from 'charming';
import BaseView from '../../../js/base-view';

const BREAKPOINT = 1024;

/**
 * Selectors
 */
const PAGE_NAV_SELECTOR                 = '.nav';
const TABS_SELECTOR                     = '.programme__tabs';
const HEADER_SELECTOR                   = '.programme__header';
const STAGES_ROW_WRAPPER_SELECTOR       = '.programme__header__column-stages .wrapper';
const STAGES_DRAGGABLE_BOUNDS_SELECTOR  = '.programme__header__column-stages';
const STAGES_DRAGGABLE_SELECTOR         = '.programme__header__column-stages .draggable';
const STAGE_DETAILS_SELECTOR            = '.programme__header .programme__stage-details';
const STAGE_SELECTOR                    = '.programme__stage';
const DAYS_SELECTOR                     = '.programme__days-column .day';
const DROPDOWN_SELECTOR                 = '.programme__daypicker-dropdown';
const DROPDOWN_SELECTED_SELECTOR        = '.programme__daypicker-dropdown-selected';
const DROPDOWN_LIST_SELECTOR            = '.programme__daypicker-dropdown-list';
const DAYPICKER_HEADER_TOGGLE_SELECTOR  = '.programme__daypicker-toggle';
const DAYPICKER_TOGGLE_SELECTOR         = '.programme button[data-daypicker-dropdown]';
const CONTENT_DRAGGABLE_BOUNDS_SELECTOR = '.programme__content-column';
const CONTENT_WRAPPER_SELECTOR          = '.programme__content-column .wrapper';
const CONTENT_ROW_SELECTOR              = '.programme__content-column .day';
const COLUMN_SELECTOR                   = '.programme__content-column .day:first-of-type .programme__schedule';
const DRAGABBLE_CURSOR                  = 'ew-resize';
const DRAGABBLE_ACTIVE_CURSOR           = 'grabbing';
const CONTENT_OVERFLOW_CLASS            = 'is-overflow';
const DETAILS_VISIBLE_CLASS             = 'is-details-visible';
const FOCUSABLE_ELEMENTS_SELECTOR       = 'button, a[href]';

export default class Programme extends BaseView {
	/**
	 * Entrypoint
	 */
	init() {
		if (! this.el.classList.contains('is-ready')) {
			return;
		}

		// refs
		this.refs = {
			nav: document.querySelector(PAGE_NAV_SELECTOR),
			header: this.el.querySelector(HEADER_SELECTOR),
			tabs: this.el.querySelector(TABS_SELECTOR),
			contentWrapper: this.el.querySelector(CONTENT_WRAPPER_SELECTOR),
			contentRows: Array.from(this.el.querySelectorAll(CONTENT_ROW_SELECTOR)),
			stageColumns: Array.from(this.el.querySelectorAll(STAGE_SELECTOR)).filter(
				(column) => column.offsetWidth > 0 && column.offsetHeight > 0
			),
			contentColumns: Array.from(this.el.querySelectorAll(COLUMN_SELECTOR)).filter(
				(column) => column.offsetWidth > 0 && column.offsetHeight > 0
			),
			stageDetails: Array.from(this.el.querySelectorAll(STAGE_DETAILS_SELECTOR)),
			focusableElements: Array.from(document.querySelectorAll(STAGE_DETAILS_SELECTOR)).map(
				(node) => Array.from(node.querySelectorAll(FOCUSABLE_ELEMENTS_SELECTOR))
			).flat(),
			daysRows: Array.from(this.el.querySelectorAll(DAYS_SELECTOR)),
			stagesDraggableContainer: this.el.querySelector(STAGES_DRAGGABLE_SELECTOR),
			stagesRowWrapper: this.el.querySelector(STAGES_ROW_WRAPPER_SELECTOR),
			dropdown: this.el.querySelector(DROPDOWN_SELECTOR),
			dropdownToggle: this.el.querySelector(DAYPICKER_HEADER_TOGGLE_SELECTOR),
			dropdownList: this.el.querySelector(DROPDOWN_LIST_SELECTOR),
			dropdownSelected: this.el.querySelector(DROPDOWN_SELECTED_SELECTOR),
			stagesDraggable: null,
			contentDraggable: null,
			observer: null,
			stagesDraggableTween: null,
			contentDraggableTween: null,
		};

		// state
		this.state = {
			isSmallBreakpoint: window.viewport_service.current_width < BREAKPOINT,
			selectedDay: null,
			currentStageDetails: null,
			isContentOverflowing: false,
			columnWidth: 0,
			columnsFitting: 0,
			isDragging: false,
			dragDirection: null,
			lastStageXScroll: 0, // TODO: check if needed for horizontal scroll of overflowing content
		};

		// setup
		this.handleCanceledEvents();
		this.setScrollerDetailsHeight();
		this.checkContentOverflowing();
		this.setColumnWidth();
		this.setNumberOfColumnsFitting();

		setTimeout(() => {
			this.setDaysColumnHeights();
		}, 0);

		if (! this.state.isSmallBreakpoint) {
			this.disableStageDetailsFocusableElements();
		}

		if (this.state.isContentOverflowing) {
			this.handleExtraRoom();
			this.initDraggable();
			this.handleNavigationControls();
		}

		// attach events
		this.bindEvents();
		setTimeout(() => {
			this.scrollToSchedule();
		}, 0);
	}

	/**
	 * Bind component's events
	 */
	bindEvents() {
		// Global events
		this.on('resize', this.onResize.bind(this));
		this.on('click', STAGE_SELECTOR, this.toggleStageDetails.bind(this));
		this.on('click', DAYPICKER_TOGGLE_SELECTOR, this.showDaypicker.bind(this));
		this.on('click', DROPDOWN_SELECTED_SELECTOR, this.closeDaypicker.bind(this));
		this.on('click', DROPDOWN_LIST_SELECTOR, this.scrollToDay.bind(this));
		this.on('click', STAGES_DRAGGABLE_BOUNDS_SELECTOR, this.handleArrowClick.bind(this));
		this.refs.stagesDraggableContainer.addEventListener('scroll', this.onXScroll.bind(this, this.refs.contentWrapper));
		this.refs.contentWrapper.addEventListener('scroll', this.onXScroll.bind(this, this.refs.stagesDraggableContainer));
	}

	// --------------------------------
	// #region Event Handlers
	// --------------------------------

	// Sync scroll
	onXScroll(target, event) {
		if (this.state.isDragging) {
			return;
		}

		const scrollTarget      = target;
		this.state.lastStageXScroll  = event.target.scrollLeft;
		scrollTarget.scrollLeft = this.state.lastStageXScroll;
	}

	onResize() {
		this.state.isSmallBreakpoint = window.viewport_service.current_width < BREAKPOINT;
		this.setColumnWidth();
		this.setNumberOfColumnsFitting();

		if (! this.state.isSmallBreakpoint) {
			this.setDaysColumnHeights();
			this.setScrollerDetailsHeight();
			this.checkContentOverflowing();
		}

		this.handleExtraRoom();
		this.handleDraggableInstances();
	}

	// --------------------------------
	// #endregion
	// --------------------------------

	// --------------------------------
	// #region Actions
	// --------------------------------

	initDraggable() {
		const dragOptions = {
			type: 'scrollLeft',
			edgeResistance: 1,
			cursor: DRAGABBLE_CURSOR,
			activeCursor: DRAGABBLE_ACTIVE_CURSOR,
			onDragStart: () => {
				this.handleDragStart();
			},
			onDragEnd: () => {
				this.handleDragEnd();
			},
		};

		this.refs.stagesDraggable = new Draggable(this.refs.stagesDraggableContainer, {
			...dragOptions,
			bounds: STAGES_DRAGGABLE_BOUNDS_SELECTOR,
			onDrag: () => {
				this.handleDrag(this.refs.stagesDraggable, this.refs.contentDraggable);
			},
		});
		this.refs.contentDraggable = new Draggable(this.refs.contentWrapper, {
			...dragOptions,
			bounds: CONTENT_DRAGGABLE_BOUNDS_SELECTOR,
			onDrag: () => {
				this.handleDrag(this.refs.contentDraggable, this.refs.stagesDraggable);
			},
		});
	}

	handleDragStart() {
		// kill ongoing tweens (snap)
		if (this.refs.stagesDraggableTween) {
			this.refs.stagesDraggableTween.kill();
			this.refs.contentDraggableTween.kill();
			this.refs.stagesDraggableTween = null;
			this.refs.contentDraggableTween = null;
		}

		this.state.isDragging = true;
	}

	handleDragEnd() {
		const closestColumnX = this.getClosestColumnX();

		this.handleNavigationControls(closestColumnX);
		this.scrollContentToPosition(closestColumnX); // SNAP

		setTimeout(() => {
			this.state.isDragging = false;
		}, 0);
	}

	handleDrag(draggable, draggableToSync) {
		const elToSync             = draggableToSync;
		elToSync.target.scrollLeft = draggable.x * -1;
		this.state.dragDirection        = this.refs.contentDraggable.getDirection();
		this.el.classList.add(CONTENT_OVERFLOW_CLASS);
	}

	handleNavigationControls(closestColumnX = 0) {
		const isLeftOverflowing  = closestColumnX > 0;
		const isRightOverflowing = closestColumnX <
			(this.refs.contentWrapper.scrollWidth - this.refs.contentWrapper.clientWidth);
		const bothOverflowing    = isLeftOverflowing && isRightOverflowing;

		this.el.classList.toggle(CONTENT_OVERFLOW_CLASS, bothOverflowing);
		this.el.classList.toggle(`${ CONTENT_OVERFLOW_CLASS }-left`, isLeftOverflowing && ! bothOverflowing);
		this.el.classList.toggle(`${ CONTENT_OVERFLOW_CLASS }-right`, isRightOverflowing && ! bothOverflowing);
	}

	setColumnWidth() {
		if (this.refs.contentColumns.length === 0) {
			return;
		}

		const lastScheduleColumn = this.refs.contentColumns[ this.refs.contentColumns.length - 1 ];

		this.state.columnWidth = lastScheduleColumn.clientWidth +
			parseInt(window.getComputedStyle(lastScheduleColumn).marginLeft);
	}

	setNumberOfColumnsFitting() {
		const containerWidth  = this.refs.contentWrapper.clientWidth;
		const columnsFitting  = Math.floor(containerWidth / this.state.columnWidth);

		this.state.columnsFitting = columnsFitting;
	}

	handleExtraRoom() {
		if (this.refs.contentColumns.length === 0) {
			return;
		}

		const containerWidth     = this.refs.contentWrapper.clientWidth;
		const lastStageColumn    = this.refs.stageColumns[ this.refs.stageColumns.length - 1 ];
		const lastScheduleColumn = this.refs.contentColumns[ this.refs.contentColumns.length - 1 ];
		const extraRoom          = containerWidth - (this.state.columnWidth * this.state.columnsFitting);

		if (! this.state.isContentOverflowing) {
			lastScheduleColumn.removeAttribute('style');
			lastStageColumn.removeAttribute('style');
			return;
		}

		lastScheduleColumn.style.marginRight = `${ extraRoom }px`;
		lastStageColumn.style.marginRight    = `${ extraRoom }px`;
	}

	handleArrowClick(event) {
		const direction = event.target.getAttribute('data-direction');

		if (! direction) {
			return;
		}

		const { target } = this.refs.contentDraggable;
		const amountToScroll = this.state.columnWidth * (this.state.columnsFitting < 3 ? 2 : 3);
		let xPosition = 0;

		if (direction === 'previous') {
			xPosition = target.scrollLeft - amountToScroll;
		}
		else {
			xPosition = target.scrollLeft + amountToScroll;
		}

		this.handleNavigationControls(xPosition);
		this.scrollContentToPosition(xPosition);
	}

	scrollToSchedule() {
		if (this.el.hasAttribute('data-programme-scroll-to-day')) {
			const formatedDateValue = `#day-${ new Date().toISOString().slice(0, new Date().toISOString().indexOf('T')).replace(/-/g, '') }`;
			const button = this.refs.dropdownList.querySelector(`button[data-day="${ formatedDateValue }"]`);
			if (button) {
				button.click();
			}
		}
	}

	getClosestColumnX() {
		if (this.refs.contentColumns.length === 0) {
			return 0;
		}

		const currentScroll     = this.refs.contentDraggable.target.scrollLeft;
		const margin            = 32;
		const columnsXPositions = this.refs.contentColumns.map((column) => column.offsetLeft);
		let closestColumnX      = columnsXPositions.reduce(
			(prev, curr) => (Math.abs(curr - currentScroll) < Math.abs(prev - currentScroll) ?
				curr : prev
			)
		);
		closestColumnX -= margin;
		return (closestColumnX);
	}

	scrollContentToPosition(x) {
		const animation = {
			scrollLeft: x,
			ease: Power1.easeOut,
		};
		const duration = 500;

		this.refs.stagesDraggableTween = new TweenLite(
			this.refs.stagesDraggable.target, duration / 1000, animation
		);

		this.refs.contentDraggableTween = new TweenLite(
			this.refs.contentDraggable.target, duration / 1000, animation
		);
	}

	handleDraggableInstances() {
		const draggableInitialized = this.refs.contentDraggable != null;
		const shouldInitDrag       = this.state.isContentOverflowing && ! draggableInitialized;
		const shouldDestroyDrag    = (! this.state.isContentOverflowing && draggableInitialized) ||
		                             (this.state.isSmallBreakpoint && draggableInitialized);

		if (shouldInitDrag) {
			this.initDraggable();
		}
		else if (shouldDestroyDrag) {
			this.destroyDraggables();
			return;
		}

		if (this.refs.contentDraggable) {
			this.handleNavigationControls();
		}
	}

	enableStageDetailsFocusableElements() {
		this.refs.focusableElements.forEach((node) => {
			node.setAttribute('tabindex', 0);
		});
	}

	disableStageDetailsFocusableElements() {
		this.refs.focusableElements.forEach((node) => {
			node.setAttribute('tabindex', -1);
		});
	}

	destroyDraggables() {
		[this.refs.stagesDraggable, this.refs.contentDraggable].forEach((draggable) => {
			if (draggable) {
				draggable.target.removeAttribute('styles');
				draggable.kill();
			}
		});

		[this.refs.stagesDraggable, this.refs.contentDraggable] = [null, null];

		this.el.classList.remove(
			CONTENT_OVERFLOW_CLASS,
			`${ CONTENT_OVERFLOW_CLASS }-left`,
			`${ CONTENT_OVERFLOW_CLASS }-right`
		);
	}

	checkContentOverflowing() {
		const target      = this.refs.contentWrapper;
		const curOverflow  = target.style.overflow;

		if (! curOverflow || curOverflow === 'visible') {
			target.style.overflow = 'hidden';
		}

		const isContentOverflowing = target.clientWidth < target.scrollWidth;

		target.style.overflow = curOverflow;
		this.refs.contentWrapper.classList.toggle('is-overflowing', isContentOverflowing);

		this.state = {
			...this.state,
			isContentOverflowing,
		};
	}

	/**
	 * Sets a css var with the height of each day column to match the content height
	 */
	setDaysColumnHeights() {
		this.refs.contentRows.forEach((row, index) => {
			this.refs.daysRows[ index ].style.setProperty('--height', `${ row.clientHeight }px`);
		});
	}

	handleCanceledEvents() {
		const elements = Array.from(this.refs.contentWrapper.querySelectorAll('del'));

		elements.forEach((el) => {
			charming(el, {
				split(string) {
					return string.split(/(\s+)/);
				},
				setClassName(index) {
					return `word`;
				},
			});
		});
	}

	/**
	 * Sets a css var with the height of stage details for transitioning
	 */
	setScrollerDetailsHeight() {
		this.el.style.setProperty('--stage-details-height', `${ this.refs.stagesRowWrapper.scrollHeight }px`);
	}

	initObserver(target) {
		this.refs.observer = new IntersectionObserver((records) => {
			if (! records[ 0 ].isIntersecting) {
				this.closeDaypicker();
			}
		});
		this.refs.observer.observe(target);
	}

	showDaypicker(event) {
		// window.console.log(event);

		const dropdownSelectedText = this.state.isSmallBreakpoint ? event.target.innerHTML : this.refs.dropdownSelected.getAttribute('data-default-text');
		this.refs.dropdownSelected.innerHTML = dropdownSelectedText;
		this.refs.dropdown.classList.toggle('is-small', this.state.isSmallBreakpoint);

		if (this.state.currentStageDetails) {
			this.hideStageDetails();
		}

		if (this.state.isSmallBreakpoint) {
			if (this.state.selectedDay && this.state.selectedDay === event.target.closest('.day')) {
				this.closeDaypicker();
				return;
			}

			if (this.state.selectedDay) {
				this.state.selectedDay.classList.remove('is-active');
			}
			this.state.selectedDay = event.target.closest('.day');

			this.refs.dropdown.classList.add('is-visible');
			this.state.selectedDay.classList.add('is-active');
			event.target.parentNode.appendChild(this.refs.dropdown);

			// start to observe the day container and hide the dropdown as soon as it goes out of viewport
			this.initObserver(this.state.selectedDay);
		}
		else {
			this.refs.dropdownToggle.appendChild(this.refs.dropdown);
			this.refs.dropdown.classList.toggle('is-visible');

			this.refs.dropdownSelected.focus();
		}
	}

	closeDaypicker() {
		if (this.state.selectedDay) {
			this.state.selectedDay.classList.remove('is-active');
			this.refs.observer.unobserve(this.state.selectedDay);
			this.state.selectedDay = null;
		}

		this.refs.dropdown.classList.remove('is-visible', 'is-small');
	}

	scrollToDay(event) {
		if (! event.target.closest('[data-day]')) {
			return;
		}

		this.closeDaypicker();

		const id           = event.target.closest('[data-day]').getAttribute('data-day');
		const target       = this.refs.contentRows.find((row) => `#${ row.id }` === id);
		const scrollOffset = this.refs.nav.clientHeight +
			(this.refs.tabs?.clientHeight ?? 0) +
			this.refs.header.clientHeight -
			window.scrollY - 1;

		window.scrollTo({
			top: target.getBoundingClientRect().top - scrollOffset,
			behavior: 'smooth',
		});
	}

	hideStageDetails() {
		this.state.currentStageDetails.classList.remove(DETAILS_VISIBLE_CLASS);
		this.refs.header.classList.remove(DETAILS_VISIBLE_CLASS);
		this.state.currentStageDetails = null;
	}

	toggleStageDetails(event) {
		if (this.state.isDragging) {
			return;
		}

		const target = event.target.closest(STAGE_SELECTOR);
		this.closeDaypicker();

		// hide or remove style from previously active details
		if (this.state.currentStageDetails && this.state.currentStageDetails !== target) {
			this.state.currentStageDetails.classList.remove(DETAILS_VISIBLE_CLASS);
		}

		target.classList.toggle(DETAILS_VISIBLE_CLASS);

		if (this.state.isSmallBreakpoint) {
			this.state.currentStageDetails = target.classList.contains(DETAILS_VISIBLE_CLASS) ? target : null;
		}
		else if (target.classList.contains(DETAILS_VISIBLE_CLASS)) {
			this.state.currentStageDetails = target;
			this.refs.header.classList.add(DETAILS_VISIBLE_CLASS);
			this.enableStageDetailsFocusableElements();
		}
		else {
			this.state.currentStageDetails = null;
			this.refs.header.classList.remove(DETAILS_VISIBLE_CLASS);
			this.disableStageDetailsFocusableElements();
		}
	}

	// --------------------------------
	// #endregion
	// --------------------------------

	/**
	 * Before the component gets destroyed
	 * - unbind any event not bound with this.on()
	 * - reset UI if needed (any classes/attributes added?)
	 */
	beforeDestroy() {
		if (this.refs && this.refs.stagesDraggable) {
			this.destroyDraggables();
		}
	}
}
